import React from 'react';
import Design from "./HeaderComponent.module.css";
import logo from "images/sharedDocument/logo.svg";

export default function HeaderComponent() {
  return (
    <div className={Design.headerSection}>
          <div className={Design.headerLogoContainer}>
            <img src={logo} alt="logo" />
          </div>
          <div className={Design.headerContentSection}>
            <p className={Design.headerContentSectionTitle}>FIND YOUR PERFECT <span className={Design.primaryColorTitle}>PARTNER & FAMILY</span></p>
            <p className={Design.headerContentSectionPara}>Trusted matrimonial service for born <br/>again <span className={Design.primaryColorTitle}>Christians</span></p>
          </div>
        </div>
  )
}
