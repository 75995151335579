import React from 'react';
import Person from "components/Person";
import Design from "./FinancesDashboard.module.css";
import { smallDateFormatter } from "../utils/functions/dateFormatter"

export default function TransactionHistoryMobileView(props) {
  const { transaction } = props;

  return (
    <div className={Design.mobileViewContainer}>
          <Person id={transaction.userId} name={transaction.userName} referenceNumber={transaction.referenceNumber} profileImageUrl={transaction.profileImageUrl} />
          <div className={Design.subscriptionDetails}>
                <p className={Design.MobilesubscriptionPlan}>{transaction.subscriptionPlan}</p>
                <p className={Design.MobilesubscriptionDate}>Ends on {smallDateFormatter(transaction.expiryDate)}</p>
          </div>
    </div>
  )
}
