import * as React from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "images/MoreVertIcon.svg";
import { SHOW_POPUP } from "store/actions/popUpActions";
import { SHOW_TOAST } from "store/actions/toastAction";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";

const ITEM_HEIGHT = 40;

function LongMenu(props) {
  let navigate = useNavigate();
  const { options, user } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onShareData = () => {
    try {
      const sharedData = `ID: ${user.referenceNumber}\nName: ${user.name}\nFather's Number: ${user.fathersCountryCode} ${user.fathersNumber}\nMother's Number: ${user.mothersCountryCode} ${user.mothersNumber}\n`;
      navigator.clipboard.writeText(sharedData);
      props.openSnackbar("Copied to clipboard", "success", true);
    } catch (error) {
      props.openSnackbar("Failed to copy", "error", true);
    }
  };

  const toReactivateUser = () => {
    if (user.id) {
      axios
        .patch(`${BASE_URL}admin/users/reactivate/${user.id}`, null)
        .then((res) => {
          props.openSnackbar("Reactivated User", "success", true);
          window.location.reload();
        })
        .catch((error) => {
          error.toJson();
          console.log(error);
          if (error.code === "ERR_NETWORK") {
            props.openSnackbar("Network Error", "error", true);
          } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
            onHandleUnauthorizedError();
          } else {
            props.openSnackbar("Some error occures. Please try again later!", "error", true);
          }
        });
    }
  };

  const toVerifyUser = () => {
    if (user.id) {
      axios
        .patch(`${BASE_URL}admin/users/verify/${user.id}`, null)
        .then((res) => {
          props.openSnackbar("Verified User", "success", true);
          window.location.reload();
        })
        .catch((error) => {
          error.toJson();
          console.log(error);
          if (error.code === "ERR_NETWORK") {
            props.openSnackbar("Network Error", "error", true);
          } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
            onHandleUnauthorizedError();
          } else {
            props.openSnackbar("Some error occures. Please try again later!", "error", true);
          }
        });
    }
  };

  const handleNavigation = (value) => {
    if (value === "Share") {
      onShareData();
    }
    if (value === "Delete" || value === "Reject") {
      props.onOpenPopup(true, false, user.id, "delete");
    }
    if (value === "Remove") {
      props.onOpenPopup(true, false, user.id, "remove");
    }
    if (value === "Deactivate") {
      navigate(`/user/deactivate/${user.id}`);
    }
    if (value === "Reactivate") {
      toReactivateUser();
    }
    if (value === "Approve") {
      toVerifyUser();
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {/* <MoreVertIcon /> */}
        <img src={MoreVertIcon} alt="MoreVertIcon" width={25} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem sx={{ fontSize: "14px" }} key={option} onClick={() => handleNavigation(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
    onOpenPopup: (showPopup, confirmAction, userId, action) => dispatch({ type: SHOW_POPUP, showPopup, confirmAction, userId, action }),
  };
};

export default connect(null, mapDispatchToProps)(LongMenu);
