import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Design from "./ProfileUpdate.module.css";
import { spacing } from '@mui/system';


export default function ProfileUpdateTableRow(props) {
  const { keyValue, newValue, oldValue } = props;
  const style = {
    padding: "12px",
    borderBottom: "1px solid #0000000f",
  }
  return (
    <TableRow key={"id"}>
      <TableCell sx={style}>
        <p className={Design.keyValue}>{keyValue}</p>
      </TableCell>
      <TableCell sx={style} >
        <p className={Design.newValue} >{newValue}</p>
      </TableCell>
      <TableCell sx={style}>
        <p className={Design.oldValue} >{oldValue}</p>
      </TableCell>
    </TableRow>
  );
}
