import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import { SHOW_TOAST } from "store/actions/toastAction";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { useNavigate, useParams } from "react-router-dom";
import ImageList from "@mui/material/ImageList";
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import Person from "../../components/Person";
import PrimaryBtn from "../../components/PrimaryBtn";
import SecondaryBtn from "../../components/SecondaryBtn";
import Status from "../../components/Status";
import SuccessStoryAttachmentImage from "./SuccessStoryAttachmentImage";
import Design from "./SuccessStoryDetails.module.css";

function SuccessStoryDetails(props) {
  const { storyId } = useParams();
  let navigate = useNavigate();
  const [successStory, setSuccessStory] = useState([]);
  const [marriageDate, setMarriageDate] = useState();
  const [engagementDate, setEngagementDate] = useState();
  const [sign, setSign] = useState();
  const matches = useMediaQuery("(min-width:600px)");

  const getSuccessStoryDetails = () => {
    axios
      .get(`${BASE_URL}admin/success-stories/${storyId}`)
      .then((res) => {
        setSuccessStory(res.data.successStory);
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
          navigate(-1);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
          navigate(-1);
        }
      });
  };
  useEffect(() => {
    getSuccessStoryDetails();
  }, []);

  useEffect(() => {
    setEngagementDate(moment.utc(successStory.engagementDate).format("DD/MM/YYYY"));
    setMarriageDate(moment.utc(successStory.marriageDate).format("DD/MM/YYYY"));

    if(successStory.status === "APPROVED"){
      setSign("success")
    }
    if(successStory.status === "PENDING"){
      setSign("warning")
    }
    if(successStory.status === "REJECTED"){
      setSign("error")
    }
  }, [successStory]);

  const handleSuccessStory = (option) => {

    const status = option === "Accept" ? "APPROVED" : "REJECTED";

    axios
      .patch(`${BASE_URL}admin/success-stories/status-update/${storyId}?status=${status}`)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            props.openSnackbar("Successfully updated success story status", "success", true);
            getSuccessStoryDetails();
          }
        }
      })
      .catch((error) => {
        error.toJSON();
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  };

  return (
    <div className={Design.successStoryPage}>
      <Header title={"Success Stories"} />
      <div className={Design.successStoryContainer}>
        <h2 className={Design.subTitles} style={{ fontSize: "16px" }}>
          {successStory.title}
        </h2>
        <div className={Design.contentContainer}>
          <div className={Design.keyValuepairs}>
            <p className={Design.userKeys}>Married User</p>
            <Person name={successStory.marriedUser?.name} profileImageUrl={successStory.marriedUser?.profileImageUrl} />
          </div>
          <div className={Design.keyValuepairs}>
            <p className={Design.userKeys}>Married To User</p>
            <Person name={successStory.marriedToUser?.name} profileImageUrl={successStory.marriedToUser?.profileImageUrl} />
          </div>
          <div className={Design.keyValuepairs}>
            <p className={Design.dateKeys}>Engagement Date</p>
            <p className={Design.value}>{engagementDate}</p>
          </div>
          <div className={Design.keyValuepairs}>
            <p className={Design.dateKeys}>Marriage Date</p>
            <p className={Design.value}>{marriageDate}</p>
          </div>
        </div>

        {successStory.status === "PENDING" ? (
          <span>
            <h2 className={Design.subTitles}>Actions</h2>
            <div className={Design.actionBtns}>
              <span onClick={() => handleSuccessStory("Accept")}>
                <PrimaryBtn title={"Accept"} />
              </span>
              <span onClick={() => handleSuccessStory("Reject")}>
                <SecondaryBtn title={"Reject"} />
              </span>
            </div>
          </span>
        ) : null}

        <span>
          <h2 className={Design.subTitles}>Status</h2>
          <span className={Design.actionBtns}>
            <Status status={successStory.status} sign={sign} />
          </span>
        </span>

        <h2 className={Design.subTitles}>Description</h2>
        <p className={Design.description}>{successStory.story}</p>

        {successStory.successstoryattachments?.length !== 0? <h2 className={Design.subTitles}>Attachments</h2> : null}

        <div className={Design.attchments}>
          <ImageList sx={{ width: "100%", height: "auto" }} cols={matches ? 5 : 2} gap={20}>
            {successStory.successstoryattachments?.map((item) => (
              <SuccessStoryAttachmentImage attachedImg={item} />
            ))}
          </ImageList>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(SuccessStoryDetails);
