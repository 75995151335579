import ImageListItem from "@mui/material/ImageListItem";
import noImage from "images/noImage.png"
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BASE_URL_V2 } from "utils/constants/ApplicationConstants";

function SuccessStoryAttachmentImage(props) {
  const { attachedImg } = props;
  const [image, setImage] = useState(noImage);
  const url = attachedImg.attachmentUrl;

  const getImage = async () => {
    const imageUrl = `${BASE_URL_V2}${url}`;
    try {
      const res = await fetch(imageUrl, {
        headers: {
          Authorization: props.authToken,
        },
      });
      const reader = await res.body.getReader();
      let chunks = [];
      reader.read().then(function processText({ done, value }) {
        if (done) {
          const blob = new Blob([chunks]);
          setImage(URL.createObjectURL(blob));
          return;
        }
        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;
        return reader.read().then(processText);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
  }, [url]);

  return (
    <ImageListItem key={attachedImg.id}>
      <img style={{ borderRadius: "10px" }} src={image} alt={attachedImg.successStoryId} loading="lazy" />
    </ImageListItem>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.authToken,
  };
};

export default connect(mapStateToProps, null)(SuccessStoryAttachmentImage);
