import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "images/MoreVertIcon.svg";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";

const ITEM_HEIGHT = 40;

function SuccessStoriesMenu(props) {

  const { storyId } = props;


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSuccessStory = (option) =>{
    
    const status = option === "Accept" ? "APPROVED" : "REJECTED";

    axios
    .patch(`${BASE_URL}admin/success-stories/status-update/${storyId}?status=${status}`)
    .then((res) => {
      if (res) {
        if (res.status === 200) {
          props.openSnackbar("Successfully updated success story status","success",true);
          window.location.reload();
        }
      }
    })
    .catch((error) => {
      error.toJSON();
      if (error.code === "ERR_NETWORK") {
        props.openSnackbar("Network error", "error", true);
      } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
        onHandleUnauthorizedError();
      } else {
        props.openSnackbar("Some error occures. Please try again later!", "error", true);
      }
    });

  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {/* <MoreVertIcon /> */}
        <img src={MoreVertIcon} alt="MoreVertIcon" width={25} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem sx={{ fontSize: "14px" }} onClick={() => handleSuccessStory("Accept")}>
        Accept
        </MenuItem>
        <MenuItem sx={{ fontSize: "14px" }} onClick={() => handleSuccessStory("Reject")}>
        Reject
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(SuccessStoriesMenu);