import React from 'react';
import SuccessStoryMobileView from "./SuccessStoryMobileView";

export default function SuccessStoryMobileViews(props) {
    const {successStories} = props;

    const mobileView = successStories.map((story) => <SuccessStoryMobileView key={story.id} story={story} />);

  return (
    <div>
       {mobileView}
    </div>
  )
}
