import React from "react";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BASE_URL } from "utils/constants/ApplicationConstants";

function RoundedAvatar(props) {
  const { url, size } = props;
  const [image, setImage] = useState("");

  const style = {
    width: size ?? "60px",
    height: size ?? "60px",
  }

  const getImage = async () => {
    const imageUrl = `${BASE_URL}${url}`;
    try {
      const res = await fetch(imageUrl, {
        headers: {
          Authorization: props.authToken,
        },
      });
      const reader = await res.body.getReader();
      let chunks = [];
      reader.read().then(function processText({ done, value }) {
        if (done) {
          const blob = new Blob([chunks]);
          setImage(URL.createObjectURL(blob));
          return;
        }
        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;
        return reader.read().then(processText);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
  }, [url]);

  return <Avatar variant="rounded" sx={style} src={image} />;
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.authToken,
  };
};

export default connect(mapStateToProps, null)(RoundedAvatar);
