import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NetworkError from "../empty/NetworkError";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import Header from "components/Header";
import Empty from "empty/Empty";
import PrimaryBtn from "../components/PrimaryBtn";
import FeedbackForm from "./FeedbackForm";
import BasicPagination from "../components/BasicPagination";
import Design from "./FeedbackForms.module.css";
import { SHOW_TOAST } from "store/actions/toastAction";
import LoadingScreen from "../components/LoadingScreen";

function FeedbackForms(props) {
  const { formId, action, confirmAction } = props;
  let navigate = useNavigate();
  const [feedbackFormsList, setFeedbackFormsList] = useState([]);
  const [networkError, setNetworkError] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  let emptyPageTitle = "No Feedback Forms";
  let emptyPageMsg = "Feedback forms will show up when you add some forms in this application.";

  const addFeedbackForm = () => {
    navigate(`/add/feedbacks`);
  };

  const onHandleSubmit = () => {
    axios
      .get(`${BASE_URL}admin/feedback-forms?page=${page}`)
      .then((res) => {
        setFeedbackFormsList(res.data.feedbackForms);
        setTotalPages(res.data.totalPages);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        error.toJSON();
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setNetworkError(true);
        }
        if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }
      });
  };

  useEffect(() => {
    onHandleSubmit();
  }, []);

  useEffect(() => {
    if (confirmAction && action === "delete") {
      axios
      .delete(`${BASE_URL}admin/feedback-forms/${formId}`)
      .then((res) => {
        props.openSnackbar("Success", "success", true);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        props.openSnackbar("Something went wrong!", "error", true);
      });
    }
  }, [formId,action, confirmAction])
  
  const feedbackForms = feedbackFormsList.map((feedbackForms) => <FeedbackForm key={feedbackForms.id} feedbackForms={feedbackForms} />);

  return (
    <div>
      <div className={Design.headerSection}>
        <Header title={"Feedback Forms"} />
        {!networkError && !isLoading ? (
          <span onClick={addFeedbackForm}>
            <PrimaryBtn title={"+ Add Form"} />
          </span>
        ) : null}
      </div>

      {totalPages === 0 && !isLoading ? <span>{networkError ? <NetworkError /> : <Empty title={emptyPageTitle} msg={emptyPageMsg} />}</span> : null}

      <div className="content" style={{ display: totalPages !== 0 && !networkError && !isLoading ? "block" : "none" }}>
        <div className={Design.container}>
          {feedbackForms}
          <BasicPagination
            totalPages={totalPages}
            page={(pageNumber) => {
              setPage(pageNumber);
            }}
          />
        </div>
      </div>

      { isLoading ? <LoadingScreen /> : null }

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    formId: state.popupReducer.userId,
    action: state.popupReducer.action,
    confirmAction: state.popupReducer.confirmAction,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForms);
