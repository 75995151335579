import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ProfileUpdateTableRow from "./ProfileUpdateTableRow";
import GalleryImageUpdateTableRow from "./GalleryImageUpdateTableRow";

export default function ProfileUpdateTable(props) {
  const { updatedData, userData } = props;
  const columns = [
    { id: "info", label: "", minWidth: 100 },
    { id: "Updated Details", label: "Updated Details", minWidth: 100 },
    { id: "Current Details", label: "Current Details", minWidth: 100 },
  ];

  const galleryImagesArrayLength = updatedData.galleryImages ? updatedData.galleryImages.length : null;

  return (
    <div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, backgroundColor: "#F4F6F8", border: "none" }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {updatedData.name ? <ProfileUpdateTableRow keyValue={"Name"} oldValue={userData.name} newValue={updatedData.name} /> : null}
            {updatedData.bio ? <ProfileUpdateTableRow keyValue={"Bio"} oldValue={userData.bio} newValue={updatedData.bio} /> : null}
            {updatedData.gender ? <ProfileUpdateTableRow keyValue={"Gender"} oldValue={userData.gender} newValue={updatedData.gender} /> : null}
            {updatedData.dateOfBirth ? <ProfileUpdateTableRow keyValue={"DOB"} oldValue={userData.dateOfBirth} newValue={updatedData.dateOfBirth} /> : null}
            {updatedData.age ? <ProfileUpdateTableRow keyValue={"Age"} oldValue={userData.age} newValue={updatedData.age} /> : null}
            {updatedData.hometown ? <ProfileUpdateTableRow keyValue={"Hometown"} oldValue={userData.hometown} newValue={updatedData.hometown} /> : null}
            {updatedData.height ? <ProfileUpdateTableRow keyValue={"Height"} oldValue={userData.height + "cms"} newValue={updatedData.height + "cms"} /> : null}
            {updatedData.weight ? <ProfileUpdateTableRow keyValue={"Weight"} oldValue={userData.weight + "kgs"} newValue={updatedData.weight + "kgs"} /> : null}
            {updatedData.complexion ? <ProfileUpdateTableRow keyValue={"Complexion"} oldValue={userData.complexion} newValue={updatedData.complexion} /> : null}
            {updatedData.physicalStatus ? (
              <ProfileUpdateTableRow keyValue={"Physical Status"} oldValue={userData.physicalStatus} newValue={updatedData.physicalStatus} />
            ) : null}
            {updatedData.bodyType ? <ProfileUpdateTableRow keyValue={"Body Type"} oldValue={userData.bodyType} newValue={updatedData.bodyType} /> : null}
            {updatedData.education ? <ProfileUpdateTableRow keyValue={"Education"} oldValue={userData.education} newValue={updatedData.education} /> : null}
            {updatedData.occupation ? <ProfileUpdateTableRow keyValue={"Occupation"} oldValue={userData.occupation} newValue={updatedData.occupation} /> : null}
            {updatedData.religion ? <ProfileUpdateTableRow keyValue={"Religion"} oldValue={userData.religion} newValue={updatedData.religion} /> : null}
            {updatedData.caste ? <ProfileUpdateTableRow keyValue={"Caste"} oldValue={userData.caste} newValue={updatedData.caste} /> : null}
            {updatedData.church ? <ProfileUpdateTableRow keyValue={"Church"} oldValue={userData.church} newValue={updatedData.church} /> : null}
            {updatedData.motherTongue ? (
              <ProfileUpdateTableRow keyValue={"Mother Tongue"} oldValue={userData.motherTongue} newValue={updatedData.motherTongue} />
            ) : null}
            {updatedData.maritalStatus ? (
              <ProfileUpdateTableRow keyValue={"Marital Status"} oldValue={userData.maritalStatus} newValue={updatedData.maritalStatus} />
            ) : null}
            {updatedData.familyStatus ? (
              <ProfileUpdateTableRow keyValue={"Family Status"} oldValue={userData.familyStatus} newValue={updatedData.familyStatus} />
            ) : null}
            {updatedData.ornaments ? <ProfileUpdateTableRow keyValue={"Ornaments"} oldValue={userData.ornaments} newValue={updatedData.ornaments} /> : null}

            {updatedData.hometown ? (
              <ProfileUpdateTableRow keyValue={"Home Town"} oldValue={userData.hometown} newValue={updatedData.hometown} />
            ) : null}

            {updatedData.currentResidence ? (
              <ProfileUpdateTableRow keyValue={"Current Residence"} oldValue={userData.currentResidence} newValue={updatedData.currentResidence} />
            ) : null}

            {updatedData.nationality ? (
              <ProfileUpdateTableRow keyValue={"Nationality"} oldValue={userData.nationality} newValue={updatedData.nationality} />
            ) : null}

            {updatedData.mothersName ? (
              <ProfileUpdateTableRow keyValue={"Mother's Name"} oldValue={userData.mothersName} newValue={updatedData.mothersName} />
            ) : null}
            {updatedData.fathersName ? (
              <ProfileUpdateTableRow keyValue={"Father's Name"} oldValue={userData.fathersName} newValue={updatedData.fathersName} />
            ) : null}
            {updatedData.brothersCount ? (
              <ProfileUpdateTableRow keyValue={"Brother's Count"} oldValue={userData.brothersCount} newValue={updatedData.brothersCount} />
            ) : null}
            {updatedData.sistersCount ? (
              <ProfileUpdateTableRow keyValue={"Sister's Count"} oldValue={userData.sistersCount} newValue={updatedData.sistersCount} />
            ) : null}

            {updatedData.whatsappCountryCode + updatedData.whatsappNumber ? (
              <ProfileUpdateTableRow
                keyValue={"Whatsapp Numbe"}
                oldValue={userData.whatsappCountryCode + userData.whatsappNumber}
                newValue={updatedData.whatsappCountryCode + updatedData.whatsappNumber}
              />
            ) : null}
            {updatedData.fathersCountryCode + updatedData.fathersNumber ? (
              <ProfileUpdateTableRow
                keyValue={"Father's Number"}
                oldValue={userData.fathersCountryCode + userData.fathersNumber}
                newValue={updatedData.fathersCountryCode + updatedData.fathersNumber}
              />
            ) : null}
            {updatedData.mothersCountryCode + updatedData.mothersNumber ? (
              <ProfileUpdateTableRow
                keyValue={"Mother's Number"}
                oldValue={userData.mothersCountryCode + userData.mothersNumber}
                newValue={updatedData.mothersCountryCode + updatedData.mothersNumber}
              />
            ) : null}
            {updatedData.pastorCountryCode + updatedData.pastorNumber ? (
              <ProfileUpdateTableRow
                keyValue={"Pastor's Number"}
                oldValue={userData.pastorCountryCode + userData.pastorNumber}
                newValue={updatedData.pastorCountryCode + updatedData.pastorNumber}
              />
            ) : null}
            {updatedData.preferences  ? (
              <ProfileUpdateTableRow
                keyValue={"Preferences"}
                oldValue={userData.preferences}
                newValue={updatedData.preferences}
              />
            ) : null}
            {galleryImagesArrayLength === null || galleryImagesArrayLength === 0 ? null : (
              <GalleryImageUpdateTableRow newValue={updatedData.galleryImages} oldValue={userData.galleryImages} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
