import React from "react";

import whiteLogo from "images/sharedDocument/whiteLogo.png";
import Design from "./ProfileImageSection.module.css";
import ProfileImage from "./ProfileImage";

export default function ProfileImageSection(props) {
  const { user } = props;

  return (
    <div className={Design.profileImageSection}>
      <div className={Design.profileImgContainer}>
        <div className={Design.profileImageCover}>
          <ProfileImage profileImageUrl={user.profileImageUrl} />
        </div>
        <img className={Design.whiteLogo} src={whiteLogo} alt="whiteLogo" />
      </div>

      <div className={Design.referenceNoContainer}>
        <p className={Design.referenceNoLabel}>REFERENCE NO </p>
        <p className={Design.referenceNoValue}>{user.referenceNumber}</p>
      </div>

      <div className={Design.contactSection}>
        <div className={Design.contactSectionContainer}>
          <p className={Design.contactPara}>
            Your contact details <br />
            are safe & secure <br />
            with us!
          </p>
          <div>
            <p className={Design.contactNumber}>+91 954 491 6447</p>
            <p className={Design.contactNumber}>+64 22 365 6916</p>
            <p className={Design.contactName}>( Br.Justin )</p>
          </div>
        </div>
        <div className={Design.contactFrame}></div>
      </div>
    </div>
  );
}
