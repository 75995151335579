import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PRIMARY_COLOR } from "utils/custom/colorPalette";

export default function ScrollableTabsButtonAuto(props) {
  const { sort, tab } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    tab(newValue);
  };
  const sortElements = sort.map((sortElement, i) => <Tab key={i} label={sortElement} sx={{ textTransform: "capitalize" }} />);
  return (
    <Box sx={{ width: "100%", bgcolor: "#F4F6F8", borderRadius: "16px 16px 0px 0px", padding: "0 16px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        TabIndicatorProps={{ sx: { backgroundColor: PRIMARY_COLOR } }}
        sx={{
          "& button": { color: "#637381" },
          "& button.Mui-selected": { color: "#212B36" },
        }}
      >
        {sortElements}
      </Tabs>
    </Box>
  );
}
