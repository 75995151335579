
import React from "react";
import Status from "../../components/Status";
import { useNavigate } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Person from "components/Person";
import openInNew from "images/openInNew.svg";
import Design from "./SuccessStories.module.css";
import SuccessStoriesActions from "./SuccessStoriesActions";

export default function SuccessStory(props) {
  const {story} = props;
  let navigate = useNavigate();
  let sign;

  if(story.status === "APPROVED" ){
    sign = "success";
  }
  if(story.status === "PENDING" ){
    sign = "warning";
  }
  if(story.status === "REJECTED" ){
    sign = "error";
  }

  return (
    <TableRow sx={{ maxHeight: 440 }}>
      <TableCell sx={{ border: "none" }}>
        <Person id={story.marriedUser.id} name={story.marriedUser.name} referenceNumber={story.marriedUser.referenceNumber} profileImageUrl={story.marriedUser.profileImageUrl} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <Person id={story.marriedToUser.id} name={story.marriedToUser.name} referenceNumber={story.marriedToUser.referenceNumber} profileImageUrl={story.marriedToUser.profileImageUrl} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <span className={Design.titleContainer} onClick={()=> navigate(`/success-stories/${story.id}`) }>
          <div className={Design.title}>
            <p>{story.title}</p>
          </div>
          <img width={15} src={openInNew} alt="->"/>
        </span>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
      {story.status === "PENDING" ? (
        <SuccessStoriesActions storyId={story.id} />) : <Status status={story.status} sign={sign} />}
      </TableCell>
    </TableRow>
  );
}
