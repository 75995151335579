import React, { useEffect, useState } from "react";
import Design from "./LoginPage.module.css";


const Timer = (props) => {
  const { initialMinute = 1, initialSeconds = 60, running, onTimerExpired } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    if (running) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
            onTimerExpired();
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    }
  });

  useEffect(() => {
    if (running) {
      setMinutes(initialMinute);
      setSeconds(initialSeconds);
    }
  }, [running, initialMinute, initialSeconds]);

  return (
    <div>
      <p className={Design.loginFormPara}>OTP will expires in {minutes}:{seconds < 10 ? `0${seconds}` : seconds} </p>
    </div>
  );
};

export default Timer;