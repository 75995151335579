import { combineReducers } from "redux";
import authReducer from "store/reducers/authReducer";
import imagePopupReducer from "store/reducers/imagePopupReducer";
import popupReducer from "store/reducers/popupReducer";
import selectUserReducer from "store/reducers/selectUserReducer";
import navBarReducer from "../reducers/navBarReducer";
import toastReducer from "./toastReducer";
import sortOrderReducer from "./sortOrderReducer";
import searchAndFilterReducer from "./searchAndFilterReducer";

const rootReducer = combineReducers({
  authReducer: authReducer,
  popupReducer,
  selectUserReducer,
  imagePopupReducer,
  toastReducer,
  navBarReducer,
  searchAndFilterReducer,
  sortOrderReducer,
});

export default rootReducer;
