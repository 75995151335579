import React from "react";
import Design from "./BasicDetails.module.css";

export default function BasicDetailsRow(props) {
    const { keys, value } = props;
  return (
    <div className={Design.basicDetailsRow}>
      <p className={Design.basicDetailsKey}>{keys}</p>
      <p className={Design.basicDetailsValue}>{value}</p>
    </div>
  );
}
