import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import acceptIcon from "../images/acceptIcon.svg";
import rejectIcon from "../images/rejectIcon.svg";
import Design from "./RequestIconBox.module.css";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";

function RequestIconBox(props) {
  const { id } = props;

  const location = useLocation();
  const path = location.pathname;

  const changeStatus = (status) => {
    axios
      .patch(`${BASE_URL}admin/user/requests/update-status/${id}?status=${status}`)
      .then((res) => {
        if (res && res.status === 200) {
          props.openSnackbar(res.data.message, "success", true);
          window.location.reload();
        }
      })
      .catch((error) => {
        error.toJSON();
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
        }else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }else{
          props.openSnackbar("Can't update request status", "error", true);
        }
      });
  };

  const acceptProfileUpdateRequest = () =>{
    axios
      .patch(`${BASE_URL}admin/users/update/profile/approve/${id}`)
      .then((res) => {
        props.openSnackbar("User profile update request accepted successfully", "success", true);
        // window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  }

  const rejectProfileUpdateRequest = () => {
    axios
      .patch(`${BASE_URL}admin/users/update/profile/reject/${id}`)
      .then((res) => {
        props.openSnackbar("User profile update request rejected successfully", "success", true);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  }

  const approveBtn = () => {
    if(path === "/requests"){
      changeStatus("APPROVED")
    }
    if(path === "/profile/updates"){
      acceptProfileUpdateRequest()
    }
  }

  const rejectBtn = () => {
    if(path === "/requests"){
      changeStatus("REJECTED")
    }
    if(path === "/profile/updates"){
      rejectProfileUpdateRequest();
    }
  }

  return (
    <div className={Design.iconBox}>
      <div className={Design.icons}>
        <div className={Design.editIcon} onClick={approveBtn} >
          <img src={acceptIcon} alt="acceptIcon" />
        </div>
        <div className={Design.deleteIcon} onClick={rejectBtn} >
          <img src={rejectIcon} alt="rejectIcon" />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(RequestIconBox);
