import React from 'react';
import Status from "components/Status";

export default function ActionLogStatus(props) {
    const { actionType } = props;
    let sign;
    let person = "";
    let status = "";
    let extra = "";

    if (actionType.includes("ADMIN")){
        person = "Admin";
    }else if(actionType.includes("USER")){
        person = "User";
    }

    if(actionType.includes("DELETE")){
        status = "Delete";
        sign = "error";
    }else if(actionType.includes("DEACTIVATE")){
        status = "Deactivate";
        sign = "error";
    }
    
    if(actionType.includes("VERIFY")){
        status = "Approved";
        sign = "success";
    }else if(actionType.includes("REACTIVATE")){
        status = "Reactivate";
        sign = "success";
    }else if(actionType.includes("REGISTER")){
        status = "Register";
        sign = "success";
    }

    if(actionType === "UPDATE_USER_REQUEST_STATUS"){
        status = "Updated";
        sign = "warning";
        extra= "Request Status";
    }else if(actionType.includes("UPDATE")){
        status = "Update";
        sign = "warning";
        extra = "Profile";
    }

    if(actionType === "SUBSCRIPTION_ACTIVATE"){
        status = "Activate Subscription";
        sign = "info";
    }

  return (
    <Status status={`${status} ${person} ${extra}`} sign={sign} />
  )
}
