import clear from "images/clear.svg";
import { connect } from "react-redux";
import filterIcon from "images/filter.svg";
import reset from "images/reset.svg";
import searchIcon from "images/search.svg";
import { useEffect, useState } from "react";
import { AUTOFILL_SEARCH_TIMER } from "utils/constants/ApplicationConstants";
import PrimaryBtn from "../../components/PrimaryBtn";
import SecondaryBtn from "../../components/SecondaryBtn";
import MultiRangeSlider from "./MultiRangeSlider";
import Design from "./SearchAndFilter.module.css";
import { SET_FILTERS, SET_SEARCH_TERM } from "store/actions/searchAndFilterActions";

function SearchAndFilterBar(props) {
  const { onHandleFilter } = props;
  const [openSlider, setOpenSlider] = useState(false);
  const [minAge, setMinAge] = useState(18);
  const [maxAge, setMaxAge] = useState(100);
  const [minHeight, setMinHeight] = useState(50);
  const [maxHeight, setMaxHeight] = useState(300);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState();
  const [timer, setTimer] = useState();

  const toggleMultiRangeSlider = () => {
    setOpenSlider(!openSlider);
  };

  const clearSearchBar = () => {
    setSearch("");
  };

  const resetFilter = () => {
    setFilter("");
    setMinAge(18);
    setMaxAge(100);
    setMinHeight(50);
    setMaxHeight(300);
  };

  const setAgeAndHeight = () => {
    setFilter(`Age ${minAge}-${maxAge} Height ${minHeight}-${maxHeight}`);
    toggleMultiRangeSlider();
  };

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      props.setSearchTerm(search);
      props.setFilters(minAge, maxAge, minHeight, maxHeight);
      onHandleFilter(search, minAge, maxAge, minHeight, maxHeight);
    }
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        props.setSearchTerm(search);
        props.setFilters(minAge, maxAge, minHeight, maxHeight);
        onHandleFilter(search, minAge, maxAge, minHeight, maxHeight);
      }, AUTOFILL_SEARCH_TIMER)
    );
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [search]);

  useEffect(() => {
    props.setSearchTerm(search);
    props.setFilters(minAge, maxAge, minHeight, maxHeight);
    onHandleFilter(search, minAge, maxAge, minHeight, maxHeight);

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [minAge, maxAge, minHeight, maxHeight]);

  return (
    <div className={Design.SearchAndFilterBar}>
      <div className={Design.searchbarContainer}>
        <div className={Design.search}>
          <div className={Design.searchbar}>
            <input
              placeholder="Search"
              type="text"
              style={{ border: "none" }}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              autoFocus
            />
          </div>
          {search ? <img src={clear} alt="clear" width={20} onClick={clearSearchBar} /> : null}
        </div>
      </div>
      <div className={Design.filterbarContainer}>
        <div className={Design.filter}>
          <div className={Design.filterbar} onClick={toggleMultiRangeSlider}>
            <input placeholder="Select filters" type="text" style={{ border: "none" }} value={filter} />
          </div>
          {filter ? <img src={reset} alt="reset" width={20} onClick={resetFilter} /> : null}
        </div>
        <img className={Design.filterIcon} src={filterIcon} alt="filterIcon" onClick={toggleMultiRangeSlider} />

        {/* sliders */}
        {openSlider ? (
          <div className={Design.sliderContainer}>
            <div className={Design.slider}>
              <span>
                <h3 className={Design.sliderLabel}>Age</h3>
                <MultiRangeSlider
                  min={18}
                  max={100}
                  onChange={({ min, max }) => {
                    setMinAge(min);
                    setMaxAge(max);
                  }}
                />
              </span>
              <span>
                <h3 className={Design.sliderLabel}>Height</h3>
                <MultiRangeSlider
                  min={50}
                  max={300}
                  onChange={({ min, max }) => {
                    setMinHeight(min);
                    setMaxHeight(max);
                  }}
                />
              </span>
              <span className={Design.btngrp}>
                <span onClick={setAgeAndHeight}>
                  <PrimaryBtn title={"Apply"} />
                </span>
                <span onClick={toggleMultiRangeSlider}>
                  <SecondaryBtn title={"Dismiss"} />
                </span>
              </span>
            </div>
          </div>
        ) : null}
      </div>
      <span
        className={Design.searchBtn}
        onClick={() => {
          props.setSearchTerm(search);
          props.setFilters(minAge, maxAge, minHeight, maxHeight);
          onHandleFilter(search, minAge, maxAge, minHeight, maxHeight);
        }}
      >
        <PrimaryBtn title={"Search"} />
      </span>
      <button
        className={Design.smallSearchBtn}
        onClick={() => {
          props.setSearchTerm(search);
          props.setFilters(minAge, maxAge, minHeight, maxHeight);
          onHandleFilter(search, minAge, maxAge, minHeight, maxHeight);
        }}
      >
        <img src={searchIcon} alt="searchIcon" />
      </button>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchTerm: (searchTerm) => dispatch({ type: SET_SEARCH_TERM, searchTerm }),
    setFilters: (minAge, maxAge, minHeight, maxHeight) => dispatch({ type: SET_FILTERS, minAge, maxAge, minHeight, maxHeight }),
  };
};

export default connect(null, mapDispatchToProps)(SearchAndFilterBar);
