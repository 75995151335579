import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { BASE_URL } from "utils/constants/ApplicationConstants";

const firebaseConfig = {
  apiKey: "AIzaSyByhG3AQibPpMoHn7UeK3W-8ZJ2JbCmBL4",
  authDomain: "garden-of-eden-matrimony.firebaseapp.com",
  projectId: "garden-of-eden-matrimony",
  storageBucket: "garden-of-eden-matrimony.appspot.com",
  messagingSenderId: "374662526938",
  appId: "1:374662526938:web:79cee7c6863b27d09732e3",
  measurementId: "G-PL1FSHB8F4",
};

initializeApp(firebaseConfig);

const PUBLIC_VAPID_KEY = "BCXDzYS5N5ugmlYhnOQRi8us7ptJSJnlLBb3GsDEvrIaBob0nA-ZOhzgbRA4I4ROM-J4b4yIz864ISvSiv9-9jg";

const messaging = getMessaging();

const onSendFCMRegisterToken = async (fcmToken, authToken) => {
  const data = { fcmToken: fcmToken };
  try {
    await axios.post(`${BASE_URL}admin/account/config/fcm-token`, data, {
      headers: {
        Authorization: authToken,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const requestForToken = async (authToken) => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY });
    if (currentToken) {
      onSendFCMRegisterToken(currentToken, authToken);
    } else {
      console.log("No registration token available. Request permission to generate one.");
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
