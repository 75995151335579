import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URL, AUTOFILL_SEARCH_TIMER } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";
import Design from "./FinancesDashboard.module.css";

export default function FinanceDashboardCards(props) {
  const { network } = props;
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalSubscribers, setTotalSubscribers] = useState(0);
  const [percentage, setPercentage] = useState();
  const [currentRevenue, setCurrentRevenue] = useState(0);
  const [sign, setSign] = useState();

  const getTransactionCardData = () => {
    axios
      .get(`${BASE_URL}admin/transactions/card`)
      .then((res) => {
        setTotalAmount(Math.ceil(res.data.totalAmount));
        setTotalSubscribers(res.data.totalSubscribers);
        getCurrentMonthRevenue(res.data.transactions);
      })
      .catch((error) => {
        console.log(error);
        error.toJSON();
        if (error.code === "ERR_NETWORK") {
          network(true);
        }
        if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }
      });
  };

  useEffect(() => {
    getTransactionCardData();
  }, []);

  const getCurrentMonthRevenue = (transactions) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonthNumber = currentDate.getMonth() + 1;

    const previousMonthNumber = currentMonthNumber === 1 ? 12 : currentMonthNumber - 1;
    const previousYear = currentMonthNumber === 1 ? currentYear - 1 : currentYear;

    const revenueOfCurrentMonthObj = transactions.find(transaction => transaction["month"] === currentMonthNumber && transaction["year"] === currentYear);
    const revenueOfPreviousMonthObj = transactions.find(transaction => transaction["month"] === previousMonthNumber && transaction["year"] === previousYear);

    const revenueOfCurrentMonth = revenueOfCurrentMonthObj === undefined ? 0 : revenueOfCurrentMonthObj?.totalAmount;
    setCurrentRevenue(Math.ceil(revenueOfCurrentMonth));
    const revenueOfPreviousMonth = revenueOfPreviousMonthObj === undefined ? 0 : revenueOfPreviousMonthObj?.totalAmount;

    const difference = revenueOfCurrentMonth.totalAmount - revenueOfPreviousMonth;
    const average = (revenueOfCurrentMonth + revenueOfPreviousMonth) / 2;

    const percentageDifference = Math.ceil((difference / average) * 100);

    setPercentage(isNaN(percentageDifference) ? 0 : percentageDifference);
    
    if (percentageDifference > 0) {
      setSign(`${Design.positiveRevenue}`);
    } else if (percentageDifference < 0) {
      setSign(`${Design.negativeRevenue}`);
    } else {
      setSign(`${Design.nodifferenceRevenue}`);
    }

  };

  return (
    <div className={Design.transactionCardContainer}>
      <div className={Design.transactionCard}>
        <p>Total Revenue</p>
        <h2>$ {totalAmount}</h2>
      </div>
      <div className={Design.transactionCard}>
        <p>Total Subscribers</p>
        <h2>{totalSubscribers}</h2>
      </div>
      <div className={Design.transactionCard}>
        <p>Revenue this month</p>
        <h2>
          $ {currentRevenue}
          <span className={`${Design.percentageRevenue} ${sign}`}>{percentage}%</span>
        </h2>
      </div>
    </div>
  );
}
