import React, { useState } from "react";
import SendRequests from "./SendRequests";
import GetRequests from "./GetRequests";
import { DatePicker, Segmented } from "antd";
import Design from "./UserRequests.module.css";
const { RangePicker } = DatePicker;

export default function UserRequests(props) {
  const [segmentedValue, setSegmentedValue] = useState("Received Requests");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  let component;
  if (segmentedValue === "Received Requests") {
    component = <GetRequests startDate={startDate} endDate={endDate} />;
  } else {
    component = <SendRequests startDate={startDate} endDate={endDate} />;
  }

  return (
    <div>
      <div className={Design.userRequestsHeaderSection}>
        <Segmented
          options={["Received Requests", "Send Requests"]}
          onChange={(val) => {
            setSegmentedValue(val);
          }}
        />
        <span style={{"height": "45px"}}>
        <RangePicker
            format="DD-MM-YYYY"
            onChange={(value, dateString) => {
              setStartDate(dateString[0]);
              setEndDate(dateString[1]);
            }}
          />
        </span>
      </div>
      {component}
    </div>
  );
}
