import React from "react";
import axios from "axios";
import Empty from "empty/Empty";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SHOW_TOAST } from "store/actions/toastAction";
import UserHeading from "user/UserHeading";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import BasicPagination from "../../components/BasicPagination";
import UserRequest from "./UserRequest";
import LoadingScreen from "../../components/LoadingScreen";
import Design from "./UserRequests.module.css";

function SendRequests(props) {
  const { startDate, endDate } = props;
  let navigate = useNavigate();
  const { userId } = useParams();
  const [requests, setrequests] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setIsLoading] = useState(true);

  let emptyPageTitle = "No results found!";
  let emptyPageMsg = "Look’s like you haven’t sent any requests yet.";

  const getRequests = () => {
    axios
      .get(`${BASE_URL}admin/user/send/requests/${userId}?page=${page}&startDate=${startDate}&endDate=${endDate}`)
      .then((res) => {
        setrequests(res.data.adminRequests);
        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.totalCount);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
          navigate(-1);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
          navigate(-1);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };
  useEffect(() => {
    getRequests();
  }, []);

  useEffect(() => {
    getRequests();
  }, [startDate, endDate, page]);

  return (
    <div>
      <div style={{ display: !isLoading ? "block" : "none" }}>
        <span style={{ display: totalPages === 0 ? "block" : "none" }}>
          <Empty title={emptyPageTitle} msg={emptyPageMsg} />
        </span>
        <div style={{ display: totalPages !== 0 ? "block" : "none" }}>
          <div className={Design.UserRequests}>
            <div className={Design.headingContainer}>
              <UserHeading title={"Send Requests"} />
              <div className={Design.totalCount}>{totalCount}</div>
            </div>
            <div className={Design.requests}>
              {requests.map((request) => (
                <UserRequest key={request.id} request={request} />
              ))}
            </div>

            <BasicPagination
              totalPages={totalPages}
              page={(pageNumber) => {
                setPage(pageNumber);
              }}
            />
          </div>
        </div>
      </div>
      <span style={{ display: isLoading ? "block" : "none" }}>
        <LoadingScreen />
      </span>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(SendRequests);
