import React from "react";
import { connect } from "react-redux";
import CopyIcon from "./CopyIcon";
import SelectedUser from "./SelectedUser";
import Design from "./SelectedUsers.module.css";
import { CLEAR_SELECTED_USERS } from "store/actions/selectUserActions";
import { PRIMARY_COLOR } from "utils/custom/colorPalette";

function SelectedUsers(props) {
  const userDetails = props.selectedUsers;

  const copytoClipboard = () => {
    let sharedData = "Contact Details\n\n";
    userDetails.forEach((element) => {
      const userdata = `ID: ${element.referenceNumber}\nName: ${element.name}\nFathers's Number: ${element.fathersCountryCode} ${element.fathersNumber}\nMother's Number: ${element.mothersCountryCode} ${element.mothersNumber}\n\n`;
      sharedData = sharedData.concat(userdata);
    });
    navigator.clipboard.writeText(sharedData);

    props.onClearSelectedUsers();
  };

  const selectedUsers = userDetails.map((user) => <SelectedUser key={user.referenceNumber} user={user} />);
  return (
    <span>
      {userDetails.length !== 0 ? (
        <div className={Design.selectedUsersContainer}>
          <div className={Design.copyIcon} onClick={copytoClipboard}>
            {/* <img src={copy} alt="copy" width={20} /> */}
            <CopyIcon color={PRIMARY_COLOR} />
          </div>
          <div className={Design.selectedUsers}>{selectedUsers}</div>
        </div>
      ) : null}
    </span>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedUsers: state.selectUserReducer.selectedUsers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onClearSelectedUsers: () => dispatch({ type: CLEAR_SELECTED_USERS }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectedUsers);
