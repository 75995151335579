import * as actionTypes from "../actions/searchAndFilterActions";

const initialState = {
  searchTerm: "",
  minAge: 18,
  maxAge: 120,
  minHeight: 50,
  maxHeight: 300,
};

const searchAndFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_TERM:
      return { searchTerm: action.searchTerm };
    case actionTypes.SET_FILTERS:
      return {
        minAge: action.minAge,
        maxAge: action.maxAge,
        minHeight: action.minHeight,
        maxHeight: action.maxHeight,
      };
      default:
        return state;
  }
};

export default searchAndFilterReducer;