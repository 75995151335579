import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Design from "./DatePicker.module.css";

export default function DatePicker(props) {
  const { getDate } = props;
  const [value, setValue] = useState();
  const handleChange = (newValue) => {
    setValue(newValue);
    getDate(newValue.format("DD-MM-YYYY"))
  };
  return (
    <div className={Design.datePicker}>
    <LocalizationProvider dateAdapter={AdapterDayjs} style={{width : "100%"}}>
      <DesktopDatePicker
        inputFormat="DD/MM/YYYY"
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField  variant="standard"  {...params} />}
      />
    </LocalizationProvider>
    </div>
  );
}
