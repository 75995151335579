import axios from "axios";
import Image from "components/Image";
import verifyIcon from "images/accept.svg";
import groupShareIcon from "images/groupShareIcon.svg";
import reactivateIcon from "images/reactivate.svg";
import rejectIcon from "images/reject.svg";
import shareIcon from "images/shareIcon.svg";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OPEN_IMAGE_POPUP } from "store/actions/imagePopupActions";
import { SHOW_POPUP } from "store/actions/popUpActions";
import { SHOW_TOAST } from "store/actions/toastAction";
import RoundedAvatar from "user/RoundedAvatar";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import UserHeading from "../UserHeading";
import Design from "./General.module.css";
import moment from "moment";

function UserProfileImage(props) {
  const { user, ongetUserData } = props;

  let navigate = useNavigate();

  var endDate = user.subscriptions && user.subscriptions.length > 0 ? moment(user.subscriptions[0].expiringOn, "DD/MM/YYYY") : null;
  var today = moment();

  const remainingDays = endDate ? endDate.diff(today, "days") + 1 : 0;

  const subscriptionIsActive = user.subscriptions ? user?.subscriptions[0]?.isActive : null;

  const galleryImages = user.galleryImages
    ? user.galleryImages.map((link) => (
        <span onClick={() => props.openImagePopup(true, user.galleryImages)}>
          <RoundedAvatar key={link.id} url={link.url} size={"80px"} />
        </span>
      ))
    : null;

  const toRejectUser = () => {
    props.onOpenPopup(true, false, user.id, "delete");
  };

  const toReactivateUser = () => {
    if (user.id) {
      axios
        .patch(`${BASE_URL}admin/users/reactivate/${user.id}`, null)
        .then((res) => {
          props.openSnackbar("Reactivated User", "success", true);
          ongetUserData();
        })
        .catch((error) => {
          console.error(error);
          if (error.code === "ERR_NETWORK") {
            props.openSnackbar("Network error", "error", true);
          } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
            onHandleUnauthorizedError();
          } else {
            props.openSnackbar("Some error occurs, try again later", "error", true);
          }
        });
    }
  };

  const toVerifyUser = () => {
    if (user.id) {
      axios
        .patch(`${BASE_URL}admin/users/verify/${user.id}`, null)
        .then((res) => {
          props.openSnackbar("Verified User", "success", true);
          ongetUserData();
        })
        .catch((error) => {
          console.error(error);
          if (error.code === "ERR_NETWORK") {
            props.openSnackbar("Network error", "error", true);
          } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
            onHandleUnauthorizedError();
          } else {
            props.openSnackbar("Some error occurs, try again later", "error", true);
          }
        });
    }
  };

  const onShareData = () => {
    try {
      const sharedData = `ID: ${user.referenceNumber}\nName: ${user.name}\nFather's Number: ${user.fathersCountryCode} ${user.fathersNumber}\nMother's Number: ${user.mothersCountryCode} ${user.mothersNumber}\n`;
      navigator.clipboard.writeText(sharedData);
      props.openSnackbar("Copied to clipboard", "success", true);
    } catch (error) {
      props.openSnackbar("Failed to copy", "error", true);
    }
  };

  const onGroupShareData = () => {
    navigate(`/user/print/${user.id}`);
    try {
      const sharedData = `NAME:     ${user.name}\nGENDER:     ${user.gender}\nDOB:     ${user.dateOfBirth}\nAGE:     ${user.age}\nHOME TOWN:     ${user.hometown}\nHEIGHT:     ${user.height}\nWEIGHT:     ${user.weight}\nCOMPLEXION:     ${user.complexion}\nPHYSICAL STATUS:     ${user.physicalStatus}\nBODY TYPE:     ${user.bodyType}\nEDUCATION:     ${user.education}\nOCCUPATION:     ${user.occupation}\nRELIGION:     ${user.religion}\nCASTE:     ${user.caste}\nCHURCH:     ${user.church}\nMOTHER TONGUE:     ${user.motherTongue}\nMARITAL STATUS:     ${user.maritalStatus}\nFAMILY STATUS:     ${user.familyStatus}\nORNAMENTS:     ${user.ornaments}\nPREFERENCES:     ${user.preferences}\nCURRENT RESIDENCE:     ${user.currentResidence}`;
      navigator.clipboard.writeText(sharedData);
      // props.openSnackbar("Copied to clipboard", "success", true);
    } catch (error) {
      props.openSnackbar("Failed to copy", "error", true);
    }
  };

  return (
    <div className={Design.userImage}>
      {user.version === 2 ? <h3 className={Design.userVersion}>NEW</h3> : null}
      <div className={Design.userImageContents}>
        <span className={Design.imageBorder} onClick={() => props.openImagePopup(true, user.profileImageUrl)}>
          <Image size={125} url={user.profileImageUrl} />
        </span>
        <UserHeading title={user.name} />
        <h3 className={Design.referenceNumber}>{user.referenceNumber}</h3>
        <h4 className={Design.userInfo}>{user.bio}</h4>

        <div className={Design.galleryImagesSection}>{galleryImages}</div>

        {user.isVerified ? (
          <div className={Design.profileBtnGrp}>
            {remainingDays >= 0 && user.isActive ? (
              <button className={Design.profileBtn} onClick={onShareData}>
                <span className={Design.profileBtnContent}>
                  <img src={shareIcon} alt="share" className={Design.profileIcons} /> Share
                </span>
              </button>
            ) : null}

            {remainingDays >= 0 && user.isActive ? (
              <button className={Design.profileBtn} onClick={onGroupShareData}>
                <span className={Design.profileBtnContent}>
                  <img src={groupShareIcon} alt="groupShare" width={15} className={Design.profileIcons} /> Group Share
                </span>
              </button>
            ) : null}

            {!user.isActive ? (
              <button className={Design.profileBtn} onClick={toReactivateUser}>
                <span className={Design.profileBtnContent}>
                  <img src={reactivateIcon} alt="reactivate" width={15} className={Design.profileIcons} /> Reactivate
                </span>
              </button>
            ) : null}
          </div>
        ) : (
          <div className={Design.profileBtnGrp}>
            <button className={Design.profileBtn} onClick={toVerifyUser}>
              <span className={Design.profileBtnContent}>
                <img src={verifyIcon} alt="verify" width={15} className={Design.profileIcons} /> Approve
              </span>
            </button>
            <button className={Design.profileBtn} onClick={toRejectUser}>
              <span className={Design.profileBtnContent}>
                <img src={rejectIcon} alt="reject" width={15} className={Design.profileIcons} /> Reject
              </span>
            </button>
            {!user.isActive ? (
              <button className={Design.profileBtn} onClick={toReactivateUser}>
                <span className={Design.profileBtnContent}>
                  <img src={reactivateIcon} alt="reactivate" width={15} className={Design.profileIcons} /> Reactivate
                </span>
              </button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
    onOpenPopup: (showPopup, confirmAction, userId, action) => dispatch({ type: SHOW_POPUP, showPopup, confirmAction, userId, action }),
    openImagePopup: (showImagePopup, imageUrl) => dispatch({ type: OPEN_IMAGE_POPUP, showImagePopup, imageUrl }),
  };
};

export default connect(null, mapDispatchToProps)(UserProfileImage);
