import * as actionTypes from "store/actions/selectUserActions";

const initialState = {
  selectedUsers: [],
};
const selectUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USERDATA:
      let duplicate = state.selectedUsers.findIndex(
        (selectedUser) =>
          selectedUser.referenceNumber === action.selectedUser.referenceNumber
      );

      if (duplicate === -1) {
        return {
          selectedUsers: [...state.selectedUsers, action.selectedUser],
        };
      } else {
        return {
          selectedUsers: state.selectedUsers.filter(
            (selectedUser) =>
              selectedUser.referenceNumber !==
              action.selectedUser.referenceNumber
          ),
        };
      }
    case actionTypes.CLEAR_SELECTED_USERS:
      return {
        selectedUsers: [],
      };
    default:
      return state;
  }
};

export default selectUserReducer;
