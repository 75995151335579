import Image from "components/Image";
import { Link, useLocation } from "react-router-dom";
import Status from "components/Status";
import subscribedUser from "../images/subscribedUser.svg";
import Design from "./Person.module.css";

export default function Person(props) {
  const { name, referenceNumber, id, admin, profileImageUrl, size, path, status } = props;
  const location = useLocation();
  const subscribeLocation = location.pathname;
  const redirect = path ?? "profile";
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {admin ? (
        <div className={Design.admin}>
          <Image url={profileImageUrl} size={size} />
          <div className={Design.personDetails}>
            <h3 className={Design.personName}>{name}</h3>
            <p className={Design.personId}>{referenceNumber}</p>
          </div>
        </div>
      ) : (
        <div className={Design.person}>
          <Link className={Design.personLink} to={`/user/${id}/${redirect}`}>
            <Image url={profileImageUrl} size={size} />
            <div className={Design.personDetails}>
              <h3 className={Design.personName}>{name}</h3>
              <p className={Design.personId}>{referenceNumber}</p>
              { status === "active" && subscribeLocation.includes("/Pending") ? <img src={subscribedUser} alt="subscribedUser" width={18} /> : null}
              { typeof status === "string" && subscribeLocation.includes("/subscribe") ? <div className={Design.personStatus}><Status status={status} sign={"error"} /></div> : null}
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}
