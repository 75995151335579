import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import BasicPagination from "../components/BasicPagination";
import clear from "images/clear.svg";
import PrimaryBtn from "components/PrimaryBtn";
import searchIcon from "images/search.svg";
import Design from "./EditRequests.module.css";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";
import EditRequestTable from "./EditRequestTable";
import EditRequestMobileviews from "./EditRequestMobileviews";
import LoadingScreen from "../components/LoadingScreen";
import { AUTOFILL_SEARCH_TIMER, BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";

export default function EditRequests(props) {
  const { tabIndex, network } = props;
  const [requets, setRequets] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [timer, setTimer] = useState();
  const [isLoading, setIsLoading] = useState(true);

  var status;
  if (tabIndex === 0) {
    status = "";
  } else if (tabIndex === 1) {
    status = "PENDING";
  } else if (tabIndex === 2) {
    status = "REJECTED";
  }

  const onHandleSubmit = () => {
    let url;
    if (search) {
      url = `${BASE_URL}admin/users/update/profiles?page=${page}&search=${search}&status=${status}`;
    } else {
      url = `${BASE_URL}admin/users/update/profiles?page=${page}&status=${status}`;
    }

    axios
      .get(`${url}`)
      .then((res) => {
        setRequets(res.data.requests);
        setTotalPages(res.data.totalPages);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        error.toJSON();
        if (error.code === "ERR_NETWORK") {
          network(true);
        }
        if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };

  useEffect(() => {
    onHandleSubmit();
  }, [page]);

  useEffect(() => {
    setIsLoading(true)
    onHandleSubmit();
  }, [tabIndex]);

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHandleSubmit();
    }
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        onHandleSubmit();
      }, AUTOFILL_SEARCH_TIMER)
    );
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [search]);

  const clearSearch = () => {
    setSearch("");
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <div>
        <div className={Design.searchBar}>
          <div className={Design.searchbarContainer}>
            <input
              type="text"
              placeholder="Search"
              value={search}
              style={{ border: "none" }}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              autoFocus
            />
            {search ? <img src={clear} alt="clear" width={20} onClick={clearSearch} /> : null}
          </div>
          <span className={Design.searchBtn} onClick={onHandleSubmit}>
            <PrimaryBtn title={"Search"} />
          </span>
          <button className={Design.smallSearchBtn} onClick={onHandleSubmit}>
            <img src={searchIcon} alt="search" />
          </button>
        </div>

        <div style={{display: isLoading ? "none" : "block" }}>

          <div className="desktopView">
            <EditRequestTable requets={requets} tabIndex={tabIndex} />
          </div>

          <div className="mobileView">
            <EditRequestMobileviews requets={requets} tabIndex={tabIndex} />
          </div>

          <BasicPagination
            tabIndex={tabIndex}
            totalPages={totalPages}
            page={(pageNumber) => {
              setPage(pageNumber);
            }}
          />
        </div>

        { isLoading ? <LoadingScreen /> : null }
      </div>
    </div>
  );
}
