import React from "react";

export default function AnalyticsIcon(props) {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
      <path d="M120-160v-40l80-80v120q0 17-11.5 28.5T160-120q-17 0-28.5-11.5T120-160Zm160 0v-200l80-80v280q0 17-11.5 28.5T320-120q-17 0-28.5-11.5T280-160Zm160 0v-280l80 81v199q0 17-11.5 28.5T480-120q-17 0-28.5-11.5T440-160Zm160 0v-199l80-80v279q0 17-11.5 28.5T640-120q-17 0-28.5-11.5T600-160Zm160 0v-360l80-80v440q0 17-11.5 28.5T800-120q-17 0-28.5-11.5T760-160ZM132-452l211-211q23-23 57-23t57 23l103 103 212-212q12-12 28.5-11.5T829-771q11 12 10.5 28.5T828-715L617-504q-23 23-57 23t-57-23L400-607 188-395q-12 12-28.5 11.5T131-396q-11-12-10.5-28.5T132-452Z" 
       fill={color}
      />
    </svg>
  );
}
