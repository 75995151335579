import React, { useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

export default function AllUsersTableHeader() {
 
  return (
    <TableHead>
      <TableRow>
        <TableCell key={"name"} sortDirection={false} style={{ minWidth: 100, backgroundColor: "#F4F6F8", border: "none" }}>
            {"Name & Ref.ID"}
        </TableCell>

        <TableCell key={"profileDetails"} sortDirection={false} style={{ minWidth: 100, backgroundColor: "#F4F6F8", border: "none" }}>
            {"Profile Details"}
        </TableCell>

        <TableCell key={"info"} sortDirection={false} style={{ minWidth: 100, backgroundColor: "#F4F6F8", border: "none" }}>
          {"Info"}
        </TableCell>
        <TableCell key={"dropdown"} sortDirection={false} style={{ minWidth: 10, backgroundColor: "#F4F6F8", border: "none" }}></TableCell>
      </TableRow>
    </TableHead>
  );
}
