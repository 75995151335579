import React from "react";

export default function AdminsIcon(props) {
  const { color } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1667 9.1668C14.45 9.1668 14.725 9.20013 15 9.2418V6.3168C15 5.65013 14.6083 5.05013 14 4.7918L9.41667 2.7918C8.99167 2.60846 8.50833 2.60846 8.08333 2.7918L3.5 4.7918C2.89167 5.05846 2.5 5.65846 2.5 6.3168V9.3168C2.5 13.1001 5.16667 16.6418 8.75 17.5001C9.20833 17.3918 9.65 17.2335 10.0833 17.0418C9.50833 16.2251 9.16667 15.2335 9.16667 14.1668C9.16667 11.4085 11.4083 9.1668 14.1667 9.1668Z"
        fill={color}
      />
      <path
        d="M14.1666 10.8333C12.325 10.8333 10.8333 12.3249 10.8333 14.1666C10.8333 16.0083 12.325 17.4999 14.1666 17.4999C16.0083 17.4999 17.5 16.0083 17.5 14.1666C17.5 12.3249 16.0083 10.8333 14.1666 10.8333ZM14.1666 11.9833C14.6833 11.9833 15.1 12.4083 15.1 12.9166C15.1 13.4249 14.675 13.8499 14.1666 13.8499C13.6583 13.8499 13.2333 13.4249 13.2333 12.9166C13.2333 12.4083 13.65 11.9833 14.1666 11.9833ZM14.1666 16.4583C13.3916 16.4583 12.7166 16.0749 12.3 15.4833C12.3416 14.8833 13.5583 14.5833 14.1666 14.5833C14.775 14.5833 15.9916 14.8833 16.0333 15.4833C15.6166 16.0749 14.9416 16.4583 14.1666 16.4583Z"
        fill={color}
      />
    </svg>
  );
}
