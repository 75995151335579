import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import Design from "./ProfileImageSection.module.css";

function ProfileImage(props) {
  const { profileImageUrl, authToken } = props;
  const [image, setImage] = useState("");

  const getImage = async () => {
    const imageUrl = `${BASE_URL}${profileImageUrl}`;
    try {
      const res = await fetch(imageUrl, {
        headers: {
          Authorization: authToken,
        },
      });
      const reader = await res.body.getReader();
      let chunks = [];
      reader.read().then(function processText({ done, value }) {
        if (done) {
          const blob = new Blob([chunks]);
          setImage(URL.createObjectURL(blob));
          console.log("URL.createObjectURL(blob) : ", URL.createObjectURL(blob));
          return;
        }
        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;
        return reader.read().then(processText);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
  }, [profileImageUrl]);

  return <img className={Design.profileImage} src={image} alt="profileImage" />;
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.authToken,
  };
};

export default connect(mapStateToProps, null)(ProfileImage);
