import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";
import UserHeading from "user/UserHeading";
import PrimaryBtn from "components/PrimaryBtn";
import SecondaryBtn from "components/SecondaryBtn";
import Design from "./Manage.module.css";
import { SHOW_TOAST } from "store/actions/toastAction";
import { emailValidator } from "utils/functions/validators";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";

function UpdateEmail(props) {
  const { userId } = useParams();
  const [email, setEmail] = useState();

  const clearForm = () =>{
    setEmail("");
  }

  const onSubmit = () => {
    let formIsvalid = true;
    if (!email || email === "") {
      formIsvalid = false;
      props.openSnackbar("Email cannot be empty", "warning", true);
    }else if(! emailValidator(email)){
      formIsvalid = false;
      props.openSnackbar("Invalid email", "error", true);
    }else{
      formIsvalid = true;
    }

    if(formIsvalid){
      updateEmail();
    }
  }

  const updateEmail = () =>{
    const data = {
      newEmail: email, 
    };
    axios
      .patch(`${BASE_URL}admin/user/update/email/${userId}`, data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            props.openSnackbar("Successfully edited user email id", "success", true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.code === "ERR_NETWORK"){
          props.openSnackbar("Network error", "error", true);
        }else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }else if(error.response.data.message === "Email id already exist"){
          props.openSnackbar("Email id already exist", "error", true);
        }else{
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  }

  return (
    <div className={Design.action}>
      <UserHeading title={"Change Email Id"} />
      <div className="textInput">
        <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} autoFocus />
      </div>
      <div className={Design.btnGroup}>
        <span onClick={onSubmit}>
          <PrimaryBtn title={"Save"} />
        </span>
        <span onClick={clearForm}>
          <SecondaryBtn title={"Clear"} />
        </span>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({type: SHOW_TOAST, message, severity, open}),
  };
};

export default connect(null, mapDispatchToProps)(UpdateEmail);