import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Header from "components/Header";
import PrimaryBtn from "components/PrimaryBtn";
import AdminTable from "./AdminTable";
import AdminMobileViews from "./AdminMobileViews";
import AddAdminForm from "./AddAdminForm";
import clear from "images/clear.svg";
import Design from "./Admins.module.css";
import { useLocation } from "react-router-dom";
import ScrollableTabsButtonAuto from "../components/ScrollableTabsButtonAuto";
import BasicPagination from "../components/BasicPagination";
import NetworkError from "empty/NetworkError";
import { BASE_URL, AUTOFILL_SEARCH_TIMER, LOADING_TIME } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";
import searchIcon from "images/search.svg";
import LoadingScreen from "../components/LoadingScreen";

function Admins(props) {
  const location = useLocation();
  const path = location.pathname;

  const [adminForm, setAdminForm] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [networkError, setNetworkError] = useState(false);
  const [timer, setTimer] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const sort = ["All", "Admins", "Super Admins"];
  const addAdminForm = () => {
    setAdminForm(true);
  };

  const onHandleSubmit = () => {
    let url;
    if (search) {
      url = `${BASE_URL}admin/profile?page=${page}&search=${search}`;
    } else {
      url = `${BASE_URL}admin/profile?page=${page}`;
    }

    axios
      .get(`${url}`)
      .then((res) => {
        setAdmins(res.data.adminData);
        setTotalPages(res.data.totalPages);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        error.toJSON();
        if (error.code === "ERR_NETWORK") {
          setNetworkError(true);
        }
        if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };

  useEffect(() => {
    onHandleSubmit();
  }, [page]);

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHandleSubmit();
    }
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        onHandleSubmit();
      }, AUTOFILL_SEARCH_TIMER)
    );
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [search]);

  const deleteAdmin = () => {
    axios
      .delete(`${BASE_URL}admin/admins/delete/${props.userId}`)
      .then((res) => {
        if (res.status === 200 && path === "/admins") {
          props.openSnackbar("The admin profile has been successfully deleted", "success", true);
          onHandleSubmit();
        }
      })
      .catch((error) => {
        console.error(error);
        props.openSnackbar("Something went wrong!", "error", true);
      });
  };

  useEffect(() => {
    if (props.confirmAction && props.action === "delete") {
      deleteAdmin();
    }
  }, [props.action, props.confirmAction]);

  const clearSearch = () => {
    setSearch("");
  };

  return (
    <div>
      <div className={Design.headerSection}>
        <Header title={"Admins"} />
        {!networkError ? (
          <span onClick={addAdminForm}>
            <PrimaryBtn title={"+ Add Admin"} />
          </span>
        ) : null}
      </div>

      {networkError && !isLoading ? (
        <NetworkError />
      ) : (
        <div className="content">
          <ScrollableTabsButtonAuto
            sort={sort}
            tab={(index) => {
              setIsLoading(true);
              setTabIndex(index);
              setTimeout(() => {
                setIsLoading(false);
              }, LOADING_TIME);
            }}
          />

          <div className={Design.searchBar}>
            <div className={Design.searchbarContainer}>
              <input
                type="text"
                placeholder="Search"
                value={search}
                style={{ border: "none" }}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                autoFocus
              />
              {search ? <img src={clear} alt="clear" width={20} onClick={clearSearch} /> : null}
            </div>
            <span className={Design.searchBtn} onClick={onHandleSubmit}>
              <PrimaryBtn title={"Search"} />
            </span>
            <button className={Design.smallSearchBtn} onClick={onHandleSubmit}>
              <img src={searchIcon} alt="search" />
            </button>
          </div>

          <div style={{ display: !isLoading ? "block" : "none" }}>
            <div className="desktopView">
              <AdminTable admins={admins} tabIndex={tabIndex} />
            </div>

            <div className="mobileView">
              <AdminMobileViews admins={admins} tabIndex={tabIndex} />
            </div>
            <BasicPagination
              totalPages={totalPages}
              page={(pageNumber) => {
                setPage(pageNumber);
              }}
            />
          </div>
          {isLoading ? <LoadingScreen /> : null}
        </div>
      )}
      {adminForm ? (
        <AddAdminForm
          onSubmitForm={() => onHandleSubmit()}
          closeAdminForm={() => {
            setAdminForm(false);
          }}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    action: state.popupReducer.action,
    confirmAction: state.popupReducer.confirmAction,
    userId: state.popupReducer.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admins);
