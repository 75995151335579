import React, { useState, useEffect } from "react";
import BasicPagination from "../components/BasicPagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TransactionHistoryTableHeader from "./TransactionHistoryTableHeader";
import TransactionHistoryTableRow from "./TransactionHistoryTableRow";
import TransactionHistoryMobileView from "./TransactionHistoryMobileView";
import axios from "axios";
import { BASE_URL, AUTOFILL_SEARCH_TIMER } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";
import NetworkError from "empty/NetworkError";
import { DatePicker } from "antd";
import Design from "./FinancesDashboard.module.css";

export default function TransactionHistoryTable() {
  const { RangePicker } = DatePicker;
  const [totalPages, setTotalPages] = useState(0);
  const [networkError, setNetworkError] = useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [page, setPage] = useState(1);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getTransactionData = () => {
    axios
      .get(
        `${BASE_URL}admin/transactions/histroy?page=${page}&order=${order.toUpperCase()}&orderBy=${orderBy}&search=${searchTerm}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setTransactionHistory(res.data.transactionHistory);
      })
      .catch((error) => {
        console.log(error);
        error.toJSON();
        if (error.code === "ERR_NETWORK") {
          setNetworkError(true);
        }
        if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }
      });
  };

  useEffect(() => {
    getTransactionData();
  }, [order, orderBy, page, searchTerm, endDate]);

  return (
    <div>
      <h3 style={{ fontSize: "16px", fontWeight: "600", marginBottom: "20px" }}>Transaction History</h3>
      <div>
        <div className={Design.searchAndSortContainer}>
          <div className={Design.searchBar}>
            <input type="text" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search user" />
          </div>
          <div className={Design.rangePicker}>
            <RangePicker
              format="DD/MM/YYYY"
              onChange={(value, dateString) => {
                setStartDate(dateString[0]);
                setEndDate(dateString[1]);
              }}
            />
          </div>
        </div>
        <div className="desktopView">
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TransactionHistoryTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={(order, orderBy) => {
                  setOrder(order);
                  setOrderBy(orderBy);
                }}
              />

              <TableBody>
                {transactionHistory.map((transactionHistory) => (
                  <TransactionHistoryTableRow transaction={transactionHistory} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="mobileView">
          {transactionHistory.map((transactionHistory) => (
            <TransactionHistoryMobileView transaction={transactionHistory} />
          ))}
        </div>
        <BasicPagination
          totalPages={totalPages}
          page={(pageNumber) => {
            setPage(pageNumber);
          }}
        />
      </div>
    </div>
  );
}
