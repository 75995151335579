import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "components/Header";
import AutoSelect from "./AutoSelect";
import DatePicker from "./DatePicker";
import UploadSuccessStoryAttachments from "./UploadSuccessStoryAttachments";
import PrimaryBtn from "../../components/PrimaryBtn";
import SecondaryBtn from "../../components/SecondaryBtn";
import dot from "../../images/dot.svg";
import { onHandleUnauthorizedError } from "../../utils/functions/errorhandle";
import Design from "./AddSuccessStories.module.css";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";

function AddSuccessStories(props) {
  let navigate = useNavigate();
  const [title, setTitle] = useState();
  const [story, setStory] = useState();
  const [marriedUser, setMarriedUser] = useState({});
  const [marriedToUser, setMarriedToUser] = useState({});
  const [engagementDate, setEngagementDate] = useState();
  const [marriageDate, setmarriageDate] = useState();
  const [attachmentsObject, setAttachmentsObject] = useState([]);

  const handleValidation = () => {
    let formIsvalid = true;
    if (!title || title === "" || title === null) {
      formIsvalid = false;
      props.openSnackbar("Title cannot be empty", "warning", true);
    } else if (!story || story === "" || story === null) {
      formIsvalid = false;
      props.openSnackbar("Story cannot be empty", "warning", true);
    } else if (!marriedUser || marriedUser === "" || marriedUser === null) {
      formIsvalid = false;
      props.openSnackbar("Add married user's name", "warning", true);
    } else if (!marriedToUser || marriedToUser === "" || marriedToUser === null) {
      formIsvalid = false;
      props.openSnackbar("Add married to user's name", "warning", true);
    } else if (!engagementDate || engagementDate === "" || engagementDate === null) {
      formIsvalid = false;
      props.openSnackbar("Select an engagement date", "warning", true);
    } else if (!marriageDate || marriageDate === "" || marriageDate === null) {
      formIsvalid = false;
      props.openSnackbar("Select a wedding date", "warning", true);
    }else {
      if(attachmentsObject.length === 0){
        props.openSnackbar("Share your moments with us", "info", true);
        formIsvalid = false;
      }else{
        formIsvalid = true;
      }
    }
    return formIsvalid;
  };

  const onSubmitSuccessStory = () => {
    if (handleValidation()) {
      const data = new FormData();
      data.append("title", title);
      data.append("story", story);
      data.append("marriedUserId", marriedUser.id);
      data.append("marriedToUserId", marriedToUser.id);
      data.append("engagementDate", engagementDate);
      data.append("marriageDate", marriageDate);
      for(let i=0; i<attachmentsObject.length; i++){
        data.append("attachments", attachmentsObject[i]);
      }

      axios
        .post(`${BASE_URL}admin/success-stories`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        }
        )
        .then((res) => {
          if(res && res.status === 201){
            navigate("/success-stories");
          }
        })
        .catch((error) => {
          error.toJSON();
          if (error.code === "ERR_NETWORK") {
            props.openSnackbar("Network error", "error", true);
          } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
            onHandleUnauthorizedError();
          }
        });
    }

  };

  const getAttachmentUrls=(urls)=>{
    setAttachmentsObject(urls)
  }

  return (
    <div className={Design.addSuccessStories}>
      <Header title={"Create Success Stories"} />

      <div className={Design.breadCrumbs}>
        <NavLink to="/success-stories" className={Design.activeLink}>
          Success Stories
        </NavLink>
        <img className={Design.dot} src={dot} alt="dot" />
        <p className={Design.inActiveLink}>Create Success Stories</p>
      </div>

      <div className={Design.addSuccessStoriesForm}>
        <div className="textInput">
          <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} autoFocus />
        </div>
        <textarea
          className={Design.story}
          value={story}
          name="reasonDescription"
          placeholder="Description"
          onChange={(e) => setStory(e.target.value)}
        ></textarea>
        <span className={Design.sectionHeading}>Married User</span>
        <AutoSelect placeholder={"Select married user"} getSelectedUserData={(user) => setMarriedUser(user)} />
        <span className={Design.sectionHeading}>Married To User</span>
        <AutoSelect placeholder={"Select married to user"} getSelectedUserData={(user) => setMarriedToUser(user)} />
        <span className={Design.sectionHeading}>Engagement Date</span>
        <DatePicker getDate={(date) => setEngagementDate(date)} />
        <span className={Design.sectionHeading}>Marriage Date</span>
        <DatePicker getDate={(date) => setmarriageDate(date)} />
        <span className={Design.sectionHeading}>Attachments</span>
        <UploadSuccessStoryAttachments getAttachmentUrls = {getAttachmentUrls}/>
        <div className={Design.btnsContainer}>
          <span onClick={onSubmitSuccessStory}>
            <PrimaryBtn title={"Submit"} />
          </span>
          <span>
            <SecondaryBtn title={"Cancel"} />
          </span>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(AddSuccessStories);
