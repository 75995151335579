import React from "react";
import { connect } from "react-redux";
import Image from "components/Image";
import Design from "./ProfileUpdate.module.css";
import { OPEN_IMAGE_POPUP } from "store/actions/imagePopupActions";

function ProfileUpdatePhotos(props) {
  const { updatedImage, userImage } = props;
  return (
    <div>
      {updatedImage ? (
        <div className={Design.ProfileUpdatePhotos}>
          <div className={Design.profileUpdateImages}>
            <h3 className={Design.userVersion}>NEW</h3>
            <span className={Design.imageBorder} onClick={()=>props.openImagePopup(true, userImage )} >
              <Image size={125} url={updatedImage} />
            </span>
          </div>
          <div className={Design.profileUpdateImages}>
            <h3 className={Design.userVersion}>OLD</h3>
            <span className={Design.imageBorder} onClick={()=>props.openImagePopup(true, userImage )} >
              <Image size={125} url={userImage} />
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openImagePopup: (showImagePopup, imageUrl) => dispatch({type: OPEN_IMAGE_POPUP, showImagePopup, imageUrl })
  };
};

export default connect(null, mapDispatchToProps)(ProfileUpdatePhotos);