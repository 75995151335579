import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PRIMARY_COLOR } from "utils/custom/colorPalette";
import { useNavigate, useLocation } from "react-router-dom";

export default function DashboardScrollableTabs() {
  let navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const pathNames = path.split("/");

  const [value, setValue] = React.useState(pathNames[2]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "#F4F6F8", borderRadius: "16px 16px 0px 0px", padding: "0 16px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        TabIndicatorProps={{ sx: { backgroundColor: PRIMARY_COLOR } }}
        sx={{
          "& button": { color: "#637381" },
          "& button.Mui-selected": { color: "#212B36" },
        }}
      >
          <Tab label={"all"} value="all" sx={{ textTransform: "capitalize" }} />
          <Tab label={"Pending"} value="Pending" sx={{ textTransform: "capitalize" }} />
          <Tab label={"subscribed"} value="subscribe" sx={{ textTransform: "capitalize" }} />
          <Tab label={"active"} value="active" sx={{ textTransform: "capitalize" }} />
          <Tab label={"unsubscribed"} value="unsubscribe" sx={{ textTransform: "capitalize" }} />
          <Tab label={"reported"} value="reported" sx={{ textTransform: "capitalize" }} />
          <Tab label={"deactived"} value="deactive" sx={{ textTransform: "capitalize" }} />
      </Tabs>
    </Box>
  );
}
