import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, NavLink, Navigate, useNavigate } from "react-router-dom";
import logo from "../images/logo.svg";
import openMenu from "../images/openMenu.svg";
import Design from "./NavigationBar.module.css";
import Image from "components/Image";
import NavigationLink from "./NavigationLink";
import { ALWAYS_OPEN, CLOSE_NAVBAR, OPEN_NAVBAR } from "store/actions/navBarActions";
import { SHOW_POPUP } from "store/actions/popUpActions";
import { PRIMARY_COLOR } from "utils/custom/colorPalette";

function NavigationBar(props) {
  let navigate = useNavigate();
  const profileImgurl = localStorage.getItem("profileImageUrl");
  const [dashboardColor, setDashboardColor] = useState("#637381");
  const [requestsColor, setRequestsColor] = useState("#637381");
  const [adminsColor, setAdminsColor] = useState("#637381");
  const [notificationColor, setNotificationColor] = useState("#637381");
  const [successStoriesColor, setsuccessStoriesColor] = useState("#637381");
  const [profileUpdateColor, setProfileUpdateColor] = useState("#637381");
  const [reportsColor, setReportsColor] = useState("#637381");
  const [feedbackColor, setFeedbackColor] = useState("#637381");
  const [financeColor, setFinanceColor] = useState("#637381");
  const [analyticsColor, setAnalyticsColor] = useState("#637381");
  const [logoutColor, setLogoutColor] = useState("#637381");
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    if (props.confirmAction && props.action === "logout") {
      localStorage.clear();
      <Navigate to="/auth" />;
      window.location.reload();
    } else if (!props.confirmAction && !props.showPopup) {
      setLogout(false);
      setLogoutColor("#637381");
    }
  }, [props.confirmAction, props.showPopup]);

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (path === "/" && !logout) {
      navigate(`/users/all`);
    }
    if (path.includes("/users") && !logout) {
      setDashboardColor(PRIMARY_COLOR);
      setRequestsColor("#637381");
      setAdminsColor("#637381");
      setNotificationColor("#637381");
      setProfileUpdateColor("#637381");
      setsuccessStoriesColor("#637381");
      setReportsColor("#637381");
      setFeedbackColor("#637381");
      setFinanceColor("#637381");
      setAnalyticsColor("#637381");
    } 
    
    else if (path === "/requests" && !logout) {
      setRequestsColor(PRIMARY_COLOR);
      setDashboardColor("#637381");
      setAdminsColor("#637381");
      setNotificationColor("#637381");
      setProfileUpdateColor("#637381");
      setsuccessStoriesColor("#637381");
      setReportsColor("#637381");
      setFeedbackColor("#637381");
      setFinanceColor("#637381");
      setAnalyticsColor("#637381");
    } 
    
    else if (path === "/notifications" && !logout) {
      setNotificationColor(PRIMARY_COLOR);
      setRequestsColor("#637381");
      setDashboardColor("#637381");
      setAdminsColor("#637381");
      setProfileUpdateColor("#637381");
      setsuccessStoriesColor("#637381");
      setReportsColor("#637381");
      setFeedbackColor("#637381");
      setFinanceColor("#637381");
      setAnalyticsColor("#637381");
    } 
    
    else if (path === "/profile/updates" && !logout) {
      setProfileUpdateColor(PRIMARY_COLOR);
      setAdminsColor("#637381");
      setNotificationColor("#637381");
      setRequestsColor("#637381");
      setDashboardColor("#637381");
      setsuccessStoriesColor("#637381");
      setReportsColor("#637381");
      setFeedbackColor("#637381");
      setFinanceColor("#637381");
      setAnalyticsColor("#637381");
    } 
    
    else if (path === "/admins" && !logout) {
      setAdminsColor(PRIMARY_COLOR);
      setNotificationColor("#637381");
      setRequestsColor("#637381");
      setDashboardColor("#637381");
      setProfileUpdateColor("#637381");
      setsuccessStoriesColor("#637381");
      setReportsColor("#637381");
      setFeedbackColor("#637381");
      setFinanceColor("#637381");
      setAnalyticsColor("#637381");
    }
    
    else if (path === "/reports" && !logout) {
      setReportsColor(PRIMARY_COLOR);
      setNotificationColor("#637381");
      setRequestsColor("#637381");
      setDashboardColor("#637381");
      setProfileUpdateColor("#637381");
      setsuccessStoriesColor("#637381");
      setAdminsColor("#637381");
      setFeedbackColor("#637381");
      setFinanceColor("#637381");
      setAnalyticsColor("#637381");
    }

    else if (path === "/success-stories" && !logout) {
      setsuccessStoriesColor(PRIMARY_COLOR);
      setNotificationColor("#637381");
      setRequestsColor("#637381");
      setDashboardColor("#637381");
      setProfileUpdateColor("#637381");
      setAdminsColor("#637381");
      setReportsColor("#637381");
      setFeedbackColor("#637381");
      setFinanceColor("#637381");
      setAnalyticsColor("#637381");
    }

    else if (path === "/feedbacks" && !logout) {
      setFeedbackColor(PRIMARY_COLOR);
      setNotificationColor("#637381");
      setRequestsColor("#637381");
      setDashboardColor("#637381");
      setProfileUpdateColor("#637381");
      setAdminsColor("#637381");
      setReportsColor("#637381");
      setsuccessStoriesColor("#637381");
      setFinanceColor("#637381");
      setAnalyticsColor("#637381");
    }

    else if (path === "/finances" && !logout) {
      setFinanceColor(PRIMARY_COLOR);
      setFeedbackColor("#637381");
      setNotificationColor("#637381");
      setRequestsColor("#637381");
      setDashboardColor("#637381");
      setProfileUpdateColor("#637381");
      setAdminsColor("#637381");
      setReportsColor("#637381");
      setsuccessStoriesColor("#637381");
      setAnalyticsColor("#637381");
    }

    else if (path === "/analytics" && !logout) {
      setAnalyticsColor(PRIMARY_COLOR)
      setFinanceColor("#637381");
      setFeedbackColor("#637381");
      setNotificationColor("#637381");
      setRequestsColor("#637381");
      setDashboardColor("#637381");
      setProfileUpdateColor("#637381");
      setAdminsColor("#637381");
      setReportsColor("#637381");
      setsuccessStoriesColor("#637381");
    }

  }, [path, logout]);

  const onLogout = () => {
    setLogout(true);
    setLogoutColor(PRIMARY_COLOR);
    setDashboardColor("#637381");
    setRequestsColor("#637381");
    setNotificationColor("#637381");
    setAdminsColor("#637381");
    setProfileUpdateColor("#637381");
    setsuccessStoriesColor("#637381");
    setReportsColor("#637381");
    setFeedbackColor("#637381");
    setFinanceColor("#637381");
    props.onOpenPopup(true, false, "logout");
  };

  const mediaQuery = window.matchMedia("(min-width: 1200px)");
  const handlemMediaQueryChanges = () => {
    if (mediaQuery.matches) {
      props.alwaysOpenNavbar(true, false);
    } else if (!mediaQuery.matches && !props.click) {
      props.onCloseNavBar(false, false);
    }
  };

  handlemMediaQueryChanges();
  mediaQuery.addListener(handlemMediaQueryChanges);

  const sideBar = document.querySelector(`.${Design.sideBar}`);
  if (sideBar) {
    if (props.openNavbar) {
      sideBar.style.transform = "translateX(0%)";
    } else if (props.openNavbar && props.click) {
      sideBar.style.transform = "translateX(0%)";
    } else if (!props.openNavbar && !props.click) {
      sideBar.style.transform = "translateX(-100%)";
    }
  }

  const closeNavbar = () => {
    props.onCloseNavBar(false, false);
  };

  return (
    <div>
      {props.click ? <div className={Design.background} onClick={closeNavbar}></div> : null}
      {props.openNavbar ? (
        <div className={Design.sideBar} onClick={closeNavbar}>
          <div className={Design.navigationBar}>
            <div className={Design.navabarLogoContainer}>
              <img className={Design.logo} src={logo} alt="logo" />
              {props.openNavbar && props.click ? <img className={Design.closeNavebarIcon} src={openMenu} alt="X" onClick={closeNavbar} /> : null}
            </div>

            <div className={Design.profileBadgeConatainer}>
              <div className={Design.profileBadge}>
                <Image url={profileImgurl} />
                <div className={Design.profileContents}>
                  <h2 className={Design.profileName}>{props.name}</h2>
                  <p className={Design.profileStatus}>{props.admin === "SUPER_ADMIN" ? "Super admin" : "Admin"}</p>
                </div>
              </div>
            </div>

            {/* navigation links */}
            <span>
              <NavLink to="/users/all" style={{ textDecoration: "none" }}>
                <NavigationLink title={"Users"} color={dashboardColor} />
              </NavLink>
              {props.admin === "SUPER_ADMIN" ? (
                <span>
                   <NavLink to="/finances" style={{ textDecoration: "none" }}>
                    <NavigationLink title={"Finances"} color={financeColor} />
                  </NavLink>

                  <NavLink to="/analytics" style={{ textDecoration: "none" }}>
                    <NavigationLink title={"Analytics"} color={analyticsColor} />
                  </NavLink>

                  <NavLink to="/requests" style={{ textDecoration: "none" }}>
                    <NavigationLink title={"Requests"} color={requestsColor} />
                  </NavLink>

                  <NavLink to="/admins" style={{ textDecoration: "none" }}>
                    <NavigationLink title={"Admins"} color={adminsColor} />
                  </NavLink>

                  <NavLink to="/profile/updates" style={{ textDecoration: "none" }}>
                    <NavigationLink title={"Profile Updates"} color={profileUpdateColor} />
                  </NavLink>

                  <NavLink to="/success-stories" style={{ textDecoration: "none" }}>
                    <NavigationLink title={"Success Stories"} color={successStoriesColor} />
                  </NavLink>

                  <NavLink to="/notifications" style={{ textDecoration: "none" }}>
                    <NavigationLink title={"Notifications"} color={notificationColor} />
                  </NavLink>

                  <NavLink to="/reports" style={{ textDecoration: "none" }}>
                    <NavigationLink title={"Reports"} color={reportsColor} />
                  </NavLink>

                  <NavLink to="/feedbacks" style={{ textDecoration: "none" }}>
                    <NavigationLink title={"Feedback Forms"} color={feedbackColor} />
                  </NavLink>

                </span>
              ) : null}
              <span onClick={onLogout}>
                <NavigationLink title={"Logout"} color={logoutColor} />
              </span>
            </span>

            <div style={{height: '100px'}}></div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    action: state.popupReducer.action,
    confirmAction: state.popupReducer.confirmAction,
    showPopup: state.popupReducer.showPopup,
    openNavbar: state.navBarReducer.openNavbar,
    click: state.navBarReducer.click,
    name: state.authReducer.name,
    admin: state.authReducer.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenNavBar: (openNavbar, click) => dispatch({ type: OPEN_NAVBAR, openNavbar, click }),
    alwaysOpenNavbar: (openNavbar, click) => dispatch({ type: ALWAYS_OPEN, openNavbar, click }),
    onCloseNavBar: (openNavbar, click) => dispatch({ type: CLOSE_NAVBAR, openNavbar, click }),
    onOpenPopup: (showPopup, confirmAction, action) => dispatch({ type: SHOW_POPUP, showPopup, confirmAction, action }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
