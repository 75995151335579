import React, { useEffect } from "react";

export default function AdminRadioBtn(props) {
  const { adminType, type } = props;

  const radioBtnGroup = {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    gap: "20px",
  };

  useEffect(() => {
    const radioBts = document.querySelectorAll(".adminType");
    if (type) {
      if (type === "SUPER_ADMIN") {
        radioBts[0].checked = true;
      }
      if (type === "ADMIN") {
        radioBts[1].checked = true;
      }
    }
  }, [type]);

  return (
    <div style={radioBtnGroup}>
      <div>
        <input
          className="adminType"
          name="adminType"
          type="radio"
          id="radio1"
          value="SUPER_ADMIN"
          onChange={(e) => {
            adminType(e.target.value);
          }}
        />
        <label htmlFor="radio1">Super Admin</label>
      </div>
      <div>
        <input
          className="adminType"
          name="adminType"
          type="radio"
          id="radio2"
          value="ADMIN"
          onChange={(e) => {
            adminType(e.target.value);
          }}
        />
        <label htmlFor="radio2">Admin</label>
      </div>
    </div>
  );
}
