import React from "react";
import axios from "axios";
import { useState } from "react";
import { connect } from "react-redux";
import dot from "../images/dot.svg";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import Header from "components/Header";
import PrimaryBtn from "components/PrimaryBtn";
import SecondaryBtn from "components/SecondaryBtn";
import Design from "./Deactivation.module.css";
import { SHOW_TOAST } from "store/actions/toastAction";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";

function Deactivation(props) {
  let navigate = useNavigate();
  const { userId } = useParams();
  const [reason, setReason] = useState("This profile is violeted terms of use");
  const [feedback, setFeedback] = useState("");

  const onSubmit = () => {
    const data = {
      reason: reason,
      description: feedback ? feedback : "No feedback",
    };
    onDeactivateUserAccount(data);
  };

  const onDeactivateUserAccount = (data) => {
    axios
      .patch(`${BASE_URL}admin/users/deactivate/${userId}`, data)
      .then((res) => {
        if (res && res.status === 200) {
          props.openSnackbar("Successfully deactivated User", "success", true);
          navigate(-1);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Something went wrong", "error", true);
        }
        // navigate(-1);
      });
  };

  return (
    <div className={Design.deactivationPage}>
      <Header title={"Deactivation Form"} />

      <div className={Design.breadCrumbs}>
        <NavLink to="/users/all" className={Design.activeLink}>
          Dashboard
        </NavLink>
        <img className={Design.dot} src={dot} alt="dot" />
        <NavLink to={`/user/${userId}/profile`} className={Design.activeLink}>
          User Profile
        </NavLink>
        <img className={Design.dot} src={dot} alt="dot" />
        <NavLink to={`/user/${userId}/settings`} className={Design.activeLink}>
          Manage
        </NavLink>
        <img className={Design.dot} src={dot} alt="dot" />
        <p className={Design.inActiveLink}>Deactivate</p>
      </div>

      <div className="content">
        <div className={Design.container}>
          <h3 className={Design.heading}>Why are you deactivaing this account ?</h3>
          <div className={Design.reasons} onChange={(e) => setReason(e.target.value)}>
            <span>
              <input id="reason1" type="radio" name="reason" value={"This profile is violeted terms of use"} />
              <label htmlFor="reason1">This profile is violeted terms of use</label>
            </span>
            <span>
              <input id="reason2" type="radio" name="reason" value={"Fake profile"} />
              <label htmlFor="reason2">Fake profile</label>
            </span>
            <span>
              <input id="reason3" type="radio" name="reason" value={"Got married through Garden of Eden matrmiony"} />
              <label htmlFor="reason3">Got married through Garden of Eden matrmiony</label>
            </span>
            <span>
              <input id="reason4" type="radio" name="reason" value={"Found my partner through other matrimony services"} />
              <label htmlFor="reason4">Found my partner through other matrimony services</label>
            </span>
            <span>
              <input id="reason5" type="radio" name="reason" value={"Issues with using the mobile app"} />
              <label htmlFor="reason5">Issues with using the mobile app</label>
            </span>
            <span>
              <input id="reason6" type="radio" name="reason" value={"Not enough profiles"} />
              <label htmlFor="reason6">Not enough profiles</label>
            </span>
            <span>
              <input id="reason7" type="radio" name="reason" value={"Unsatisfied with the customer service offered"} />
              <label htmlFor="reason7">Unsatisfied with the customer service offered</label>
            </span>
            <span>
              <input id="reason8" type="radio" name="reason" value={"Service is too expensive"} />
              <label htmlFor="reason8">Service is too expensive</label>
            </span>
            <span>
              <input id="reason9" type="radio" name="reason" value={"Didn't find a matching profile for so long"} />
              <label htmlFor="reason9">Didn't find a matching profile for so long</label>
            </span>
            <span>
              <input id="reason10" type="radio" name="reason" value={"Other reasons"} />
              <label htmlFor="reason10">Other reasons</label>
            </span>
          </div>
          <textarea
            className={Design.otherReason}
            name="reasonDescription"
            value={feedback}
            placeholder="Enter your feedback..."
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>
          <div className={Design.btnGroup}>
            <span onClick={onSubmit}>
              <PrimaryBtn title={"Submit"} />
            </span>

            <NavLink to={`/user/${userId}`}>
              <SecondaryBtn title={"Cancel"} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(Deactivation);
