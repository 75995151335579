import React from "react";
import networkErrorImage from "images/networkError.svg";
import PrimaryBtn from "components/PrimaryBtn";
import Design from "./Empty.module.css";

export default function NetworkError() {
  const reloadPage = (event) => {
    event.preventDefault();
    window.location.reload();
  };

  return (
    <div className={Design.emptyPage} style={{marginTop: "80px", paddingBottom: "80px"}}>
      <div className={Design.emptyPageContents}>
        <img className={Design.emptyPageImage} src={networkErrorImage} alt="networkErrorImage" />
        <h2 className={Design.emptyPageHeading}>Server Unreachable</h2>
        <p className={Design.emptyPagePara}>This is may be due to server maintenance or a temporary network issue. Please try again later.</p>
        <span style={{ marginTop: "30px" }} onClick={reloadPage}>
          <PrimaryBtn title={"Retry"} />
        </span>
      </div>
    </div>
  );
}
