import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import UserHeading from "user/UserHeading";
import PrimaryBtn from "components/PrimaryBtn";
import SecondaryBtn from "components/SecondaryBtn";
import Design from "./Manage.module.css";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";

function UpdatePassword(props) {
  const { userId } = useParams();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const clearForm = () =>{
    setPassword("");
    setConfirmPassword("");
  }

  const onSubmit = () =>{
    let formIsvalid = true;
    if (!password || password === "") {
      formIsvalid = false;
      props.openSnackbar("Password cannot be empty.", "warning", true);
    } else if (typeof password) {
      if (password.length <= 5) {
        formIsvalid = false;
        props.openSnackbar("Password must contain 6 characters", "info", true);
      }
    }else if(password !== confirmPassword){
      formIsvalid = false;
      props.openSnackbar("Passwords are not same", "info", true);
    }
    if (formIsvalid) {
      updatePassword();
    }
  }

  const updatePassword = () => {
    const data = {
      newPassword: password,
    };
    axios
      .patch(`${BASE_URL}admin/user/update/password/${userId}`, data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            props.openSnackbar("Successfully edited user account password", "success", true);
          }
        }
      })
      .catch((error) => {
        console.log(error)
        if(error.code === "ERR_NETWORK"){
          props.openSnackbar("Network error", "error", true);
        }else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }else{
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  }

  return (
    <div className={Design.action}>
      <UserHeading title={"Change Password"} />
      <div className="textInput">
        <input type="text" placeholder="New Password" value={password} onChange={(e) => setPassword(e.target.value)} autoFocus />
      </div>
      <div className="textInput">
        <input type="text" placeholder="Confirm New Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
      </div>
      <div className={Design.btnGroup}>
        <span onClick={onSubmit}>
          <PrimaryBtn title={"Save"} />
        </span>
        <span onClick={clearForm}>
          <SecondaryBtn title={"Clear"} />
        </span>
      </div>
    </div>
  );
}
 
const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({type: SHOW_TOAST, message, severity, open}),
  };
};

export default connect(null, mapDispatchToProps)(UpdatePassword);