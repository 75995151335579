import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Design from "./ImageModel.module.css";
import GalleryCarousel from "./GalleryCarousel";
import GalleryCarouselImage from "./GalleryCarouselImage";
import { connect } from "react-redux";
import { CLOSE_IMAGE_POPUP } from "store/actions/imagePopupActions";

function ImageModel(props) {
  const { showImagePopup, imageUrl } = props;

  return (
    <div>
      <Modal open={showImagePopup} onClose={() => props.handleClose(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className={Design.imageModelBox}>{typeof imageUrl === "string" ? <GalleryCarouselImage url={imageUrl} /> : <GalleryCarousel />}</div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    showImagePopup: state.imagePopupReducer.showImagePopup,
    imageUrl: state.imagePopupReducer.imageUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleClose: (showImagePopup) => dispatch({ type: CLOSE_IMAGE_POPUP, showImagePopup }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageModel);
