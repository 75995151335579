import React from "react";
import UserHeading from "user/UserHeading";
import BasicDetailsRow from "./BasicDetailsRow";
import Design from "./BasicDetails.module.css";

export default function BasicDetails(props) {
  const { user } = props;
  return (
    <div className={Design.basicDetails}>
      <UserHeading title={"Basic Details"} />
      <div className={Design.contents}>
        <div className={Design.basicDetailsBox}>
          <BasicDetailsRow keys={"Gender"} value={user.gender} />
          <BasicDetailsRow keys={"DOB"} value={user.dateOfBirth} />
          <BasicDetailsRow keys={"Age"} value={user.age} />
          <BasicDetailsRow keys={"Hometown"} value={user.hometown} />
          <BasicDetailsRow keys={"Height"} value={user.height + "cms"} />
          <BasicDetailsRow keys={"Weight"} value={user.weight + "kgs"} />
          <BasicDetailsRow keys={"Complexion"} value={user.complexion} />
          <BasicDetailsRow keys={"Physical Status"} value={user.physicalStatus} />
          <BasicDetailsRow keys={"Body Type"} value={user.bodyType} />
        </div>

        <div className={Design.basicDetailsBox}>
          <BasicDetailsRow keys={"Education"} value={user.education} />
          <BasicDetailsRow keys={"Occupation"} value={user.occupation} />
          <BasicDetailsRow keys={"Religion"} value={user.religion} />
          <BasicDetailsRow keys={"Caste"} value={user.caste} />
          <BasicDetailsRow keys={"Church"} value={user.church} />
          <BasicDetailsRow keys={"Mother Tongue"} value={user.motherTongue} />
          <BasicDetailsRow keys={"Marital Status"} value={user.maritalStatus} />
          <BasicDetailsRow keys={"Family Status"} value={user.familyStatus} />
          <BasicDetailsRow keys={"Ornaments"} value={user.ornaments} />
        </div>
      </div>
      <div className={Design.preferenceBox}>
        <UserHeading title={"Preferences"} />
        {user.preferences ? <p className={Design.preferenceStatus}>{user.preferences}</p> : <p className={Design.preferenceStatus}>No Preferences</p>}
      </div>
    </div>
  );
}
