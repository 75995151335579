import React from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import acceptIcon from "../../images/acceptIcon.svg";
import rejectIcon from "../../images/rejectIcon.svg";
import Design from "./SuccessStories.module.css";
import { BASE_URL } from 'utils/constants/ApplicationConstants';
import { SHOW_TOAST } from 'store/actions/toastAction';

function SuccessStoriesActions(props) {
  const { storyId } = props;

  const handleSuccessStory = (option) =>{

    const status = option === "Accept" ? "APPROVED" : "REJECTED";

    axios
    .patch(`${BASE_URL}admin/success-stories/status-update/${storyId}?status=${status}`)
    .then((res) => {
      if (res) {
        if (res.status === 200) {
          props.openSnackbar("Successfully updated success story status","success",true);
          window.location.reload();
        }
      }
    })
    .catch((error) => {
      error.toJSON();
      if (error.code === "ERR_NETWORK") {
        props.openSnackbar("Network error", "error", true);
      } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
        onHandleUnauthorizedError();
      } else {
        props.openSnackbar("Some error occures. Please try again later!", "error", true);
      }
    });
  }

  return (
    <div className={Design.actionBtns}>
        <span className={Design.acceptActionBtn} >
        <img src={acceptIcon} alt="Accept" onClick={()=> handleSuccessStory("Accept")} />
        </span>
        <span className={Design.rejectActionBtn} >
        <img src={rejectIcon} alt="Reject" onClick={()=> handleSuccessStory("Reject")} />
        </span>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(SuccessStoriesActions);
