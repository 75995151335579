import React from "react";
import Empty from "empty/Empty";
import AdminsMobileView from "./AdminsMobileView";

export default function AdminMobileViews(props) {
  const { admins, tabIndex } = props;
  let sortedArray = [];
  let emptyPageTitle;
  let emptyPageMsg;

  if (tabIndex === 0) {
    sortedArray = admins;
    emptyPageTitle = "No admins found";
    emptyPageMsg = "You haven't added any admins to manage the system.";
  } else if (tabIndex === 1) {
    sortedArray = admins.filter((admin) => admin.type === "ADMIN");
    emptyPageTitle = "No admins found";
    emptyPageMsg = "You haven't added any admins to manage the system."
  } else if (tabIndex === 2) {
    sortedArray = admins.filter((admin) => admin.type === "SUPER_ADMIN");
    emptyPageTitle = "No super admins found";
    emptyPageMsg = "You haven't added any admins as super admin to manage the system."
  }

  const admin = sortedArray.map((admin) => <AdminsMobileView key={admin.id} admin={admin} />);
  return <div>{sortedArray.length === 0 ? <Empty title={emptyPageTitle} msg={emptyPageMsg} /> : <span>{admin}</span>}</div>;
}
