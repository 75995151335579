import React from "react";
import ReactLoading from 'react-loading';
import { PRIMARY_COLOR } from "utils/custom/colorPalette";
import Design from "./LoadingScreen.module.css";

export default function LoadingScreen() {
  return (
    <div className={Design.loadingScreenContainer}>
        <div className={Design.loadingContainer}>
        <ReactLoading type={"spinningBubbles"} color={PRIMARY_COLOR} height={60} width={60} />
        <h1>Loading...</h1>
        </div>
    </div>
  );
}
