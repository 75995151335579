import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import SecondaryBtn from "components/SecondaryBtn";
import Design from "./Manage.module.css";
import { SHOW_TOAST } from "store/actions/toastAction";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";

function DeleteOrDeactivateAccount(props) {
  let navigate = useNavigate();
  const userId = useParams();
  let check = false;
  const confirmDeleteAccount = () => {
    check = !check;
  };

  const deleteUser = () => {
    if (check) {
      axios
        .delete(`${BASE_URL}admin/users/delete/${userId?.userId}`)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              props.openSnackbar("Successfully deleted user account", "success", true);
              navigate("/users/all");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if(error.code === "ERR_NETWORK"){
            props.openSnackbar("Network error", "error", true);
          }else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
            onHandleUnauthorizedError();
          }else{
            props.openSnackbar("Some error occures. Please try again later!", "error", true);
          }
        });
    } else {
      props.openSnackbar("Please confirm delete process", "info", true);
    }
  };

  return (
    <div className={Design.action}>
      <h2 className={Design.deleteHeading}>Delete or Deactivate Account</h2>
      <p className={Design.deleteMsg}>Deleting this account will remove all the data associated with it permanently</p>
      <div className={Design.deleteConfirmCheck}>
        <input className={Design.checkbox} type="checkbox" name="confirmDelete" id="confirmDelete" onClick={confirmDeleteAccount} />
        <label className={Design.label} htmlFor="confirmDelete">
          Are you permanenly delete this account
        </label>
      </div>
      <span onClick={deleteUser}>
        <button className={Design.deleteBtn}>Delete</button>
      </span>
      <hr className={Design.deleteDivider} />
      <Link to={`/user/deactivate/${userId.userId}`}>
        <SecondaryBtn title={"Temporarly Disable Account"} />
      </Link>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(DeleteOrDeactivateAccount);
