import React, { useState } from "react";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

export default function SubscribedUsersTableHeader(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("expiringOn");
  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
    props.onRequestSort(order, orderBy);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell key={"name"} sortDirection={false} style={{ minWidth: 100, backgroundColor: "#F4F6F8", border: "none" }}>
          <TableSortLabel active={orderBy === "id"} direction={orderBy === "id" ? order : "desc"} onClick={createSortHandler("id")}>
            {"Name & Ref.ID"}
            <Box component="span" sx={visuallyHidden}>
              {order === "asc" ? "sorted descending" : "sorted ascending"}
            </Box>
          </TableSortLabel>
        </TableCell>

        <TableCell key={"profileDetails"} sortDirection={false} style={{ minWidth: 100, backgroundColor: "#F4F6F8", border: "none" }}>
          <TableSortLabel active={orderBy === "expiringOn"} direction={orderBy === "expiringOn" ? order : "desc"} onClick={createSortHandler("expiringOn")}>
            {"Profile Details"}
            <Box component="span" sx={visuallyHidden}>
              {order === "asc" ? "sorted descending" : "sorted ascending"}
            </Box>
          </TableSortLabel>
        </TableCell>

        <TableCell key={"phoneNumber"} sortDirection={false} style={{ minWidth: 100, backgroundColor: "#F4F6F8", border: "none" }}>
          {"Phone Number"}
        </TableCell>
        <TableCell key={"dropdown"} sortDirection={false} style={{ minWidth: 10, backgroundColor: "#F4F6F8", border: "none" }}></TableCell>
      </TableRow>
    </TableHead>
  );
}
