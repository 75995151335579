import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import Design from "./ImageModel.module.css";
import loading from "./loading.gif";

function GalleryCarouselImage(props) {
  const { url } = props;
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getImage = async () => {
    const imageUrl = `${BASE_URL}${url}`;
    try {
      const res = await fetch(imageUrl, {
        headers: {
          Authorization: props.authToken,
        },
      });
      const reader = await res.body.getReader();
      let chunks = [];
      reader.read().then(function processText({ done, value }) {
        if (done) {
          const blob = new Blob([chunks]);
          setImage(URL.createObjectURL(blob));
          setTimeout(() => {
            setIsLoading(false);
          }, LOADING_TIME);

          return;
        }
        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;
        return reader.read().then(processText);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getImage();
  }, [url]);

  return (
    <span>
      {isLoading ? (
        <div className={Design.loadingScreen}>
          <img className={Design.loadingGIF} src={loading} alt="loading..." />
        </div>
      ) : (
        <img className={Design.carouselImages} src={image} alt="carousel" />
      )}
    </span>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.authToken,
  };
};

export default connect(mapStateToProps, null)(GalleryCarouselImage);
