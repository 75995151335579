import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import UpdatePassword from "./UpdatePassword";
import UpdateEmail from "./UpdateEmail";
import UpdatePhoneNumber from "./UpdatePhoneNumber";
import DeleteOrDeactivateAccount from "./DeleteOrDeactivateAccount";
import Design from "./Manage.module.css";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import { SHOW_TOAST } from "store/actions/toastAction";
import LoadingScreen from "../../components/LoadingScreen";

function Manage(props) {
  let navigate = useNavigate();
  const { userId } = useParams();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUserData = () => {
    axios
      .get(`${BASE_URL}admin/users/${userId}`)
      .then((res) => {
        setUserData(res.data.userData);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
          navigate(-1);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
          navigate(-1);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <div className={Design.manageSection} style={{ display: !isLoading ? "flex" : "none" }}>
        {userData.uid ? <UpdatePassword /> : null}
        <UpdateEmail />
        <UpdatePhoneNumber />
        <DeleteOrDeactivateAccount />
      </div>
      <span style={{ display: isLoading ? "block" : "none" }}>
        <LoadingScreen />
      </span>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(Manage);
