import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import axios from "axios";
import { BASE_URL, AUTOFILL_SEARCH_TIMER } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";
import NetworkError from "empty/NetworkError";
  
  const pieOptions = {
    title: "Subscription Plans",
    legend: "none",
    pieSliceText: "label",
    pieStartAngle: 100,
  };

export default function PieChartContainer() {
  const [networkError, setNetworkError] = useState(false);
  const [graphData, setGraphData] = useState([]);

  const getAllSubscriptionPlanCount = () =>{
    axios
    .get(`${BASE_URL}admin/transactions/plans`)
    .then((res) => {
      const data = [["Plan", "Count"]];
      const graphData = res.data.transactionsByPlan;
      const subscriptionPlans = res.data.subscriptionPlans;
      graphData.map((graphData, index) => {
        subscriptionPlans.map((plan)=>{
          if(plan.id === graphData.planId){
            data.push([plan.name, graphData.transactionCount]);
          }
        })
      });
      setGraphData(data);
    })
    .catch((error) => {
      console.log(error);
      error.toJSON();
      if (error.code === "ERR_NETWORK") {
        setNetworkError(true);
      }
      if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
        onHandleUnauthorizedError();
      }
    });
  }

  useEffect(() => {
    getAllSubscriptionPlanCount();
  }, [])
  

  return (
    <div className="content" style={{ padding: "5px", width: "100%", height: "100%" }}>
    <Chart chartType="PieChart" data={graphData} options={pieOptions} width={"100%"} height={"100%"} />
  </div>
  )
}
