import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import UserHeading from "user/UserHeading";
import UserProfileImage from "./UserProfileImage";
import BasicDetailsRow from "./BasicDetailsRow";
import Design from "./BasicDetails.module.css";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import verifiedIcon from "../../images/verified.svg";
import LoadingScreen from "../../components/LoadingScreen";

function Contacts(props) {
  let navigate = useNavigate();
  const { userId } = useParams();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUserData = () => {
    axios
      .get(`${BASE_URL}admin/users/${userId}`)
      .then((res) => {
        setUserData(res.data.userData);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
          navigate(-1);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
          navigate(-1);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <div className={Design.contacts} style={{ display: !isLoading ? "flex" : "none" }}>
        <UserProfileImage user={userData} ongetUserData = {()=>getUserData()} />
        <div className={Design.basicDetails}>
          <UserHeading title={"Contact Details"} />
          <div className={Design.contents}>
            <div className={Design.basicDetailsBox}>
              <BasicDetailsRow keys={"Current Residence"} value={userData.hometown} />
              <BasicDetailsRow keys={"Phone Number"} value={userData.countryCode + userData.phoneNumber} />
              <span className={Design.verifiedEmail}>
                <BasicDetailsRow keys={"Email Id"} value={userData.email} />
                {userData.uid ? <img src={verifiedIcon} alt="verified" width={15} /> : null}
              </span>
              <BasicDetailsRow keys={"Whatsapp Number"} value={userData.whatsappCountryCode + userData.whatsappNumber} />
              <BasicDetailsRow keys={"Father's Number"} value={userData.fathersCountryCode + userData.fathersNumber} />
              <BasicDetailsRow keys={"Mother's Number"} value={userData.mothersCountryCode + userData.mothersNumber} />
              <BasicDetailsRow keys={"Pastor's Number"} value={userData.pastorCountryCode + userData.pastorNumber} />
            </div>
          </div>
        </div>
      </div>

      <span style={{ display: isLoading ? "block" : "none" }}>
        <LoadingScreen />
      </span>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(Contacts);
