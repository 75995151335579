import React from "react";
import { useState } from "react";
import Header from "components/Header";
import NetworkError from "empty/NetworkError";
import ScrollableTabsButtonAuto from "../components/ScrollableTabsButtonAuto";
import Requests from "./Requests";

export default function RequestsSection() {
  const [networkError, setNetworkError] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const sort = ["All", "Pending", "Approved", "Rejected"];

  return (
    <div>
      <Header title={"Requests"} />
      <div style={{ display: !networkError ? "block" : "none" }}>
        <div className="content">
          <ScrollableTabsButtonAuto sort={sort} tab={(index) => setTabIndex(index)} />
          <Requests tabIndex={tabIndex} network={(value) => setNetworkError(value)} />
        </div>
      </div>
      <span style={{ display: networkError ? "block" : "none" }}>
        <NetworkError />
      </span>
    </div>
  );
}
