import React from "react";
import { useState } from "react";
import Header from "components/Header";
import ScrollableTabsButtonAuto from "../components/ScrollableTabsButtonAuto";
import EditRequests from "./EditRequests";
import NetworkError from "empty/NetworkError";

export default function UpdateProfileDetailsRequest() {
  const [tabIndex, setTabIndex] = useState(0);
  const [networkError, setNetworkError] = useState(false);

  const sort = ["All", "Pending", "Rejected"];

  return (
    <div>
      <Header title={"Profile Update Requests"} />
      <div style={{ display: !networkError ? "block" : "none" }}>
        <div className="content">
          <ScrollableTabsButtonAuto sort={sort} tab={(index) => setTabIndex(index)} />
          <EditRequests tabIndex={tabIndex} network={(value) => setNetworkError(value)} />
        </div>
      </div>

      <span style={{ display: networkError ? "block" : "none" }}>
        <NetworkError />
      </span>
    </div>
  );
}
