import React from "react";

export default function NotificationsIcon(props) {
  const { color } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 18.3333C10.9167 18.3333 11.6667 17.5833 11.6667 16.6666H8.33338C8.33338 17.5833 9.07504 18.3333 10 18.3333ZM15 13.3333V9.16658C15 6.60825 13.6334 4.46659 11.25 3.89992V3.33325C11.25 2.64159 10.6917 2.08325 10 2.08325C9.30838 2.08325 8.75004 2.64159 8.75004 3.33325V3.89992C6.35838 4.46659 5.00004 6.59992 5.00004 9.16658V13.3333L3.92504 14.4083C3.40004 14.9333 3.76671 15.8333 4.50838 15.8333H15.4834C16.225 15.8333 16.6 14.9333 16.075 14.4083L15 13.3333Z"
        fill={color}
      />
    </svg>
  );
}
