import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import PrimaryBtn from "components/PrimaryBtn";
import SecondaryBtn from "components/SecondaryBtn";
import UpdateImage from "../components/UpdateImage";
import AdminBadge from "components/Badge";
import AdminRadioBtn from "./AdminRadioBtn";
import { useParams, NavLink } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Design from "./EditAdminDetails.module.css";
import dot from "../images/dot.svg";
import { SHOW_TOAST } from "store/actions/toastAction";
import { phoneNumberValidator, emailValidator } from "utils/functions/validators";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";


function EditAdminDetails(props) {
  const { userId } = useParams();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [type, setType] = useState();
  const [profileImage, setProfileImage] = useState(null);

  const getUploadedImage = (value) => {
    setProfileImage(value);
  };

  const getAdminDetails = () => {
    axios
      .get(`${BASE_URL}admin/profile/${userId}`)
      .then((res) => {
        if (res.status === 200) {
          setProfileImage(res.data.admin.profileImageUrl);
          setName(res.data.admin.name);
          setEmail(res.data.admin.email);
          setType(res.data.admin.type);
          setCountrycode(res.data.admin.countryCode);
          setPhoneNumber(res.data.admin.phoneNumber);
        }
      })
      .catch((error) => {
        error.toJSON();
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }else{
          props.openSnackbar("Can't fetch admin details","error", true);
        }
      });
  };

  const handleValidation = () => {
    let formIsvalid = true;
    if (!name || name === "" || name === null) {
      formIsvalid = false;
      props.openSnackbar("Admin name cannot be empty", "warning", true);
    } else if (!phoneNumber || phoneNumber === "" || !countrycode || countrycode === "") {
      formIsvalid = false;
      props.openSnackbar("Phone number cannot be empty", "warning", true);
    } else if (!phoneNumberValidator(countrycode, phoneNumber)) {
      formIsvalid = false;
      props.openSnackbar("Invalid phonenumber", "error", true);
    } else if (!email || email === "" || email === null) {
      formIsvalid = false;
      props.openSnackbar("Email cannot be empty", "warning", true);
    } else if (!emailValidator(email)) {
      formIsvalid = false;
      props.openSnackbar("Invalid email", "error", true);
    } else if (!type || type === undefined || type === null) {
      formIsvalid = false;
      props.openSnackbar("Select an admin type", "warning", true);
    } else if (profileImage === null) {
      formIsvalid = false;
      props.openSnackbar("Please choose a profile image", "warning", true);
    } else {
      formIsvalid = true;
    }
    return formIsvalid;
  };

  useEffect(() => {
    getAdminDetails();
  }, [userId]);


  const handleSubmit = () => {
    if(handleValidation()){
      const data = new FormData();
      data.append("profileImage", profileImage);
      data.append("name", name);
      data.append("email", email);
      data.append("type", type);
      data.append("countryCode", countrycode);
      data.append("phoneNumber", phoneNumber);

      axios
        .patch(`${BASE_URL}admin/admins/edit/${userId}`, data)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              props.openSnackbar("Successfully edited admin details","success",true);
              getAdminDetails();
            }
          }
        })
        .catch((error) => {
          error.toJSON();
          if (error.code === "ERR_NETWORK") {
            props.openSnackbar("Network error", "error", true);
          } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
            onHandleUnauthorizedError();
          } else if (error.response.data.message === "Validation error") {
            props.openSnackbar("Phone number or email id already exists", "error", true);
          }
        });
    }
  }

  return (
    <div>
      <Header title={"Admin Profile"} />
      <div className={Design.breadCrumbs}>
        <NavLink to="/admins" className={Design.activeLink}>
          Admins
        </NavLink>
        <img className={Design.dot} src={dot} alt="dot" />
        <p className={Design.inActiveLink}>Admin Profile</p>
      </div>

      <div className={Design.contents}>
        <div className={Design.updateProfilepic}>
          <span className={Design.updateProfilepicContents}>
            <UpdateImage onImageSelected={getUploadedImage} url={profileImage} />
            <h3 className={Design.adminName}>{name}</h3>
            <AdminBadge type={type} />
          </span>
        </div>

        {/* update information */}
        <div className={Design.updateDetails}>
          <h3 className={Design.account}>Account</h3>
          <p className={Design.info}>This information can be edited</p>
          <hr className={Design.horizontalLine} />
          <div className={Design.editForm}>
            <div className="textInput">
              <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} autoFocus />
            </div>
            <div className="phoneInput">
              <PhoneInput
                inputStyle={{ border: "none" }}
                country={"in"}
                value={countrycode + phoneNumber}
                onChange={(value, data) => {
                  setCountrycode(data.dialCode);
                  setPhoneNumber(value.slice(data.dialCode.length));
                }}
              />
            </div>
            <div className="textInput">
              <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <AdminRadioBtn adminType={(value) => setType(value)} type={type}/>
            <div className={Design.buttonGrp}>
              <span onClick={handleSubmit}>
                <PrimaryBtn title={"Save"} />
              </span>
              <NavLink to="/admins"> 
                <SecondaryBtn title={"Cancel"} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(EditAdminDetails);
