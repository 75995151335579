import React from "react";
import Chip from "@mui/material/Chip";

export default function Status(props) {
  const { status, sign } = props;

  const error = {
    color: "#B72136",
    backgroundColor: "#b721361a",
    fontSize: "11px",
    height: "24px",
    borderRadius: "5px",
  };

  const success = {
    color: "#00AB56",
    backgroundColor: "rgba(0,171,86, 0.08)",
    fontSize: "11px",
    height: "24px",
    borderRadius: "5px",
  };

  const warning = {
    color: "#F3B702",
    backgroundColor: "rgba(255,193,7, 0.08)",
    fontSize: "11px",
    height: "24px",
    borderRadius: "5px",
  };

  const info = {
    color: "rgb(0, 30, 60)",
    backgroundColor: "rgba(144, 202, 249, 0.08)",
    fontSize: "11px",
    height: "24px",
    borderRadius: "5px",
  }

  if (sign === "error") {
    return <Chip sx={error}  label={status} />;
  } else if (sign === "success") {
    return <Chip sx={success} label={status} />;
  } else if (sign === "info"){
    return <Chip sx={info} label={status} />;
  }else{
    return <Chip sx={warning} label={status} />;
  }
}
