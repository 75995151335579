import React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Person from "components/Person";
import Design from "./FinancesDashboard.module.css";
import { dateFormatter } from "../utils/functions/dateFormatter"

export default function TransactionHistoryTableRow(props) {
  const { transaction } = props;

  return (
    <TableRow sx={{ maxHeight: 440 }}>
        <TableCell sx={{ border: "none" }}>
        <div className={Design.checkcol}>
        <Person id={transaction.userId} name={transaction.userName} referenceNumber={transaction.referenceNumber} profileImageUrl={transaction.profileImageUrl} />
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <div>
            <p className={Design.subscriptionPlan}>{transaction.subscriptionPlan}</p>
            <p className={Design.subscriptionDate}>Subscription ends on {dateFormatter(transaction.expiryDate)}</p>
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <p className={Design.transactionId}>{transaction.transactionId}</p>
      </TableCell>
    </TableRow>
  )
}
