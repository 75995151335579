import { initializeApp } from "firebase/app";
import "index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reportWebVitals from "reportWebVitals";
import rootReducer from "store/reducers/rootReducer";
import App from "./App";
import { THEME } from "utils/custom/Theme";
import { ThemeProvider } from "@mui/material/styles";
const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const firebaseConfig = {
  apiKey: "AIzaSyByhG3AQibPpMoHn7UeK3W-8ZJ2JbCmBL4",
  authDomain: "garden-of-eden-matrimony.firebaseapp.com",
  projectId: "garden-of-eden-matrimony",
  storageBucket: "garden-of-eden-matrimony.appspot.com",
  messagingSenderId: "374662526938",
  appId: "1:374662526938:web:79cee7c6863b27d09732e3",
  measurementId: "G-PL1FSHB8F4",
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

const app = (
  <ThemeProvider theme={THEME}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(root.render(<React.StrictMode>{app}</React.StrictMode>));

reportWebVitals();
