import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { onMessageListener } from "../firebase";
import notiIcon from "../images/notiIcon.png";
import Design from "./Notifications.module.css";

const Notifications = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div className={Design.notificationContainer}>
        <div>
          <img src={notiIcon} width={60} alt="GE-Matrimony" />
        </div>
        <div className={Design.notificationDetails}>
          <p className={Design.notificationTitle}>{notification?.title}</p>
          <p className={Design.notificationbody}>{notification?.body}</p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  //   requestForToken();

  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log("failed: ", err));

  return <Toaster />;
};

export default Notifications;