import React from "react";
import { useState } from "react";
import axios from "axios";
import Timer from "./Timer";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import Design from "./LoginPage.module.css";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { requestForToken } from "../firebase";
import { ACTION_AUTH_SUCCESS } from "store/actions/authActions";
import { SHOW_TOAST } from "store/actions/toastAction";

function OTP(props) {
  const [isResendBtnDisabled, setIsResendBtnDisabled] = useState(true);
  const [otp, setOtp] = useState();

  const phoneNumber = localStorage.getItem("phoneNumber");
  const countryCode = localStorage.getItem("countryCode");

  const disableResendBtnState = (btnState) => {
    setIsResendBtnDisabled(btnState);
  };

  const handleSubmit = (otpValue) => {
    const otpdata = {
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      otp: otpValue,
    };
    axios
      .post(`${BASE_URL}admin/otp/verify-admin`, otpdata)
      .then((res) => {
        if (res) {
          if (res.data.adminData) {
            props.onLoginSuccessful(res.data.authToken, res.data.adminData.name, res.data.adminData.profileImageUrl, res.data.adminData.type, res.data.adminData.id);
            requestForToken(res.data.authToken);
          }
        } else {
          props.openSnackbar("Invalid OTP", "error", true);
        }
      })
      .catch((error) => {
        JSON.stringify(error);
        console.log(error);
        if (error.response) {
          props.openSnackbar("Error in OTP verification", "error", true);
        }
      });
  };

  const resendOtp = (event) => {
    const resend = { countryCode: countryCode, phoneNumber: phoneNumber };
    axios
      .post(`${BASE_URL}admin/otp/resend`, resend)
      .then((res) => {
        if (res) {
          if (res.data.message === "Success") {
            disableResendBtnState(true);
          }
        }
      })
      .catch((error) => {
        props.openSnackbar("Can't resend OTP", "error", true);
        console.error(error);
      });
  };

  const handleChange = (otp) => {
    setOtp(otp);
    if (otp.length === 4) {
      handleSubmit(otp);
    }
  };

  return (
    <div>
      <Timer
        onTimerExpired={() => {
          disableResendBtnState(false);
        }}
        running={isResendBtnDisabled}
      />

      <OtpInput
        inputStyle={{
          width: "50%",
          height: "20px",
          fontSize: "15px",
          marginLeft: "2px",
          marginRight: "10px",
          border: "transparent",
          outline: "none",
          borderBottom: "2px solid rgba(0,0,0,0.3)",
          borderRadius: "0",
        }}
        onChange={handleChange}
        numInputs={4}
        separator={<span></span>}
        shouldAutoFocus={true}
        value={otp}
        isInputNum={true}
      />

      <div className={Design.buttonGroup}>
        <button disabled={isResendBtnDisabled} className={Design.resendBtn} onClick={resendOtp}>
          Resend
        </button>
        <button className={Design.verifyBtn} onClick={handleSubmit}>
          Verifiy
        </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginSuccessful: (authToken, name, profileImageUrl, admin, id) => dispatch({ type: ACTION_AUTH_SUCCESS, authToken, name, profileImageUrl, admin,id }),
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(OTP);
