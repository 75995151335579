import React from "react";
import RequestMobileView from "./RequestMobileView";
import Empty from "empty/Empty";

export default function RequestMobileViews(props) {
  const { requests, tabIndex } = props;
  let emptyPageTitle;
  let emptyPageMsg;

  if (tabIndex === 0) {
    emptyPageTitle = "All caught up";
    emptyPageMsg = "You haven't received any request yet.";
  } else if (tabIndex === 1) {
    emptyPageTitle = "No pending request found";
    emptyPageMsg = "There are no pending requests";
  } else if (tabIndex === 2) {
    emptyPageTitle = "No approved request found";
    emptyPageMsg = "You haven't approved any request yet.";
  } else if (tabIndex === 3) {
    emptyPageTitle = "No rejected request found";
    emptyPageMsg = "You haven't rejected any request yet.";
  }

  const request = requests.map((request) => <RequestMobileView key={request.id} request={request} />);

  return <div>{requests.length === 0 ? <Empty title={emptyPageTitle} msg={emptyPageMsg} /> : <span>{request}</span>}</div>;
}
