import React from "react";
import Status from "components/Status";
import clock from "images/clock.svg";
import Design from "./UserRequests.module.css";
import { dateFormatter } from "utils/functions/dateFormatter";

export default function UserRequest(props) {
  const { request } = props;
  const name = request.requestedIdDetails ? request.requestedIdDetails.name : null;
  let sign;
  if (request.status === "PENDING") {
    sign = "warning";
  } else if (request.status === "APPROVED") {
    sign = "success";
  } else if (request.status === "REJECTED") {
    sign = "error";
  }

  return (
    <div className={Design.request}>
      {request.requestedTo === "ADMIN" ? (
        <h3 className={Design.requestMsg}>
          Requested <span className={Design.requestedName}>{name}</span>'s details from admin
        </h3>
      ) : (
        <h3 className={Design.requestMsg}>
          Sent an interest request to <span className={Design.requestedName}>{name}</span>
        </h3>
      )}
      <div className={Design.requestStatusWrap}>
        <Status status={request.status} sign={sign} />
        <div className={Design.requestTime}>
          <img className={Design.notificationIcon} src={clock} alt="clock" />
          {dateFormatter(request.updatedAt)}
        </div>
      </div>
    </div>
  );
}
