import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SuccessStory from "./SuccessStory";

export default function SuccessStoriesTable(props) {
  const {successStories} = props;
  const columns = [
    { id: "marriedUser", label: "Married User", minWidth: 100 },
    { id: "marriedToUser", label: "Married To User", minWidth: 100 },
    { id: "title", label: "Title", minWidth: 100 },
    { id: "action", label: "", minWidth: 100 },
  ];

  const row = successStories.map((story) => <SuccessStory key={story.id} story={story} />);

  return (
    <div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, backgroundColor: "#F4F6F8", border: "none" }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
                {row}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
