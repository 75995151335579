import qrCode from "images/sharedDocument/qrCode.jpeg";
import androidAppIcon from "images/sharedDocument/androidApp.png";
import appleAppIcon from "images/sharedDocument/appleApp.png";
import isoIcon from "images/sharedDocument/isoIcon.png";
import verifiedIcon from "images/sharedDocument/verifiedIcon.png";
import whatsapp from "../images/sharedDocument/whatsapp.png";

import Design from "./FooterComponent.module.css";

export default function FooterComponent() {
  return (
    <div className={Design.footerSection}>
      <div className={Design.qualityIconsContainer}>
        <div className={Design.qualityIcons}>
          <img src={verifiedIcon} alt="verifiedIcon" />
          <img src={isoIcon} alt="isoIcon" />
        </div>
      </div>
      <div className={Design.qrCodeSection}>
        <img className={Design.qrCode} src={qrCode} alt="qrCode" />
        <div>
          <p className={Design.footerTextOne}>Scan QR code to</p>
          <p className={Design.footerTextTwo}>Download</p>
          <p className={Design.footerTextTwo}>The App Now!</p>
        </div>
        <div className={Design.playstoreIconContainer}>
          <img className={Design.appleAppIcon} src={appleAppIcon} alt="appleAppIcon" />
          <img className={Design.androidAppIcon} src={androidAppIcon} alt="androidAppIcon" />
        </div>
      </div>
    </div>
  );
}
