import React, { useState, useEffect } from "react";
import Header from "components/Header";
import NetworkError from "empty/NetworkError";
import AnalyticsDashboardCards from "./AnalyticsDashboardCards";
import AnalyticsDashboardLineChart from "./AnalyticsDashboardLineChart";
import LoadingScreen from "../components/LoadingScreen";
import Design from "./AnalyticalDashboard.module.css";
import { LOADING_TIME } from "utils/constants/ApplicationConstants";

export default function AnalyticalDashboard() {
  const [networkError, setNetworkError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, LOADING_TIME);
  }, [])

  return (
    <div>
      <div className={Design.headerSection}>
        <Header title={"Analytics"} />
      </div>
      <div style={{ display: !networkError && !isLoading ? "block" : "none" }}>
        <AnalyticsDashboardCards network={(value) => setNetworkError(value)} />
        <div className={Design.chartContainer}>
          <AnalyticsDashboardLineChart />
        </div>
      </div>

      <span style={{ display: networkError && !isLoading ? "block" : "none" }}>
        <NetworkError />
      </span>

      { isLoading ? <LoadingScreen /> : null }

    </div>
  );
}
