import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, AUTOFILL_SEARCH_TIMER } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";
import NetworkError from "empty/NetworkError";

import { Chart } from "react-google-charts";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const options = {
  title: "Users",
  curveType: "function",
  legend: { position: "bottom" },
};

export default function AnalyticsDashboardLineChart() {
  const [networkError, setNetworkError] = useState(false);
  const [graphData, setGraphData] = useState([]);

  const getUsersData = () => {
    axios
      .get(`${BASE_URL}admin/analytics/monthly`)
      .then((res) => {
        const data = [["Month", "Registered Users", "Deactivated Users"]];
        const graphData = res.data.groupdata;
        graphData.map((graphData) => {
          const monthName = graphData.day + monthNames[graphData.month - 1] + graphData.year;
          data.push([monthName, graphData.profileCount, graphData.deactivatedProfileCount]);
        });
        setGraphData(data);
      })
      .catch((error) => {
        console.log(error);
        error.toJSON();
        if (error.code === "ERR_NETWORK") {
          setNetworkError(true);
        }
        if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }
      });
  };

  useEffect(() => {
    getUsersData();
  }, []);

  return (
    <div className="content" style={{ padding: "5px", width: "100%", height: "100%" }}>
      <Chart chartType="LineChart" width="100%" height="100%" data={graphData} options={options} />
    </div>
  );
}
