import React from "react";

export default function DashboardIcon(props) {
    const {color} = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33333 10.8333H8.33333C8.79167 10.8333 9.16667 10.4583 9.16667 10V3.33333C9.16667 2.875 8.79167 2.5 8.33333 2.5H3.33333C2.875 2.5 2.5 2.875 2.5 3.33333V10C2.5 10.4583 2.875 10.8333 3.33333 10.8333ZM3.33333 17.5H8.33333C8.79167 17.5 9.16667 17.125 9.16667 16.6667V13.3333C9.16667 12.875 8.79167 12.5 8.33333 12.5H3.33333C2.875 12.5 2.5 12.875 2.5 13.3333V16.6667C2.5 17.125 2.875 17.5 3.33333 17.5ZM11.6667 17.5H16.6667C17.125 17.5 17.5 17.125 17.5 16.6667V10C17.5 9.54167 17.125 9.16667 16.6667 9.16667H11.6667C11.2083 9.16667 10.8333 9.54167 10.8333 10V16.6667C10.8333 17.125 11.2083 17.5 11.6667 17.5ZM10.8333 3.33333V6.66667C10.8333 7.125 11.2083 7.5 11.6667 7.5H16.6667C17.125 7.5 17.5 7.125 17.5 6.66667V3.33333C17.5 2.875 17.125 2.5 16.6667 2.5H11.6667C11.2083 2.5 10.8333 2.875 10.8333 3.33333Z"
        fill={color}
      />
    </svg>
  );
}
