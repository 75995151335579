import React from "react";
import Design from "./PersonalDetails.module.css";

export default function PersonalDetails(props) {
  const { keys, value } = props;

  let truncatedStr;
  let ellipsis = false;
  if ((keys === "Hometown" || keys === "Education" || keys === "Occupation" || keys === "Current Residence") && value?.length > 50) {
    truncatedStr = value?.substring(0, 45);
    ellipsis = true;
  } else if (keys === "Preferences" && value?.length > 90) {
    truncatedStr = value?.substring(0, 100);
    ellipsis = true;
  } else {
    truncatedStr = value;
  }

  return (
    <div className={Design.PersonalDetailsStrip}>
      <p className={Design.keys}>{keys}</p>
      <p className={Design.value}>
        {truncatedStr}
        <span style={{ display: ellipsis ? "inline" : "none" }}>...</span>
      </p>
    </div>
  );
}
