import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Person from "components/Person";
import AdminBadge from "../components/Badge";
import IconBox from "./IconBox";
import Design from "./AdminTable.module.css";

export default function Admin(props) {
  const { admin } = props;

  return (
    <TableRow sx={{ maxHeight: 440 }}>
      <TableCell sx={{ border: "none" }}>
        <Person name={admin.name} referenceNumber={null} admin={"admin"} profileImageUrl={admin.profileImageUrl} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <p className={Design.email}>{admin.email}</p>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <AdminBadge type={admin.type} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <IconBox id={admin.id}/>
      </TableCell>
    </TableRow>
  );
}
