import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PrimaryBtn from "components/PrimaryBtn";
import SecondaryBtn from "components/SecondaryBtn";
import Design from "./ProfileUpdate.module.css";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";

function ProfileUpdateActionBtns(props) {
  const { id, updateProfileAction } = props;
  const [comment, setComment] = useState();

  const data = {
    comments: comment,
  };

  const acceptProfileUpdateRequest = () => {
    axios
      .patch(`${BASE_URL}admin/users/update/profile/approve/${id}`)
      .then((res) => {
        props.openSnackbar("User profile update request accepted successfully", "success", true);
        updateProfileAction();
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  };

  const rejectProfileUpdateRequest = () => {
    axios
      .patch(`${BASE_URL}admin/users/update/profile/reject/${id}`, data)
      .then((res) => {
        props.openSnackbar("User profile update request rejected successfully", "success", true);
        updateProfileAction();
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  };

  return (
    <div className={Design.actions}>
      <div className={Design.comment}>
        <input type="text" placeholder="Reason for rejection (Optional)" onChange={(event) => setComment(event.target.value)}></input>
      </div>
      <div className={Design.actionBtnGroups}>
        <span onClick={acceptProfileUpdateRequest}>
          <PrimaryBtn title={"Accept"} />
        </span>
        <span onClick={rejectProfileUpdateRequest}>
          <SecondaryBtn title={"Reject"} />
        </span>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(ProfileUpdateActionBtns);
