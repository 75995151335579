import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import subscribedUser from "../images/subscribedUser.svg";
import Person from "components/Person";
import LongMenu from "./LongMenu";
import { dateFormatter } from "../utils/functions/dateFormatter";
import Design from "./Users.module.css";
import { UPDATE_USERDATA } from "store/actions/selectUserActions";

function Users(props) {
  const { user } = props;
  const location = useLocation();
  const path = location.pathname;
  let options = [];

  const subscription = user.subscription ? user.subscription : null;
  const subscriptionExpiringOn = user.subscription ? user.subscription.expiringOn : null;
  const subscriptionIsCancelled = user.subscription ? user.subscription.isCancelled : null;
  const subscriptionIsActive = user.subscription ? user.subscription.isActive : null;
  const subscriptionCancelledOn = user.subscription ? user.subscription.updatedAt : null;

    var endDate = moment(subscriptionExpiringOn).format("DD/MM/YYYY");
    var today = moment().format("DD/MM/YYYY");
    endDate = new Date(endDate.split('/')[2],endDate.split('/')[1]-1,endDate.split('/')[0]);
    today = new Date(today.split('/')[2],today.split('/')[1]-1,today.split('/')[0]);
    const timeDiff = Math.abs(today.getTime() - endDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 

  let date;
  if (user.verifiedOn && user.verifiedOn !== null && user.isVerified) {
    if (path.includes("/subscribe") && subscription && diffDays >= 0 && !subscriptionIsCancelled) {
      date = `Expiring in ${diffDays} days`
    } else if (subscription && diffDays >= 0 && subscriptionIsCancelled) {
      date = `Subscription cancelled on ${dateFormatter(subscriptionCancelledOn)}`;
    } else if (subscription && diffDays < 0) {
      date = `Verified on ${dateFormatter(user.verifiedOn)}`;
    } else {
      date = `Approved on ${dateFormatter(user.verifiedOn)}`;
    }
  } else if (user.createdAt && !user.isVerified) {
    date = `Registered on ${dateFormatter(user.createdAt)}`;
  }else if (path.includes("/subscribe") && subscription && diffDays >= 0 && (!subscriptionIsCancelled || subscriptionIsCancelled === null) ) {
    date = `Expiring in ${diffDays} days`
  } else if (subscription && diffDays >= 0 && subscriptionIsCancelled) {
    date = `Subscription cancelled on ${dateFormatter(subscriptionCancelledOn)}`;
  }

  if (user.isVerified && diffDays >= 0) {
    options = ["Share", "Deactivate", "Delete"];
  }else if (user.isVerified && diffDays < 0) {
    options = ["Deactivate", "Delete"];
  }else if (!user.isVerified && diffDays < 0) {
    options = ["Deactivate", "Delete"];
  }else if (!user.isVerified) {
    options = ["Reject", "Approve"];
  }

  const getSelectedUser = () => {
    props.onPassSelectedUser(user);
  };

  const isSelected = props.selectedUsers.find((selectedUser) => selectedUser.referenceNumber === user.referenceNumber) !== undefined;

  return (
    <TableRow sx={{ maxHeight: 440 }}>
      <TableCell sx={{ border: "none" }}>
        <div className={Design.checkcol}>
          {diffDays >= 0 && path.includes("/subscribe") ? <input type="checkbox" className={Design.checkbox} readOnly={true} onClick={getSelectedUser} checked={isSelected}></input> : null}
          <Person id={user.id} name={user.name} referenceNumber={user.referenceNumber} profileImageUrl={user.profileImageUrl} />
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <div>
          <p className={Design.profileDetails}>
            {user.gender} {user.age} yrs, {user.hometown} {diffDays >= 0 && path.includes("/Pending") ? <img src={subscribedUser} alt="subscribedUser" width={18} /> : null}
          </p> 
          <p className={Design.approvedDate}>{date}</p>
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <p className={Design.phoneNumber}>
          {user.countryCode} {user.phoneNumber}
        </p>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <LongMenu options={options} user={user} />
      </TableCell>
    </TableRow>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPassSelectedUser: (selectedUser) => dispatch({ type: UPDATE_USERDATA, selectedUser }),
  };
};

const mapStateToProps = (state) => {
  return {
    selectedUsers: state.selectUserReducer.selectedUsers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);