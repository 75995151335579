import axios from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SHOW_TOAST } from "store/actions/toastAction";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import BasicDetails from "./BasicDetails";
import Design from "./General.module.css";
import UserProfileImage from "./UserProfileImage";
import LoadingScreen from "../../components/LoadingScreen";

function General(props) {
  let navigate = useNavigate();
  const { userId } = useParams();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUserData = () => {
    axios
      .get(`${BASE_URL}admin/users/${userId}`)
      .then((res) => {
        setUserData(res.data.userData);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
          navigate(-1);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
          navigate(-1);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <div className={Design.general} style={{ display: !isLoading ? "flex" : "none" }}>
        <UserProfileImage user={userData} ongetUserData = {()=>getUserData()} />
        <BasicDetails user={userData} />
      </div>
      <span style={{ display: isLoading ? "block" : "none" }}>
        <LoadingScreen />
      </span>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(General);
