import { useState } from "react";
import Design from "./LoginPage.module.css";
import OTP from "./OTP";
import Form from "./Form";

export default function LoginForm() {
  const [loginBtn, setloginBtn] = useState(false);

  if( loginBtn){
    const loginForm = document.querySelector(`.${Design.loginForm}`);
    const otpForm = document.querySelector(`.${Design.otpForm}`);
    loginForm.style.transform = "translateX(100%)";
    otpForm.style.transform = "translateX(100%)";
  }

  return (
    <div className={Design.loginFormContainer}>
      <div>
        <h2>Login into Admin panel</h2>
        <div className={Design.loginFormSlider}>
          <div className={Design.loginForm}>
            <Form
              onclickLoginBtn={() => {
                setloginBtn(true);
              }}
            />
          </div>
          <div className={Design.otpForm}>
            <OTP />
          </div>
        </div>
      </div>
    </div>
  );
}
