import React from "react";
import DashboardIcon from "../customIcons/DashboardIcon";
import RequetsIcon from "customIcons/RequetsIcon";
import AdminsIcon from "customIcons/AdminsIcon";
import NotificationsIcon from "customIcons/NotificationsIcon";
import UpdateProfileIcon from "customIcons/UpdateProfileIcon";
import SuccessStoriesIcon from "customIcons/SuccessStoriesIcon";
import FeedbackIcon from "customIcons/FeedbackIcon";
import ReportsIcon from "customIcons/ReportsIcon";
import LogoutIcon from "customIcons/LogoutIcon";
import FinanceIcon from "customIcons/FinanceIcon";
import AnalyticsIcon from "customIcons/AnalyticsIcon";
import Design from "./NavigationBar.module.css";

export default function NavigationLink(props) {
  const { title, color } = props;

  let iconComponent;
  if (title === "Users") {
    iconComponent = <DashboardIcon color={color} />;
  } else if (title === "Requests") {
    iconComponent = <RequetsIcon color={color} />;
  } else if (title === "Admins") {
    iconComponent = <AdminsIcon color={color} />;
  } else if (title === "Notifications") {
    iconComponent = <NotificationsIcon color={color} />;
  }else if (title === "Profile Updates") {
    iconComponent = <UpdateProfileIcon color={color} />;
  }else if (title === "Success Stories") {
    iconComponent = <SuccessStoriesIcon color={color} />;
  }else if (title === "Reports") {
    iconComponent = <ReportsIcon color={color} />;
  }else if (title === "Feedback Forms") {
    iconComponent = <FeedbackIcon color={color} />;
  }else if (title === "Finances") {
    iconComponent = <FinanceIcon color={color} />;
  }else if (title === "Analytics") {
    iconComponent = <AnalyticsIcon color={color} />;
  }

  else if (title === "Logout") {
    iconComponent = <LogoutIcon color={color} />;
  }

  return (
    <div className={Design.navlink}>
      <div>
        {iconComponent}
        <p style={{"color": color}} className={Design.navlinkName}>{title}</p>
      </div>
    </div>
  );
}
