import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SuccessStoriesMenu from "./SuccessStoriesMenu";
import Status from "../../components/Status";
import Person from "../../components/Person";
import SuccessStoriesActions from "./SuccessStoriesActions";
import expandLess from "../../images/expandLess.svg";
import expandMore from "../../images/expandMore.svg";
import Design from "./SuccessStoryMobileView.module.css";

export default function SuccessStoryMobileView(props) {
  const {story} = props;
  let navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  let sign;

  if(story.status === "APPROVED" ){
    sign = "success";
  }
  if(story.status === "PENDING" ){
    sign = "warning";
  }
  if(story.status === "REJECTED" ){
    sign = "error";
  }

  return (
    <div>
      <div className={Design.container}>
        <p className={Design.title} onClick={()=> navigate(`/success-stories/${story.id}`) } > {story.title} </p>
        <span className={Design.actionBtnsContainer}>
          <div className={Design.expandIcons}>
            {isOpen ? <img src={expandLess} alt="expandLess" onClick={toggleAccordion} /> : <img src={expandMore} alt="expandMore" onClick={toggleAccordion} />}
          </div>
          { story.status === "PENDING" ? ( <SuccessStoriesMenu storyId={story.id} />) : <Status status={story.status} sign={sign} />}
        </span>
      </div>
      {/* accordion */}
      {isOpen ? (
        <div className={Design.accordion}>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Married user</p>
            <Person referenceNumber={story.marriedUser.referenceNumber} id={story.marriedUser.id} name={story.marriedUser.name} size={"25px"} profileImageUrl={story.marriedUser.profileImageUrl} />
          </div>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Married to user</p>
            <Person referenceNumber={story.marriedToUser.referenceNumber} id={story.marriedToUser.id} name={story.marriedToUser.name} size={"25px"} profileImageUrl={story.marriedToUser.profileImageUrl} />
          </div>
          {/* <div className={Design.actionKeys}>
            <SuccessStoriesActions storyId={story.id} />
          </div> */}
        </div>
      ) : null}
    </div>
  );
}
