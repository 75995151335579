import NavigationBar from "../navigationBar/NavigationBar";
import axios from "axios";
import { useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "components/AppBar";
import Popup from "components/Popup";
import { Outlet, useLocation } from "react-router-dom";
import Design from "./Container.module.css";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";

function Container(props) {
  const location = useLocation();
  const path = location.pathname;
  axios.defaults.headers.common["Authorization"] = props.authToken;

  const deleteUser = () => {
    axios
      .delete(`${BASE_URL}admin/users/delete/${props.userId}`)
      .then((res) => {
        if (res) {
          if (res.status === 200 && path.includes("/users")) {
            props.openSnackbar("Successfully deleted user account", "success", true);
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        error.toJson();
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network Error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  };

  const deleteReport = () => {
    axios
      .delete(`${BASE_URL}admin/users/reported/${props.userId}`)
      .then((res) => {
        if (res) {
          if (res.status === 200 && path.includes("/users")) {
            props.openSnackbar("Successfully removed record", "success", true);
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        error.toJson();
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network Error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  };

  useEffect(() => {
    if (props.confirmAction && props.action === "delete") {
      deleteUser();
    }
    if (props.confirmAction && props.action === "remove") {
      deleteReport();
    }
  }, [props.action, props.confirmAction]);

  return (
    <div>
      <NavigationBar />
      <div className={Design.container}>
        <AppBar />
        <span>
        <Outlet />
        </span>
      </div>
      {props.showPopup ? <Popup open={true} /> : null}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    showPopup: state.popupReducer.showPopup,
    authToken: state.authReducer.authToken,
    action: state.popupReducer.action,
    confirmAction: state.popupReducer.confirmAction,
    userId: state.popupReducer.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);