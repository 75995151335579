import React from "react";
import adminIcon from "../images/adminIcon.svg";
import superAdminIcon from "../images/superAdminIcon.svg";
import userIcon from "../images/userIcon.svg";
import Chip from "@mui/material/Chip";


export default function AdminBadge(props) {
  const { type } = props;

  const SUPER_ADMIN = {
    backgroundColor: "rgba(128,0,255, 0.08)",
    color: "#7000DF",
    borderRadius: "5px",
    fontSize: "11px",
    height: "24px",
    '@media (max-width: 400px)' : {
      fontSize: "9px",
    }
  };
  const USER = {
    backgroundColor: "rgba(0,171,86, 0.08)",
    color: '#00AB56',
    borderRadius: "5px",
    fontSize: "11px",
    height: "24px",
    '@media (max-width: 400px)' : {
      fontSize: "9px",
    }
  };
  const ADMIN = {
    backgroundColor: "#ffc1071a",
    color: "#F3B702",
    borderRadius: "5px",
    fontSize: "11px",
    height: "24px",
    '@media (max-width: 400px)' : {
      fontSize: "9px",
    }
  };

  if(type === "SUPER_ADMIN"){
    return <Chip sx={SUPER_ADMIN} icon={<img src={superAdminIcon} width={12} alt="*" />} label="SUPER ADMIN" />;
  }else if(type === "ADMIN"){
    return <Chip sx={ADMIN} icon={<img src={adminIcon} width={12} alt="*" />} label="ADMIN" />;
  }else{
    return <Chip sx={USER} icon={<img src={userIcon}width={12} alt="*" />} label="USER" />;
  }
}
