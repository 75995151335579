import React, { useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import domtoimage from "dom-to-image";
import SharedComponent from "./SharedComponent";
import SecondaryBtn from "../components/SecondaryBtn";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";
import Design from "./ExportPdfComponent.module.css";
import { exportComponentAsPNG, exportComponentAsJPEG } from "react-component-export-image";

function ExportPdfComponent(props) {
  let navigate = useNavigate();
  const { userId } = useParams();
  const componentRef = useRef();
  const [userData, setUserData] = useState([]);

  const getUserData = () => {
    axios
      .get(`${BASE_URL}admin/users/${userId}`)
      .then((res) => {
        setUserData(res.data.userData);
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
          navigate(-1);
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
          navigate(-1);
        }
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  var params = {
    html2CanvasOptions: {
      // x: -10,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    },
  };

  const fileName = userData.referenceNumber;

  return (
    <div className={Design.mainExportPdfContainer}>
      <div className={Design.exportPdfTitileComponent}>
        <div className={Design.exportPdfTitile}>
          <p className={Design.label}>Profile is ready to be shared</p>
          <span
            onClick={() => {
              exportComponentAsPNG(componentRef, {
                fileName,
                html2CanvasOptions: {
                  // x: -10,
                  scrollX: -window.scrollX,
                  scrollY: -window.scrollY,
                  windowWidth: document.documentElement.offsetWidth,
                  windowHeight: document.documentElement.offsetHeight,
                },
              });
            }}
          >
            <SecondaryBtn title={"Download"} />
          </span>
        </div>
      </div>

      <div className={Design.exportPdfComponent}>
        <div className={Design.exportPdfComponentContainer}>
          <div id="my-node" className={Design.sharedComponent} ref={componentRef}>
            <SharedComponent user={userData} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(ExportPdfComponent);
