import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Empty from "empty/Empty";
import Admin from "./Admin";

export default function AdminTable(props) {
  const { admins, tabIndex } = props;
  let sortedArray = [];
  let emptyPageTitle;
  let emptyPageMsg;

  const columns = [
    { id: "name", label: "Name", minWidth: 100 },
    { id: "email", label: "Email", minWidth: 100 },
    { id: "role", label: "Role", minWidth: 100 },
    { id: "action", label: "", minWidth: 200 },
  ];

  if (tabIndex === 0) {
    sortedArray = admins;
    emptyPageTitle = "No admins found";
    emptyPageMsg = "You haven't added any admins to manage the system.";
  } else if (tabIndex === 1) {
    sortedArray = admins.filter((admin) => admin.type === "ADMIN");
    emptyPageTitle = "No admins found";
    emptyPageMsg = "You haven't added any admins to manage the system.";
  } else if (tabIndex === 2) {
    sortedArray = admins.filter((admin) => admin.type === "SUPER_ADMIN");
    emptyPageTitle = "No super admins found";
    emptyPageMsg = "You haven't added any admins as super admin to manage the system.";
  }

  const admin = sortedArray.map((admin) => <Admin key={admin.id} admin={admin} />);

  return (
    <div>
      {sortedArray.length === 0 ? (
        <Empty title={emptyPageTitle} msg={emptyPageMsg} />
      ) : (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, backgroundColor: "#F4F6F8", border: "none" }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{admin}</TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
