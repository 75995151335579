import React from "react";

export default function RequetsIcon(props) {
  const { color } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.84993 10.8333C4.00826 10.8333 2.5166 12.3249 2.5166 14.1666C2.5166 16.0083 4.00826 17.4999 5.84993 17.4999C7.6916 17.4999 9.18327 16.0083 9.18327 14.1666C9.18327 12.3249 7.6916 10.8333 5.84993 10.8333ZM10.8333 11.6666V16.6666C10.8333 17.1249 11.2083 17.4999 11.6666 17.4999H16.6666C17.1249 17.4999 17.4999 17.1249 17.4999 16.6666V11.6666C17.4999 11.2083 17.1249 10.8333 16.6666 10.8333H11.6666C11.2083 10.8333 10.8333 11.2083 10.8333 11.6666ZM5.10827 2.97492L2.35826 7.92492C2.04993 8.48325 2.44993 9.16659 3.08327 9.16659H8.58326C9.2166 9.16659 9.6166 8.48325 9.30827 7.92492L6.55826 2.97492C6.2416 2.40825 5.42493 2.40825 5.10827 2.97492ZM16.0416 2.08325C15.1583 2.08325 14.5333 2.54992 14.1666 3.05825C13.7999 2.54992 13.1749 2.08325 12.2916 2.08325C10.9916 2.08325 9.99993 3.14992 9.99993 4.37492C9.99993 5.89992 11.6916 7.01659 13.6249 8.69159C13.9333 8.95825 14.3916 8.95825 14.7083 8.69159C16.6416 7.01659 18.3333 5.89992 18.3333 4.37492C18.3333 3.14992 17.3416 2.08325 16.0416 2.08325Z"
        fill={color}
      />
    </svg>
  );
}
