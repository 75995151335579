import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgressBar from "./CircularProgressBar";
import Design from "./Subscription.module.css";
import PrimaryBtn from "components/PrimaryBtn";
import TransactionHistory from "./TransactionHistory";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import LoadingScreen from "../../components/LoadingScreen";

function Subscription(props) {
  const { userId } = useParams();
  let navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getSubscriptionData = () => {
    axios
      .get(`${BASE_URL}admin/users/${userId}`)
      .then((res) => {
        setSubscriptions(res.data.userData.subscriptions);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
          navigate(-1);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
          navigate(-1);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };

  useEffect(() => {
    getSubscriptionData();
  }, []);

  const [percentage, setPercentage] = useState(0);
  const [remainingDays, setRemainingDays] = useState(0);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);

  const calculateRemainingDatesForExpiry = () => {
    var startDate = moment(subscriptions[0].lastRenewedOn, "DD/MM/YYYY");
    var endDate = moment(subscriptions[0].expiringOn, "DD/MM/YYYY");
    var today = moment();
    setIsSubscriptionActive(subscriptions[0].isActive);

    const planDuration = endDate.diff(startDate, "days");
    const remainingDays = endDate.diff(today, "days") + 1;
    setRemainingDays(remainingDays);

    if (remainingDays >= 0) {
      const remainingDaysPercentage = (remainingDays / planDuration) * 100;
      setPercentage(remainingDaysPercentage);
    }
  };

  useEffect(() => {
    if (subscriptions && subscriptions.length !== 0) {
      calculateRemainingDatesForExpiry();
    }
  }, [subscriptions]);

  const activateSubscription = () => {
    if (userId) {
      axios
        .post(`${BASE_URL}admin/users/payment/subscription/record-manual-payment/${userId}`, null)
        .then((res) => {
          props.onPassToastMessage("Subscribed user successfully");
        })
        .catch((error) => {
          console.log(error);
          if (error.code === "ERR_NETWORK") {
            props.openSnackbar("Network error", "error", true);
          } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
            onHandleUnauthorizedError();
          } else {
            props.openSnackbar("Some error occures. Please try again later!", "error", true);
          }
        });
    }
  };

  return (
    <div>
      <div style={{ display: !isLoading ? "block" : "none" }}>
        <div className={Design.circularProgressBarContainer}>
          <CircularProgressBar percentage={percentage} remainingDays={remainingDays} />
          <div className={Design.subscriptionContent}>
            <h3 className={Design.subscriptionPlan}>Pro subscription</h3>
            {remainingDays === 0 ? (
              <h4 className={Design.subscriptionDays}>Expiring today</h4>
            ) : (
              <span>
                {remainingDays < 0 || !isSubscriptionActive ? (
                  <h4 className={Design.subscriptionDays}>No subscriptions</h4>
                ) : (
                  <h4 className={Design.subscriptionDays}>Expiring in {remainingDays} days</h4>
                )}
              </span>
            )}
            <p className={Design.subscriptionPrice}>₹ 500/ 6 months</p>
            {remainingDays < 0 || !isSubscriptionActive ? (
              <span onClick={activateSubscription}>
                <PrimaryBtn title={"Subscribe"} />
              </span>
            ) : null}
          </div>
        </div>
        <div>
          <TransactionHistory />
        </div>
      </div>
      <span style={{ display: isLoading ? "block" : "none" }}>
        <LoadingScreen />
      </span>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(Subscription);
