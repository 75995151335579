import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Design from "./Subscription.module.css";
import { PRIMARY_COLOR, PRIMARY_BACKGROUND_COLOR } from "../../utils/custom/colorPalette";

export default function CircularProgressBar(props) {
  let { percentage, remainingDays } = props;
  remainingDays = remainingDays + 1;
  var text;
  if(remainingDays === 0){
    text = "ACTIVE";
    percentage = 0;
  }
  else if (percentage === 0 || remainingDays < 0 || percentage === undefined) {
    text = "INACTIVE";
    percentage = 0;
  } else {
    text = "ACTIVE";
  }
  return (
    <div className={Design.circularProgressBar}>
      <CircularProgressbar
        value={percentage}
        text={text}
        // counterClockwise
        strokeWidth={12}
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: "#637381",
          textSize: "10px",
          pathColor: PRIMARY_COLOR,
          trailColor: PRIMARY_BACKGROUND_COLOR,
        })}
      />
    </div>
  );
}
