import Header from "components/Header";
import { useNavigate, Outlet} from "react-router-dom";
import arrowBack from "../images/arrowBack.svg";
import ScrollableTabs from "./ScrollableTabs";
import ImageModel from "carousel/ImageModel";
import Design from "./UserProfile.module.css";

function UserProfile() {
  let navigate = useNavigate();
  
  const goBacktoDashboard = () => {
    navigate(-1);
  };

  return (
    <div>
      <div style={{marginBottom: "50px"}}>
        <div className={Design.userProfileHeader}>
          <div className={Design.arrowBackBtnContainer}>
            <img className={Design.arrowBackBtn} src={arrowBack} alt="<<" onClick={goBacktoDashboard} />
          </div>
          <Header title={"User Profile"} />
        </div>
        <ScrollableTabs/>
        <div style={{ marginTop: "50px" }}><Outlet/></div>
      </div>
      <ImageModel />
    </div>
  );
}

export default UserProfile;
