import React from "react";
import Carousel from "react-material-ui-carousel";
import { connect } from "react-redux";
import GalleryCarouselImage from "./GalleryCarouselImage";

function GalleryCarousel(props) {
  const { imageUrl } = props;
  
  return (
    <Carousel
    indicators = {false}
    >
      {imageUrl.map((url, i) => (
        <GalleryCarouselImage key={i} url={url.url} />
      ))}

    </Carousel>
  );
}

const mapStateToProps = (state) => {
  return {
    imageUrl: state.imagePopupReducer.imageUrl,
  };
};

export default connect(mapStateToProps, null)(GalleryCarousel);
