import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { phoneNumberValidator } from "utils/functions/validators";
import UserHeading from "user/UserHeading";
import PrimaryBtn from "components/PrimaryBtn";
import SecondaryBtn from "components/SecondaryBtn";
import Design from "./Manage.module.css";
import { SHOW_TOAST } from "store/actions/toastAction";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";

function UpdatePhoneNumber(props) {
  const { userId } = useParams();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countrycode, setCountrycode] = useState("");

  const clearForm = () =>{
    setPhoneNumber("");
  }

  const onSubmit = () =>{
    let formIsvalid = true;
    if(!phoneNumber || phoneNumber === "" || !countrycode || countrycode === ""){
      formIsvalid = false;
      props.openSnackbar("Phone number cannot be empty", "warning", true);
    }else if (!phoneNumberValidator(countrycode,phoneNumber)){
      formIsvalid = false;
      props.openSnackbar("Invalid phonenumber", "error", true);
    }else{
      formIsvalid = true;
    }

    if(formIsvalid){
      updatePhonenumber();
    }
  }

  const updatePhonenumber = () =>{
    const data = {
      newPhoneNumber: phoneNumber,
      countryCode: `+${countrycode}`,
    };
    axios
      .patch(`${BASE_URL}admin/user/update/phonenumber/${userId}`, data)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            props.openSnackbar("Successfully edited user's phone number", "success", true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if(error.code === "ERR_NETWORK"){
          props.openSnackbar("Network error", "error", true);
        }else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }else if(error.response.data.message === "Phone Number already exist"){
          props.openSnackbar("Phone number already exist", "error", true);
        }else{
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
      });
  }

  return (
    <div className={Design.action}>
      <UserHeading title={"Change Phone Number"} />
      <div className="phoneInput">
        <PhoneInput
          inputStyle={{ border: "none" }}
          country={"in"}
          value={countrycode + phoneNumber}
          onChange={(value, data) => {
            setCountrycode(data.dialCode);
            setPhoneNumber(value.slice(data.dialCode.length));
          }}
        />
      </div>
      <div className={Design.btnGroup}>
        <span onClick={onSubmit}>
          <PrimaryBtn title={"Save"} />
        </span>
        <span onClick={clearForm}>
          <SecondaryBtn title={"Clear"} />
        </span>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({type: SHOW_TOAST, message, severity, open}),
  };
};

export default connect(null, mapDispatchToProps)(UpdatePhoneNumber);