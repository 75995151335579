import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Design from "./TransactionHistory.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import { smallDateFormatter } from "utils/functions/dateFormatter";

function TransactionHistory(props) {
  let navigate = useNavigate();
  const { userId } = useParams();
  const [isEmpty, setIsEmpty] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);


  const getTransactionHistory = () => {
    axios
      .get(`${BASE_URL}admin/transactions/${userId}`)
      .then((res) => {
        if (res.data.transactionHistory.length === 0) {
          setIsEmpty(true);
        }
        setTransactionHistory(res.data.transactionHistory);
      })
      .catch((error) => {
        console.log(error.code);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
          navigate(-1);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else if (error.code === "ERR_BAD_REQUEST") {
          setIsEmpty(true);
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
          navigate(-1);
        }
      });
  };

  useEffect(() => {
    getTransactionHistory();
  }, []);

  return (
    <div className={Design.transactionHistory} style={{ display: isEmpty ? "none" : "block" }}>
      <h4 className={Design.transactionHistoryHeading}>Transaction History</h4>
      <TableContainer className="desktopView">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Subscription Plan</TableCell>
              <TableCell>Purchased Date</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionHistory.map((transaction, index) => {
              const amount = transaction.amount ? transaction.amount : transaction?.subscriptionplan.price * 0.013;
              return (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{transaction?.subscriptionplan?.name}</TableCell>
                  <TableCell>{smallDateFormatter(transaction.purchaseDate)}</TableCell>
                  <TableCell>{transaction.originalTransactionId}</TableCell>
                  <TableCell>${amount}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="mobileView" style={{ marginTop: "10px" }}>
        {transactionHistory.map((transaction, index) => {

          const amount = transaction.amount ? transaction.amount : transaction?.subscriptionplan.price * 0.013;

          return (
            <div className={Design.transactionHistoryMobileView}>
              <div className={Design.transactionPlanSection}>
                <h3>{transaction?.subscriptionplan?.name}</h3>
                <span>{smallDateFormatter(transaction.purchaseDate)}</span>
              </div>
              <div>
                <p className={Design.transactionAmount}>Amount: $ {amount}</p>
                <p className={Design.transactionId}>{transaction.originalTransactionId}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(TransactionHistory);
