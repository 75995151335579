import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import EditRequest from "./EditRequest";
import Empty from "empty/Empty";

export default function EditRequestTable(props) {
  const { requets, tabIndex } = props;
  let emptyPageTitle;
  let emptyPageMsg;

  const columns = [
    { id: "requester", label: "Requester", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: tabIndex === 2 ? "Comments" : "Action / Comments", minWidth: 200 },
  ];

  if (tabIndex === 0) {
    emptyPageTitle = "All caught up";
    emptyPageMsg = "You haven't received any request yet.";
  } else if (tabIndex === 1) {
    emptyPageTitle = "No pending request found";
    emptyPageMsg = "There are no pending requests";
  } else if (tabIndex === 2) {
    emptyPageTitle = "No rejected request found";
    emptyPageMsg = "You haven't rejected any request yet.";
  }

  const requests = requets.map((request) => <EditRequest key={request.id} request={request} />);

  return (
    <div>
      {requets.length === 0 ? (
        <Empty title={emptyPageTitle} msg={emptyPageMsg} />
      ) : (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, backgroundColor: "#F4F6F8", border: "none" }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{requests}</TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
