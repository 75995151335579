import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";
import { connect } from "react-redux";
import UsersMobileView from "dashboard/mobileView/UsersMobileView";
import UnSubscribedUsersTableHeader from "../tableHeader/UnSubscribedUsersTableHeader";
import Users from "dashboard/Users";
import Empty from "empty/Empty";
import NetworkError from "empty/NetworkError";
import React, { useEffect, useState } from "react";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import BasicPagination from "../../components/BasicPagination";
import { onHandleUnauthorizedError } from "../../utils/functions/errorhandle";
import SearchAndFilterBar from "dashboard/searchbar/SearchAndFilterBar";
import LoadingScreen from "../../components/LoadingScreen";

function UnSubscribedUsersTable(props) {
  const { sortOrder, sortOrderBy } = props;
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('verifiedOn');
  const [searchFilter, setsearchFilter] = useState();
  const [usersData, setUsersData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [networkError, setNetworkError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let emptyPageTitle = "No unsubscribed users";
  let emptyPageMsg = "Looks like you dont't have any unsubscribed users yet.";

  const onHandleFilter = (url) => {
    if (url) {
      axios
        .get(`${url}`)
        .then((res) => {
          setUsersData(res.data.usersData);
          setTotalPages(res.data.totalPages);
          setTimeout(() => {
            setIsLoading(false);
          }, LOADING_TIME);
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {
            setNetworkError(true);
          } else if (error.response.status === 401) {
            onHandleUnauthorizedError();
          }
          setTimeout(() => {
            setIsLoading(false);
          }, LOADING_TIME);
        });
    }
  };

  useEffect(() => {
    setOrder(sortOrder);
    if(sortOrderBy || sortOrderBy !== null){
      setOrderBy(sortOrderBy);
    }
  }, [sortOrder, sortOrderBy])

  useEffect(() => {
    let url = `${BASE_URL}admin/users/unsubscribed?page=${page}&order=${order.toUpperCase()}&orderBy=${orderBy}`;
    const link = `${url}${searchFilter}`;
    if (searchFilter) {
      onHandleFilter(link);
    } else {
      onHandleFilter(url);
    }
  }, [page, searchFilter, order, orderBy]);

  const onSubmitSearchFilter = (search, minAge, maxAge, minHeight, maxHeight) => {
    var filters;
    if (search) {
       filters = `&search=${search}&minHeight=${minHeight}&maxHeight=${maxHeight}&minAge=${minAge}&maxAge=${maxAge}`;
    } else {
       filters = `&minHeight=${minHeight}&maxHeight=${maxHeight}&minAge=${minAge}&maxAge=${maxAge}`;
    }
    setsearchFilter(filters);
  };

  let row = usersData.map((user) => <Users key={user.id} user={user} />);
  let content = usersData.map((user) => <UsersMobileView key={user.id} user={user} />);

  return (
    <div>
      <SearchAndFilterBar
        onHandleFilter={(search, minAge, maxAge, minHeight, maxHeight) => onSubmitSearchFilter(search, minAge, maxAge, minHeight, maxHeight)}
      />
      <div style={{ display: totalPages !== 0 && !networkError && !isLoading ? "block" : "none" }}>
        <div className="desktopView">
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
             
             <UnSubscribedUsersTableHeader
             order={order}
             orderBy={orderBy}
             onRequestSort = {(order, orderBy)=>{
               setOrder(order);
               setOrderBy(orderBy);
             }}
              />

              <TableBody>{row}</TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="mobileView">{content}</div>
        <BasicPagination
          totalPages={totalPages}
          page={(pageNumber) => {
            setPage(pageNumber);
          }}
        />
      </div>

      <span style={{ display: totalPages === 0 && !networkError && !isLoading ? "block" : "none" }}>
        <Empty title={emptyPageTitle} msg={emptyPageMsg} />
      </span>

      <span style={{ display: networkError && !isLoading ? "block" : "none" }}>
        <NetworkError/>
      </span>

      <span style={{ display: isLoading ? "block" : "none" }}>
        <LoadingScreen/>
      </span>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    sortOrder: state.sortOrderReducer.sortOrder,
    sortOrderBy: state.sortOrderReducer.sortOrderBy,
  };
};

export default connect(mapStateToProps, null)(UnSubscribedUsersTable);