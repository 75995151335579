import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { SHOW_POPUP } from "store/actions/popUpActions";
import deleteIcon from "../images/deleteIcon.svg";
import editIcon from "../images/editIcon.svg";
import Design from "./IconBox.module.css";

function IconBox(props) {
  const { id } = props;

  const onRequestDeleteAdmin = () =>{
    props.onOpenPopup(true, false, id, "delete");
  }

  return (
    <div className={Design.iconBox}>
      <div className={Design.icons}>
        <NavLink to={`/admins/profile/edit/${id}`}>
          <div className={Design.editIcon}>
            <img src={editIcon} alt="editIcon" />
          </div>
        </NavLink>
        <div className={Design.deleteIcon} onClick={onRequestDeleteAdmin}>
          <img src={deleteIcon} alt="deleteIcon" />
        </div>
      </div>
    </div>
  );
}


const mapDispatchToProps = (dispatch) => {
  return {
    onOpenPopup: (showPopup, confirmAction, userId, action) => dispatch({ type: SHOW_POPUP, showPopup, confirmAction, userId, action}),
  };
};

export default connect(null, mapDispatchToProps)(IconBox);