import React from 'react';
import Chip from '@mui/material/Chip';
import { connect } from "react-redux";
import { UPDATE_USERDATA } from "store/actions/selectUserActions";

function SelectedUser(props) {
  const { user } = props;

  const removeSelectedUser = () => {
    props.deselectUser(user);
  };

    const style = {
        borderRadius: "10px",
        color: "#212B36",
        border: "1px solid #212B36",
        fontSize: "11px",
      };
    
  return (
    <Chip label={user.referenceNumber} sx={style} variant="outlined" onDelete={removeSelectedUser} />
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    deselectUser: (selectedUser) => dispatch({ type: UPDATE_USERDATA, selectedUser }),
  };
};

export default connect(null, mapDispatchToProps)(SelectedUser);