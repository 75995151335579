import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import Header from "components/Header";
import ActionLog from "./ActionLog";
import Empty from "empty/Empty";
import NetworkError from "../empty/NetworkError";
import Design from "./ActionLogs.module.css";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import BasicPagination from "../components/BasicPagination";
import LoadingScreen from "../components/LoadingScreen";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";

export default function Notifications() {
  const [logs, setLogs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [networkError, setNetworkError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  let emptyPageTitle = "No notification received";
  let emptyPageMsg = "Notification will show up when some action happen in the application.";

  const onHandleSubmit = () => {
    axios
      .get(`${BASE_URL}admin/activitylog?page=${page}`)
      .then((res) => {
        setLogs(res.data.logs);
        setTotalPages(res.data.totalPages);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        error.toJSON();
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setNetworkError(true);
        }
        if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }
      });
  };

  const actionlogs = logs.map((log) => <ActionLog key={log.id} actionlog={log} />);

  useEffect(() => {
    onHandleSubmit();
  }, [page]);

  return (
    <div>
      <Header title={"Notifications"} />
      {totalPages === 0 && !isLoading ? <span>{networkError  ? <NetworkError /> : <Empty title={emptyPageTitle} msg={emptyPageMsg} />}</span> : null}
      <div className="content" style={{ display: totalPages !== 0 && !isLoading && !networkError ? "block" : "none" }}>
        <div className={Design.container}>
          <div>{actionlogs}</div>
          <BasicPagination
            totalPages={totalPages}
            page={(pageNumber) => {
              setPage(pageNumber);
            }}
          />
        </div>
      </div>
      { isLoading ? <LoadingScreen /> : null }
    </div>
  );
}
