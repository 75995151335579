import React from "react";
import emptyImage from "images/emptyImage.svg";
import Design from "./Empty.module.css";

export default function Empty(props) {
  const { title, msg } = props;

  return (
    <div className={Design.emptyPage}>
      <div className={Design.emptyPageContents}>
        <img className={Design.emptyPageImage} src={emptyImage} alt="emptyImage" />
        <h2 className={Design.emptyPageHeading}>{title}</h2>
        <p className={Design.emptyPagePara}>{msg}</p>
      </div>
    </div>
  );
}
