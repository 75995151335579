import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import BasicPagination from "components/BasicPagination";
import ScrollableTabsButtonAuto from "components/ScrollableTabsButtonAuto";
import NetworkError from "empty/NetworkError";
import Empty from "empty/Empty";
import clear from "images/clear.svg";
import filterIcon from "images/filter.svg";
import searchIcon from "images/search.svg";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SHOW_TOAST } from "store/actions/toastAction";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import Header from "../../components/Header";
import PrimaryBtn from "../../components/PrimaryBtn";
import { onHandleUnauthorizedError } from "../../utils/functions/errorhandle";
import Design from "./SuccessStories.module.css";
import SuccessStoriesTable from "./SuccessStoriesTable";
import SuccessStoryMobileViews from "./SuccessStoryMobileViews";
import LoadingScreen from "../../components/LoadingScreen";

function SuccessStories(props) {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [successStories, setSuccessStories] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const [user, setUser] = useState("Admin");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 40;

  let emptyPageTitle = "No active users";
  let emptyPageMsg = "Looks like you dont't have any active users yet.";

  const sort = ["Pending", "Approved", "Rejected"];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAddSuccessStoryPage = () => {
    navigate("/add/success-stories");
  };

  const getSuccessStories = () => {
    const status = sort[tabIndex].toUpperCase();
    axios
      .get(`${BASE_URL}admin/success-stories?page=${page}&postedBy=${user.toUpperCase()}&status=${status}`)
      .then((res) => {
        setTotalPages(res.data.totalPages);
        setSuccessStories(res.data.successStories);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          setNetworkError(true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };

  useEffect(() => {
    getSuccessStories();
  }, [tabIndex, user, page]);

  const clearSearch = () => {
    setSearch("");
  };

  const onHandleSubmit = () => {
    console.log("onHandleSubmit");
  };

  return (
    <div className={Design.SuccessStoriesSection}>
      <div className={Design.headerSection}>
        <Header title={"Success Stories"} />
        {!networkError ? (
          <span onClick={openAddSuccessStoryPage}>
            <PrimaryBtn title={"+ Add Stories"} />
          </span>
        ) : null}
      </div>

     <div style={{display: networkError && !isLoading ? "block" : "none"}}> <NetworkError /> </div>


        <div className="content">
          <ScrollableTabsButtonAuto sort={sort} tab={(index) =>{ 
               setIsLoading(true);
                setTabIndex(index);
                setTimeout(() => {
                  setIsLoading(false);
                }, LOADING_TIME);
              }}
          />

          <div className={Design.searchBar}>
            <div className={Design.searchbarContainer}>
              <input
                type="text"
                placeholder="Search"
                value={search}
                style={{ border: "none" }}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                autoFocus
              />
              {search ? <img src={clear} alt="clear" width={20} onClick={clearSearch} /> : null}
            </div>
            <div className={Design.filterbar}>
              <Select style={{ width: "100%" }} value={user} onChange={(e) => setUser(e.target.value)}>
                <MenuItem value={"Admin"}>Admin</MenuItem>
                <MenuItem value={"User"}>User</MenuItem>
              </Select>
            </div>

            <div className={Design.smallFilterbar}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <img src={filterIcon} width={30} alt="filter" />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "10ch",
                  },
                }}
              >
                <MenuItem
                  sx={{ fontSize: "14px" }}
                  onClick={() => {
                    setUser("Admin");
                    setAnchorEl(null);
                  }}
                >
                  Admin
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "14px" }}
                  onClick={() => {
                    setUser("User");
                    setAnchorEl(null);
                  }}
                >
                  User
                </MenuItem>
              </Menu>
            </div>

            <span className={Design.searchBtn} onClick={onHandleSubmit}>
              <PrimaryBtn title={"Search"} />
            </span>
            <button className={Design.smallSearchBtn} onClick={onHandleSubmit}>
              <img src={searchIcon} width={20} alt="search" />
            </button>
          </div>

          <div style={{ display: totalPages !== 0 && !isLoading ? "block" : "none" }}>
            <div className="desktopView">
              <SuccessStoriesTable successStories={successStories} />
            </div>

            <div className="mobileView">
              <SuccessStoryMobileViews successStories={successStories} />
            </div>
            <BasicPagination
              totalPages={totalPages}
              page={(pageNumber) => {
                setPage(pageNumber);
              }}
            />
          </div>
          <span style={{ display: totalPages === 0 && !isLoading ? "block" : "none" }}>
            <Empty title={emptyPageTitle} msg={emptyPageMsg} />
          </span>
            {isLoading ? <LoadingScreen /> : null}
        </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(SuccessStories);
