
export const phoneNumberValidator = (countrycode,phone) => {
  let isvalid = true;
  const number = countrycode + phone;
  if (!number.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
    isvalid = false;
  }
  return isvalid;
};

export const emailValidator = (email) => {
  let formIsvalid = true;
  if (typeof email) {
    let lastAtPos = email.lastIndexOf("@");
    let lastDotPos = email.lastIndexOf(".");
    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf("@@") === -1 && lastDotPos > 2 && email.length - lastDotPos > 2)) {
      formIsvalid = false;
    }
  } else {
    formIsvalid = true;
  }
  return formIsvalid;
}