import * as actionTypes from "store/actions/authActions";
import { LOCAL_STORAGE_AUTH_TOKEN, LOCAL_STORAGE_NAME, LOCAL_STORAGE_PROFILE_IMAGE_URL, LOCAL_STORAGE_TYPE , LOCAL_STORAGE_ID} from "utils/constants/localStorageKeys";

const initialState = {
  authToken: localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN) ?? null,
  name: localStorage.getItem(LOCAL_STORAGE_NAME) ?? null,
  profileImageUrl: localStorage.getItem(LOCAL_STORAGE_PROFILE_IMAGE_URL) ?? null,
  admin: localStorage.getItem(LOCAL_STORAGE_TYPE) ?? null,
  id: localStorage.getItem(LOCAL_STORAGE_ID) ?? null, 
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_AUTH_SUCCESS:
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, `Bearer ${action.authToken}`);
      localStorage.setItem(LOCAL_STORAGE_NAME, action.name);
      localStorage.setItem(LOCAL_STORAGE_PROFILE_IMAGE_URL, action.profileImageUrl);
      localStorage.setItem(LOCAL_STORAGE_TYPE, action.admin);
      localStorage.setItem(LOCAL_STORAGE_ID, action.id);

      return {
        authToken: `Bearer ${action.authToken}`,
        name: action.name,
        profileImageUrl: action.profileImageUrl,
        admin: action.admin,
        id: action.id,
      };
    case actionTypes.ACTION_NO_EXISTING_ACCOUNT:
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, action.tempAuthToken);
      return { ...state, authToken: action.tempAuthToken };
    default:
      return { ...state };
  }
};
export default authReducer;
