import React from "react";
import { useState } from "react";
import LongMenu from "../LongMenu";
import Person from "components/Person";
import AdminBadge from "components/Badge";
import expandLess from "images/expandLess.svg";
import expandMore from "images/expandMore.svg";
import Design from "./MobileView.module.css";

export default function DeactivatedUsersMobileView(props) {
  const { user } = props;
  let options = []; 
  const [isOpen, setIsOpen] = useState(false);
  let feedback = user.feedback;
  let reason = feedback ? user.feedback.reason : "";
  let deactivatedBy = feedback ? user.feedback.deactivatedBy : "";

  if (deactivatedBy === "admin"){
    deactivatedBy = "ADMIN";
  }else if (deactivatedBy === "user"){
    deactivatedBy = "USER";
  }else{
    deactivatedBy = "SUPER_ADMIN";
  }

  if(user.isVerified){
    options = ["Reactivate", "Delete"];
  }else if(!user.isVerified){
    options = ["Reactivate", "Reject", "Approve"];
  }

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className={Design.container}>
      <Person id={user.id} name={user.name} referenceNumber={user.referenceNumber} profileImageUrl={user.profileImageUrl} />
      <LongMenu options={options} user={user} />
        <div className={Design.expandIcons}>
          {isOpen ? <img src={expandLess} alt="expandLess" onClick={toggleAccordion} /> :  <img src={expandMore} alt="expandMore" onClick={toggleAccordion} /> }
        </div>
      </div>
      {/* accordion */}
      {isOpen ? (
        <div className={Design.accordion}>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Deactivated by</p>
            <AdminBadge type={deactivatedBy} />
          </div>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Reason</p>
            <p className={Design.accordionValue}>{reason}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}
