import React from 'react'
import { PRIMARY_COLOR } from 'utils/custom/colorPalette';

export default function SecondaryBtn(props) {
    const { title } = props;
    const style = {
      background: "#ffffff",
      border: "1px solid",
      borderColor: PRIMARY_COLOR,
      outline: "none",
      borderRadius: "8px",
      color: PRIMARY_COLOR,
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "14px",
      height: "40px",
      width: "fit-content",
      padding: "0 20px",
    };
    return <button style={style}> {title} </button>;
}
