import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "components/Header";
import PrimaryBtn from "components/PrimaryBtn";
import ReportsSectionRow from "./ReportsSectionRow";
import NetworkError from "../empty/NetworkError";
import NoDataSection from "./NoDataSection";
import { DatePicker } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx/xlsx.mjs";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";
import LoadingScreen from "../components/LoadingScreen";
import Design from "./ReportsSection.module.css";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";

const { RangePicker } = DatePicker;

const columns = [
  { id: "referenceNumber", label: "Reference Number", minWidth: 200 },
  { id: "name", label: "Name", minWidth: 200 },
  { id: "VerifedOn", label: "Verified On", minWidth: 200 },
];

export default function ReportsSection() {
  const [networkError, setNetworkError] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getVerifiedUserData = () => {
    setIsLoading(true);
    axios
      .get(`${BASE_URL}admin/users/reports/verified?startDate=${startDate}&endDate=${endDate}`)
      .then((res) => {
        setUsersData(res.data.usersData);
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setNetworkError(true);
        } else if (error.response.status === 401) {
          onHandleUnauthorizedError();
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };

  useEffect(() => {
    if (startDate && endDate) {
      getVerifiedUserData();
    }
  }, [startDate, endDate]);

  const user = usersData.map((user) => <ReportsSectionRow key={user.id} user={user} />);

  const exportExcelData = () => {
    var wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(usersData);
    XLSX.utils.book_append_sheet(wb, ws, "excelSheet");
    XLSX.writeFile(wb, `User data from ${startDate} to ${endDate}.xlsx`);
  };

  return (
    <div className={Design.reportsSection}>
      <Header title={"Reports"} />
      <div className={Design.headerSection} style={{ display: networkError ? "none" : "flex" }}>
        <span className={Design.rangePicker}>
          <RangePicker
            format="DD-MM-YYYY"
            onChange={(value, dateString) => {
              setStartDate(dateString[0]);
              setEndDate(dateString[1]);
            }}
          />
        </span>
        <span onClick={exportExcelData}>
          <PrimaryBtn title={"Export Excel"} />
        </span>
        {/* <div className={Design.exportCsvBtn}>
        <CSVLink className={Design.exportCsvBtnText} filename="csv sheets" data={usersData}>Export Data as CSV</CSVLink>
        </div> */}
      </div>

      <div style={{ display: isLoading ? "none" : "block" }}>
        {networkError ? <NetworkError /> : null}

        <span style={{ display: !networkError && usersData.length === 0 ? "block" : "none" }}>
          <NoDataSection />
        </span>

        <Paper className={Design.TableContainer} style={{ display: networkError || usersData.length === 0 ? "none" : "block" }}>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, backgroundColor: "#F4F6F8", border: "none" }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{user}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      {isLoading ? <LoadingScreen /> : null}
    </div>
  );
}
