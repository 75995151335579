import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import Header from "components/Header";
import PrimaryBtn from "../components/PrimaryBtn";
import SecondaryBtn from "../components/SecondaryBtn";
import dot from "../images/dot.svg";
import Design from "./FeedbackForms.module.css";
import { SHOW_TOAST } from "store/actions/toastAction";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";

function AddFeedbackForm(props) {
  let navigate = useNavigate();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [formLink, setFormLink] = useState();

  const handleValidation = () => {
    let formIsvalid = true;
    if (!title || title === "" || title === null) {
      formIsvalid = false;
      props.openSnackbar("Title cannot be empty", "warning", true);
    } else if (!description || description === "" || description === null) {
      formIsvalid = false;
      props.openSnackbar("Description cannot be empty", "warning", true);
    } else if (!formLink || formLink === "" || formLink === null) {
      formIsvalid = false;
      props.openSnackbar("Feedback form link is manadatory", "error", true);
    }else{
      formIsvalid = true;
    }
    return formIsvalid;
  }

  const onSubmitFeedbackForm = () => {

    if (handleValidation()) {

      const feedbackFormData = {
        title: title,
        description: description,
        link: formLink
      };

      axios
      .post(`${BASE_URL}admin/feedback-forms`, feedbackFormData)
      .then((res) => {
        if (res) {
          props.openSnackbar("Feedback form successfully sended ", "success", true);
        }
        navigate("/feedbacks");
      })
      .catch((error) => {
        console.log(error)
        error.toJSON();
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else if (error.response.data.message === "Validation error") {
          props.openSnackbar("Phone number or email id already exists", "error", true);
        }
      });

    }
  };

  return (
    <div>
      <Header title={"Create Feedback Form"} />

      <div className={Design.breadCrumbs}>
        <NavLink to="/feedbacks" className={Design.activeLink}>
          Feedback Form
        </NavLink>
        <img className={Design.dot} src={dot} alt="dot" />
        <p className={Design.inActiveLink}>Create Feedback Form</p>
      </div>

      <div className={Design.feedbackFormContainer}>
        <div className="textInput">
          <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} autoFocus />
        </div>
        <textarea
          className={Design.description}
          value={description}
          name="reasonDescription"
          placeholder="Description"
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <div className="textInput">
          <input type="text" placeholder="Enter your feedback form link here..." value={formLink} onChange={(e) => setFormLink(e.target.value)} autoFocus />
        </div>
        <div className={Design.btnsContainer}>
          <span onClick={onSubmitFeedbackForm}>
            <PrimaryBtn title={"Submit"} />
          </span>
          <span>
            <SecondaryBtn title={"Cancel"} />
          </span>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(AddFeedbackForm);