import React from "react";
import PersonalDetails from "./PersonalDetails";
import Design from "./Profile.module.css";

export default function Profile(props) {
  const { user } = props;
  return (
    <div className={Design.profileDetailsSection}>
      <div className={Design.profileDetailsContainer}>
        <PersonalDetails keys={"Gender"} value={user.gender} />
        <PersonalDetails keys={"DOB"} value={user.dateOfBirth} />
        <PersonalDetails keys={"Age"} value={user.age} />
        <PersonalDetails keys={"Height"} value={user.height + " cm"} />
        <PersonalDetails keys={"Weight"} value={user.weight + " kg"} />
        <PersonalDetails keys={"Hometown"} value={user.hometown} />
        <PersonalDetails keys={"Religion"} value={user.religion} />
        <PersonalDetails keys={"Caste"} value={user.caste} />
        <PersonalDetails keys={"Marital Status"} value={user.maritalStatus} />
        <PersonalDetails keys={"Complexion"} value={user.complexion} />
        <PersonalDetails keys={"Physical Status"} value={user.physicalStatus} />
        <PersonalDetails keys={"BodyType"} value={user.bodyType} />

        <PersonalDetails keys={"Current Residence"} value={user.currentResidence} />
        <PersonalDetails keys={"Education"} value={user.education} />
        <PersonalDetails keys={"Occupation"} value={user.occupation} />
        <PersonalDetails keys={"Church"} value={user.church} />
        <PersonalDetails keys={"Mother Tongue"} value={user.motherTongue} />
        <PersonalDetails keys={"Family Status"} value={user.familyStatus} />
        <PersonalDetails keys={"Ornaments"} value={user.ornaments} />
        {user.preferences ? (
          <PersonalDetails keys={"Preferences"} value={user.preferences} />
        ) : (
          <PersonalDetails keys={"Preferences"} value={"No Preferences"} />
        )}
      </div>
      <div className={Design.profileFrame}></div>
    </div>
  );
}
