import React from "react";

export default function UpdateProfileIcon(props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" height="22px" viewBox="0 0 20 20" width="22px" fill={color}>
      <rect fill="none" height="22" width="22" />
      <g>
        <path d="M11,6.75C11,6.34,11.34,6,11.75,6h5.5C17.66,6,18,6.34,18,6.75c0,0.41-0.34,0.75-0.75,0.75h-5.5C11.34,7.5,11,7.16,11,6.75 z M18,13.25c0-0.41-0.34-0.75-0.75-0.75h-5.5c-0.41,0-0.75,0.34-0.75,0.75S11.34,14,11.75,14h5.5C17.66,14,18,13.66,18,13.25z M8.89,3.87c-0.29-0.29-0.77-0.29-1.06,0L4.83,6.88L3.59,5.64c-0.29-0.29-0.77-0.29-1.06,0c-0.29,0.29-0.29,0.77,0,1.06l1.59,1.59 c0.39,0.39,1.02,0.39,1.41,0l3.36-3.36C9.19,4.64,9.19,4.17,8.89,3.87z M8.89,10.37c-0.29-0.29-0.77-0.29-1.06,0l-3.01,3.01 l-1.24-1.24c-0.29-0.29-0.77-0.29-1.06,0c-0.29,0.29-0.29,0.77,0,1.06l1.59,1.59c0.39,0.39,1.02,0.39,1.41,0l3.36-3.36 C9.19,11.14,9.19,10.67,8.89,10.37z" />
      </g>
    </svg>
  );
}
