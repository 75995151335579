import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";


export default function ReportsSectionRow(props) {
    const { user } = props;
  return (
    <TableRow>
      <TableCell>{user.referenceNumber}</TableCell>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.verifiedOn}</TableCell>
    </TableRow>
  );
}
