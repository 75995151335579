import React, { useEffect, useState } from "react";
import Header from "components/Header";
import SelectedUsers from "selectedUsers/SelectedUsers";
import NetworkError from "empty/NetworkError";
import AllFilterIcon from "./AllFilterIcon";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Design from "./Users.module.css";
import DashboardScrollableTabs from "./DashboardScrollableTabs";

function Dashboard() {
  const [sortMenu, setSortMenu] = useState([]);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    let menu;
    
    if (path.includes("/active")) {
      menu = [
        { label: "Reference Id", value: "id" },
        { label: "Verified Date", value: "verifiedOn" },
      ];
      setSortMenu(menu);
    } else if (path.includes("/Pending")) {
      menu = [
        { label: "Reference Id", value: "id" },
        { label: "Registered Date", value: "createdAt" },
      ];
      setSortMenu(menu);
    } else if (path.includes("/unsubscribe")) {
      menu = [
        { label: "Reference Id", value: "id" },
        { label: "Verified Date", value: "verifiedOn" },
      ];
      setSortMenu(menu);
    } else if (path.includes("/subscribe")) {
      menu = [
        { label: "Reference Id", value: "id" },
        { label: "Expired Date", value: "expiringOn" },
      ];
      setSortMenu(menu);
    } else if (path.includes("/reported")) {
      menu = [
        { label: "Reported User Id", value: "reportedUserId" },
        { label: "Reported By User Id", value: "reportedByUserId" },
        { label: "Reported Date", value: "createdAt" },
      ];
      setSortMenu(menu);
    } else if (path.includes("/deactive")) {
      menu = [
        { label: "Deactivated User id", value: "userId" },
        { label: "Deactivation Date", value: "createdAt" },
      ];
      setSortMenu(menu);
    }
  }, [path]);

  return (
    <div>
      <div className={Design.dashboardHeadingContainer}>
        <Header title={"Users"} />
          <div className="mobileView">
            {sortMenu.length === 0 ? null : <AllFilterIcon options={sortMenu} />}
          </div>
      </div>
      <div className="content">
        <DashboardScrollableTabs />
        <div style={{ marginBottom: "100px" }}>
          <span>
            <Outlet />
          </span>
        </div>
      </div>
      <SelectedUsers />
    </div>
  );
}

export default Dashboard;
