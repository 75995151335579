import React from "react";
import { connect } from "react-redux";
import { CLOSE_POPUP, CONFIRM_ACTION } from "store/actions/popUpActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { PRIMARY_COLOR } from "utils/custom/colorPalette";

function Popup(props) {
  var title, content;
  const handleClose = () => {
    props.onClosePopup(false, false);
  };

  const confirmAction = () => {
    props.onConfirmAction(false, true);
  }

  if (props.action === "logout") {
    title = "Logout?";
    content = "Are you sure, you want to logout";
  }else if (props.action === "delete") {
    title = "Are you Sure?";
    content = "Deleting user will permanently delete all the data related to that user. Continue?";
  }else if (props.action === "remove") {
    title = "Confirm delete";
    content = "This record will be deleted permanently";
  }

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{color : PRIMARY_COLOR, boxShadow: "none"}} onClick={confirmAction}>YES</Button>
          <Button sx={{color : PRIMARY_COLOR, boxShadow: "none"}} onClick={handleClose} autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClosePopup: (showPopup, confirmAction) => dispatch({ type: CLOSE_POPUP, showPopup, confirmAction}),
    onConfirmAction: (showPopup, confirmAction) => dispatch({ type: CONFIRM_ACTION, showPopup, confirmAction}),
  };
};

const mapStateToProps = (state) => {
  return {
    action: state.popupReducer.action,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
