export const dateFormatter = (date) => {
    let today = new Date(date);
    let dd = String(today.getDate()).padStart(2, "0");
    let yyyy = today.getFullYear();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = monthNames[today.getMonth()];
    const formattedDate = `${dd} ${month} ${yyyy}`;
    return formattedDate;
  };

  export const smallDateFormatter = (date) => {
    let today = new Date(date);
    let dd = String(today.getDate()).padStart(2, "0");
    let yyyy = today.getFullYear();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month = monthNames[today.getMonth()];
    const formattedDate = `${dd} ${month} ${yyyy}`;
    return formattedDate;
  };