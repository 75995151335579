import React from "react";
import { connect } from "react-redux";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Design from "./ProfileUpdate.module.css";
import RoundedAvatar from "../RoundedAvatar";
import { OPEN_IMAGE_POPUP } from "store/actions/imagePopupActions";

function GalleryImageUpdateTableRow(props) {
  const { newValue, oldValue } = props;
  const style = {
    padding: "12px",
    borderBottom: "1px solid #0000000f",
  }
  const newGalleryImages = newValue ? newValue.map((link) => <span onClick={()=>props.openImagePopup(true, newValue)} ><RoundedAvatar key={link.id} url={link.url} /></span>) : null;
  const oldGalleryImages = oldValue ? oldValue.map((link) => <span onClick={()=>props.openImagePopup(true, oldValue)} ><RoundedAvatar key={link.id} url={link.url} /></span>) : null;

  return (
    <TableRow key={"id"}>
      <TableCell sx={style} >
        <p className={Design.keyValue}>Gallery Images</p>
      </TableCell>
      <TableCell sx={style} >
        <Stack direction="row" spacing={2}>
          {newGalleryImages}
        </Stack>
      </TableCell>
      <TableCell sx={style} >
        <Stack direction="row" spacing={2}>
          {oldGalleryImages}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openImagePopup: (showImagePopup, imageUrl) => dispatch({type: OPEN_IMAGE_POPUP, showImagePopup, imageUrl })
  };
};

export default connect(null, mapDispatchToProps)(GalleryImageUpdateTableRow);