import React from "react";
import { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PrimaryBtn from "components/PrimaryBtn";
import SecondaryBtn from "components/SecondaryBtn";
import AdminRadioBtn from "./AdminRadioBtn";
import UpdateImage from "components/UpdateImage";
import Design from "./AddAdminForm.module.css";
import { SHOW_TOAST } from "store/actions/toastAction";
import { phoneNumberValidator, emailValidator } from "utils/functions/validators";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";

function AddAdminForm(props) {
  const { onSubmitForm, closeAdminForm } = props;
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [type, setType] = useState();
  const [profileImage, setProfileImage] = useState(null);

  const getUploadedImage = (value) => {
    setProfileImage(value);
  };

  const handleValidation = () => {
    let formIsvalid = true;
    if (!name || name === "" || name === null) {
      formIsvalid = false;
      props.openSnackbar("Admin name cannot be empty", "warning", true);
    } else if (!phoneNumber || phoneNumber === "" || !countrycode || countrycode === "") {
      formIsvalid = false;
      props.openSnackbar("Phone number cannot be empty", "warning", true);
    } else if (!phoneNumberValidator(countrycode, phoneNumber)) {
      formIsvalid = false;
      props.openSnackbar("Invalid phonenumber", "error", true);
    } else if (!email || email === "" || email === null) {
      formIsvalid = false;
      props.openSnackbar("Email cannot be empty", "warning", true);
    } else if (!emailValidator(email)) {
      formIsvalid = false;
      props.openSnackbar("Invalid email", "error", true);
    } else if (!type || type === undefined || type === null) {
      formIsvalid = false;
      props.openSnackbar("Select an admin type", "warning", true);
    } else if (profileImage === null) {
      formIsvalid = false;
      props.openSnackbar("Please choose a profile image", "warning", true);
    } else {
      formIsvalid = true;
    }
    return formIsvalid;
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      const data = new FormData();
      data.append("profileImage", profileImage);
      data.append("name", name);
      data.append("email", email);
      data.append("type", type);
      data.append("countryCode", countrycode);
      data.append("phoneNumber", phoneNumber);
      axios
        .post(`${BASE_URL}admin/register`, data)
        .then((res) => {
          if (res) {
            props.openSnackbar("Registration Completed", "success", true);
          }
          onSubmitForm();
          closeAdminForm();
        })
        .catch((error) => {
          error.toJSON();
          if (error.code === "ERR_NETWORK") {
            props.openSnackbar("Network error", "error", true);
          } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
            onHandleUnauthorizedError();
          } else if (error.response.data.message === "Validation error") {
            props.openSnackbar("Phone number or email id already exists", "error", true);
          }
        });
    }
  };

  return (
    <div className={Design.addAdminFormContainer}>
      <div className={Design.addAdminForm}>
        <div className={Design.form}>
          <h2 className={Design.heading}>Add Admin</h2>
          <UpdateImage onImageSelected={getUploadedImage} />
          <div className="textInput">
            <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} autoFocus />
          </div>
          <div className="phoneInput">
            <PhoneInput
              inputStyle={{ border: "none" }}
              country={"in"}
              value={countrycode + phoneNumber}
              onChange={(value, data) => {
                setCountrycode(data.dialCode);
                setPhoneNumber(value.slice(data.dialCode.length));
              }}
            />
          </div>
          <div className="textInput">
            <input type="email" placeholder="Email Id" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <AdminRadioBtn adminType={(value) => setType(value)} />

          <div className={Design.buttonGrp}>
            <span onClick={handleSubmit}>
              <PrimaryBtn title={"Save"} />
            </span>
            <span onClick={closeAdminForm}>
              <SecondaryBtn title={"Cancel"} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(AddAdminForm);
