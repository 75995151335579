import Person from "components/Person";
import expandLess from "images/expandLess.svg";
import expandMore from "images/expandMore.svg";
import { useState } from "react";
import Status from "../components/Status";
import RequestIconBox from "./RequestIconBox";
import Design from "./Requests.module.css";

export default function RequestMobileView(props) {
  const { request } = props;
  const [isOpen, setIsOpen] = useState(false);
  const requesterName = request.requesterDetails ? request.requesterDetails.name : null;
  const requestedName = request.requestedIdDetails ? request.requestedIdDetails.name : null;
  const requesterId = request.requesterDetails ? request.requesterDetails.referenceNumber : null;
  const requestedId = request.requestedIdDetails ? request.requestedIdDetails.referenceNumber : null;
  const requesterProfilePic = request.requesterDetails ? request.requesterDetails.profileImageUrl : null;
  const requestedProfilePic = request.requestedIdDetails ? request.requestedIdDetails.profileImageUrl : null;

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  let sign;
  if (request.status === "PENDING") {
    sign = "warning";
  } else if (request.status === "APPROVED") {
    sign = "success";
  } else if (request.status === "REJECTED") {
    sign = "error";
  }

  return (
    <div>
      <div className={Design.requests}>
        <Person id={request.requester} name={requesterName} referenceNumber={requesterId} profileImageUrl={requesterProfilePic} />
        <Status status={request.status} sign={sign} />
      </div>
        <div className={Design.accordion}>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Requested id</p>
            <Person id={request.requestedId} name={requestedName} referenceNumber={requestedId} profileImageUrl={requestedProfilePic} size={"25px"} />
          </div>
          {request.status === "PENDING" ? (
            <div className={Design.iconBoxContainer}>
              <RequestIconBox id={request.id} />
            </div>
           ) : null} 
        </div>
    </div>
  );
}
