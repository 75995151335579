import React from 'react'
import noData from "../images/noData.svg";
import Design from "./NoDataSection.module.css";

export default function NoDataSection() {
  return (
    <div className={Design.emptyDataSection}>
        <img width={"20%"} src={noData} />
        <h2 className={Design.heading} >No Data Found</h2>
        <p className={Design.content}>Please select a date range to generate report</p>
    </div>
  )
}
