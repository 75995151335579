import React, { useState, useEffect } from "react";
import axios from "axios";
import AutoSelectItem from "./AutoSelectItem";
import Image from "../../components/Image";
import expandLess from "images/expandLess.svg";
import expandMore from "images/expandMore.svg";
import clear from "images/clear.svg";
import Design from "./AutoSelect.module.css";
import { onHandleUnauthorizedError } from "../../utils/functions/errorhandle";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { BASE_URL } from "utils/constants/ApplicationConstants";

export default function AutoSelect(props) {
  const { getSelectedUserData, placeholder } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setsearchTerm] = useState();
  const [userData, setuserData] = useState([]);
  const [isSelectUser, setIsSelectUser] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState({});

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let url = `${BASE_URL}admin/users?page=1`;
    onHandleSearch(url);
  }, [])
  

  useEffect(() => {
    let url = `${BASE_URL}admin/users?search=${searchTerm}`;
    onHandleSearch(url);
  }, [searchTerm]);

  const onHandleSearch = (url) => {
    if (url) {
      axios
        .get(`${url}`)
        .then((res) => {
          setuserData(res.data.usersData);
        })
        .catch((error) => {
          if (error.code === "ERR_NETWORK") {
            // setNetworkError(true);
          } else if (error.response.status === 401) {
            onHandleUnauthorizedError();
          }
        });
    }
  };

  const empty = (
    <ListItem disablePadding>
      {" "}
      <ListItemText className={Design.empty} primary={"No users found"} />
    </ListItem>
  );

  const listItems = userData.map((user) => (
    <ListItem
      disablePadding
      onClick={() => {
        setIsSelectUser(true);
        getSelectedUserData(user);
        setSelectedUserData(user);
      }}
    >
      <AutoSelectItem user={user} />
    </ListItem>
  ));

  return (
    <div className={Design.autoSelectContainer}>
      <div className={Design.autoSelect} onClick={toggleAccordion}>
        {isSelectUser ? (
          <div className={Design.autoSelectedItem}>
            <div className={Design.autoSelectedItemContent}>
              <Image size={"26px"} url={selectedUserData.profileImageUrl} />
              <span>{selectedUserData.name}</span>
            </div>
            <img src={clear} width={20} alt="x" onClick={() => setIsSelectUser(false)} />
          </div>
        ) : (
          <div className={Design.autoSelectInput}>
            <input placeholder={placeholder} type="text" style={{ border: "none" }} onChange={(e) => setsearchTerm(e.target.value)} />
            {isOpen ? <img src={expandLess} alt="expandLess" onClick={toggleAccordion} /> : <img src={expandMore} alt="expandMore" onClick={toggleAccordion} />}
          </div>
        )}
      </div>
      {isOpen ? (
        <div className={Design.accordion}>
          <List>{userData.length === 0 ? empty : listItems}</List>
        </div>
      ) : null}
    </div>
  );
}
