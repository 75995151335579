
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import Profile from "./Profile";
import ProfileImageSection from "./ProfileImageSection";
import Design from "./SharedComponent.module.css";

export default function SharedComponent(props) {
  const { user } = props;
  return (
    <div className={Design.container}>
      <div className={Design.mainSection}>
        <HeaderComponent />

        <div className={Design.contentSection}>
          <Profile user={user} />
          <ProfileImageSection user={user} />
        </div>
        
      </div>
      <FooterComponent />
    </div>
  );
}
