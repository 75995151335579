import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URL, AUTOFILL_SEARCH_TIMER } from "utils/constants/ApplicationConstants";
import { onHandleUnauthorizedError } from "../utils/functions/errorhandle";
import Design from "./AnalyticalDashboard.module.css";

export default function AnalyticsDashboardCards(props) {
  const { network } = props;

  const [data, setData] = useState();

  const getAnalyticsCardData = () => {
    axios
      .get(`${BASE_URL}admin/analytics/card`)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
        error.toJSON();
        if (error.code === "ERR_NETWORK") {
          network(true);
        }
        if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        }
      });
  };

  useEffect(() => {
    getAnalyticsCardData();
  }, []);

  return (
    <div>
      <div className={Design.analyticsCardContainer}>
        <div className={Design.analyticsCard}>
          <p>Subscribed Profiles</p>
          <h2>{data?.totalSubscribedprofiles}</h2>
        </div>
        <div className={Design.analyticsCard}>
          <p>Total Active Profiles</p>
          <h2>{data?.totalActiveProfiles}</h2>
        </div>
        <div className={Design.analyticsCard}>
          <p>Total Pending Profiles</p>
          <h2>{data?.totalPendingProfiles}</h2>
        </div>
        <div className={Design.analyticsCard}>
          <p>Subscribed Profiles</p>
          <span>Last 30 days</span>
          <h2>{data?.Last30Subscribedprofile}</h2>
        </div>
        <div className={Design.analyticsCard}>
          <p>New Active Profiles</p>
          <span>Last 30 days</span>
          <h2>{data?.Last30ActivatedProfile}</h2>
        </div>
        <div className={Design.analyticsCard}>
          <p>Newly Registered Users</p>
          <span>Last 30 days</span>
          <h2>{data?.Last30Pendingprofile}</h2>
        </div>
      </div>
    </div>
  );
}
