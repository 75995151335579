import React from "react";
import { connect } from "react-redux";
import { dateFormatter } from "../utils/functions/dateFormatter";
import deleteIcon from "images/deleteIcon.svg";
import clock from "images/clock.svg";
import calenderIcon from "images/calenderIcon.svg";
import Design from "./FeedbackForms.module.css";
import { SHOW_POPUP } from "store/actions/popUpActions";

function FeedbackForm(props) {
  const { feedbackForms } = props;

  const onDeleteFeedbackForm=(formId)=>{
    props.onOpenPopup(true, false, formId, "delete");
  }

  return (
    <div className={Design.feedbackform}>
      <div className={Design.feedbackContents}>
        <a href={feedbackForms.link} className={Design.feedbackTexts}>
          <h2 className={Design.feedbackTitle}>{feedbackForms.title}</h2>
          <p className={Design.feedbackTime}>
            {" "}
            <img className={Design.feedbackClockIcon} width={15} src={calenderIcon} alt="clock" />{dateFormatter(feedbackForms.createdAt)}
          </p>
          <p className={Design.feedbackDesc}>{feedbackForms.description}</p>
        </a>
        <div className={Design.deleteIconsFlex} >
          <span className={Design.deleteIconContainer} onClick={() => onDeleteFeedbackForm(feedbackForms.id)}>
            <img width={"20px"} src={deleteIcon} alt="feedback" />
          </span>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenPopup: (showPopup, confirmAction, userId, action) => dispatch({ type: SHOW_POPUP, showPopup, confirmAction, userId, action }),
  };
};

export default connect(null, mapDispatchToProps)(FeedbackForm);