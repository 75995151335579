import React, { useState, useEffect } from "react";
import Header from "components/Header";
import FinanceDashboardCards from "./FinanceDashboardCards";
import PieChartContainer from "./PieChartContainer";
import LineChartContainer from "./LineChartContainer";
import TransactionHistoryTable from "./TransactionHistoryTable";
import NetworkError from "empty/NetworkError";
import Design from "./FinancesDashboard.module.css";
import LoadingScreen from "../components/LoadingScreen";
import { LOADING_TIME } from "utils/constants/ApplicationConstants";

export default function FinancesDashboard() {
  const [networkError, setNetworkError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, LOADING_TIME);
  }, [])
  

  return (
    <div>
      <div className={Design.headerSection}>
        <Header title={"Finances"} />
      </div>
      <div style={{ display: !networkError && !isLoading ? "block" : "none" }}>
        {/* Charts */}
        <div className={Design.charts}>
          <div className={Design.lineChartContainer}>
            <LineChartContainer />
          </div>
          <div className={Design.pieChartContainer}>
            <PieChartContainer />
          </div>
        </div>

        {/* Transaction cards */}

        <FinanceDashboardCards network={(value) => setNetworkError(value)} />

        {/* Transaction table */}
        <div style={{ position: "relative", marginTop: "50px" }}>
          <TransactionHistoryTable />
        </div>
      </div>

      <span style={{ display: networkError && !isLoading ? "block" : "none" }}>
        <NetworkError />
      </span>

      { isLoading ? <LoadingScreen /> : null }

    </div>
  );
}
