import AdminBadge from "components/Badge";
import Person from "components/Person";
import expandLess from "images/expandLess.svg";
import expandMore from "images/expandMore.svg";
import { useState } from "react";
import Design from "./AdminsMobileView.module.css";
import IconBox from "./IconBox";

export default function AdminsMobileView(props) {
  const { admin } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={Design.adminContainer}>
      <div className={Design.admins}>
        <Person name={admin.name} referenceNumber={null} admin={"admin"} profileImageUrl={admin.profileImageUrl} />
        <AdminBadge type={admin.type} />
        <div className={Design.expandIcons}>
          {isOpen ? (
            <img className={Design.expandLess} src={expandLess} alt="expandLess" onClick={toggleAccordion} />
          ) : (
            <img className={Design.expandMore} src={expandMore} alt="expandMore" onClick={toggleAccordion} />
          )}
        </div>
      </div>
      {/* accordion */}
      {isOpen ? (
        <div className={Design.accordion}>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Email</p>
            <p className={Design.accordionValue}>{admin.email}</p>
          </div>
          <div className={Design.iconBoxContainer}>
            <IconBox />
          </div>
        </div>
      ) : null}
    </div>
  );
}
