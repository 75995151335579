import React from "react";
import { useNavigate } from "react-router-dom";
import Design from "./ActionLogs.module.css";
import Image from "components/Image";
import ActionLogStatus from "./ActionLogStatus";
import clock from "images/clock.svg";

export default function ActionLog(props) {
  const { actionlog } = props;

  let navigate = useNavigate();
  const gotoPage = () =>{
    if (actionlog.actionType.includes("DELETE")) {
      navigate(`/notifications`);
    } else if (actionlog.actionType.includes("ADMIN")) {
      navigate(`/admins/profile/edit/${actionlog.userId}`);
    } else if ((actionlog.actionType.includes("USER")) || (actionlog.actionType.includes("SUBSCRIPTION")) ) {
      navigate(`/user/${actionlog.userId}`);
    }
  }

  let date = new Date(actionlog.date);

  return (
    <div className={Design.notificationContainer}>
      <div className={Design.notification} onClick={gotoPage}>
        <Image url={actionlog.profileImageUrl} />
        <div className={Design.notificationContent}>
          <h2 className={Design.notificationMsg}>{actionlog.activitylog}</h2>
          <div className={Design.notificationStatusWrap}>
            <ActionLogStatus actionType={actionlog.actionType} />
            <p className={Design.notificationTime}>
              {" "}
              <img className={Design.notificationIcon} src={clock} alt="clock" /> {date.toLocaleString("en-GB")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
