import React from "react";
import { PRIMARY_COLOR } from "utils/custom/colorPalette";

export default function PrimaryBtn(props) {
  const { title } = props;
  const style = {
    background: PRIMARY_COLOR,
    border: "none",
    outline: "none",
    borderRadius: "8px",
    color: "white",
    fontWeight: 800,
    fontSize: "14px",
    lineHeight: "14px",
    height: "40px",
    width: "fit-content",
    padding: "0 20px",
  };
  return <button style={style}> {title} </button>;
}
