import React from 'react'

export default function UserHeading(props) {
    const { title } = props;
    const style ={
        fontSize: "14px",
        fontWeight: "600",
    }
  return (
    <h3 style={style}>{title}</h3>
  )
}
