import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Design from "./LoginPage.module.css";
import { phoneNumberValidator } from "../utils/functions/validators";
import { BASE_URL } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";

function Form(props) {
  const {onclickLoginBtn} = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countrycode, setCountrycode] = useState("");

  const onLogin = () =>{
    if(!phoneNumber || !countrycode || phoneNumber === "" || countrycode === ""){
      props.openSnackbar("Phone number cannot be empty", "warning", true);
    }else if(phoneNumberValidator(countrycode, phoneNumber)){
      localStorage.setItem("countryCode", countrycode);
      localStorage.setItem("phoneNumber", phoneNumber);

      const logindata = {
        countryCode: countrycode,
        phoneNumber: phoneNumber,
      };

      axios
        .post(`${BASE_URL}admin/otp/send`, logindata)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              onclickLoginBtn();
            }
          }
        })
        .catch((error) => {
          JSON.stringify(error);
          if(error.response.status === 500){
            if(error.response.data.message === "Cannot read properties of null (reading 'isDeleted')"){
              props.openSnackbar("Please register your account", "warning", true);
            }else{
              props.openSnackbar("Internal server error", "error", true);
            }
          }
        });
    }else{
      props.openSnackbar("Invalid phone number", "error", true);
    }
  }
  
  const keyDownHandler = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onLogin();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [phoneNumber, countrycode]);

  return (
    <div>
        <p className={Design.loginFormPara}>Enter your phone number below.</p>
        <div className="phoneInput">
          <PhoneInput
           inputProps={{
            name: 'phone',
            required: true,
            autoFocus: true
          }}
            inputStyle={{ border: "none" }}
            country={"in"}
            value={countrycode + phoneNumber}
            onChange={(value, data) => {
              setCountrycode(data.dialCode);
              setPhoneNumber(value.slice(data.dialCode.length));
            }}
          />
        </div>
        <button className={Design.loginBtn} onClick={onLogin}>Login</button>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(Form);