import * as actionTypes from "store/actions/imagePopupActions";

const initialState = {
  showImagePopup: false,
  imageUrl: [] ,
};

const imagePopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_IMAGE_POPUP:
      return {
        showImagePopup: true,
        imageUrl: action.imageUrl,
      };
    case actionTypes.CLOSE_IMAGE_POPUP:
      return {
        showImagePopup: false,
        imageUrl: [],
      };
    default:
      return {
        showImagePopup: false,
        imageUrl: [],
      };
  }
};

export default imagePopupReducer;
