import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import imageCompression from "browser-image-compression";
import Design from "./UploadSuccessStoryAttachments.module.css";
import clear from "images/clear.svg";
import { Badge } from "antd";

export default function UploadSuccessStoryAttachments(props) {
  const { getAttachmentUrls } = props;
  const [image, setImage] = useState();
  const [previews, setPreviews] = useState([]);
  const fileInputRef = useRef(null);
  const [attachmentArray, setAttachmentArray] = useState([]);

  const handleImageUpload = async () => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      setAttachmentArray((current) => [...current, compressedFile]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews([...previews, reader.result]);
      };
      reader.readAsDataURL(image);
      handleImageUpload();
    }
    // else {
    //   setPreview(null);
    // }
  }, [image]);

  const onRemoveImages = (index) => {
    setAttachmentArray((current) => current.filter((item) => item !== attachmentArray[index]));
    setPreviews((current) => current.filter((item) => item !== previews[index]));
  };

  useEffect(() => {
    getAttachmentUrls(attachmentArray);
  }, [attachmentArray]);

  const previewImages = previews.map((imgData, index) => {
    return (
      <Badge offset={[-6, 6]} count={<img onClick={() => onRemoveImages(index)} width={20} src={clear}></img>}>
        <div key={index} className={Design.previewImage}>
          <img className="img-fluid" src={imgData} alt="attachment" />
        </div>
      </Badge>
    );
  });

  return (
    <div>
      <div className={Design.itemsContainer}>
        {previewImages}

        {attachmentArray.length < 8 ? (
          <div
            className={Design.photoUploadBtn}
            onClick={(event) => {
              event.preventDefault();
              fileInputRef.current.click();
            }}
          >
            <div className={Design.btnContainer}>
              <PlusOutlined />
              <p className={Design.addPhotoText}>Upload</p>
            </div>
          </div>
        ) : null}
      </div>
      <input
        required
        accept=".png, .jpg, .jpeg"
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={(event) => {
          const file = event.target.files[0];
          if (file) {
            setImage(file);
          } else {
            setImage(null);
          }
        }}
      />
    </div>
  );
}
