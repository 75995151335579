import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import ProfileUpdatePhotos from "./ProfileUpdatePhotos";
import ProfileUpdateTable from "./ProfileUpdateTable";
import ProfileUpdateActionBtns from "./ProfileUpdateActionBtns";
import Design from "./ProfileUpdate.module.css";
import { BASE_URL, LOADING_TIME } from "utils/constants/ApplicationConstants";
import { SHOW_TOAST } from "store/actions/toastAction";
import { onHandleUnauthorizedError } from "utils/functions/errorhandle";
import Empty from "empty/Empty";
import LoadingScreen from "../../components/LoadingScreen";

function ProfileUpdate(props) {
  let navigate = useNavigate();
  const { userId } = useParams();
  const [updatedData, setupdatedData] = useState([]);
  const [userData, setuserData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getUserData = () => {
    axios
      .get(`${BASE_URL}admin/users/update/profile/${userId}`)
      .then((res) => {
        setuserData(res.data.userDetails);
        setupdatedData(res.data.userUpdateRequest);
        if (res.data.userUpdateRequest.status === "REJECTED") {
          setIsEmpty(true);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      })
      .catch((error) => {
        console.log(error.code);
        if (error.code === "ERR_NETWORK") {
          props.openSnackbar("Network error", "error", true);
          navigate(-1);
        } else if (error.response.statusText === "Unauthorized" && error.response.status === 401) {
          onHandleUnauthorizedError();
        } else if (error.response.data.message === "Profile update request not found") {
          setIsEmpty(true);
        } else if (error.code === "ERR_BAD_REQUEST") {
          setIsEmpty(true);
        } else {
          props.openSnackbar("Some error occures. Please try again later!", "error", true);
          navigate(-1);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, LOADING_TIME);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div>
      <div style={{ display: !isLoading ? "block" : "none" }}>
        {isEmpty ? (
          <Empty title={"No profile update request found"} msg={"This user doesn't have any profile update request"} />
        ) : (
          <div className={Design.ProfileUpdate}>
            <div>
              <ProfileUpdatePhotos updatedImage={updatedData.profileImageUrl} userImage={userData.profileImageUrl} />
              <ProfileUpdateTable updatedData={updatedData} userData={userData} />
              <ProfileUpdateActionBtns id={updatedData.id} updateProfileAction= {()=> getUserData()} />
            </div>
          </div>
        )}
      </div>
      <span style={{ display: isLoading ? "block" : "none" }}>
        <LoadingScreen />
      </span>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openSnackbar: (message, severity, open) => dispatch({ type: SHOW_TOAST, message, severity, open }),
  };
};

export default connect(null, mapDispatchToProps)(ProfileUpdate);
