import React from "react";
import Design from "./Error.module.css";
import { Link } from "react-router-dom";
import errorImage from "../images/errorImage.svg";
import PrimaryBtn from "components/PrimaryBtn";

export default function Error() {
  return (
    <div className={Design.errorPage}>
      <div className={Design.errorPageContents}>
        <h1 className={Design.errorPageHeading} >Sorry, page not found!</h1>
        <img className={Design.errorImage} src={errorImage} alt="errorImage" />
        <p className={Design.errorPageText}>Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling.</p>
        <Link to="/users" className={Design.backToHomeBtn}>
          <PrimaryBtn title={"Back to Home"}/>
        </Link>
      </div>
    </div>
  );
}
