import React from "react";
import EditRequestMobileview from "./EditRequestMobileview";
import Empty from "empty/Empty";

export default function EditRequestMobileviews(props) {
  const { requets, tabIndex } = props;
  let emptyPageTitle;
  let emptyPageMsg;

  if (tabIndex === 0) {
    emptyPageTitle = "All caught up";
    emptyPageMsg = "You haven't received any request yet.";
  } else if (tabIndex === 1) {
    emptyPageTitle = "No pending request found";
    emptyPageMsg = "There are no pending requests";
  } else if (tabIndex === 2) {
    emptyPageTitle = "No rejected request found";
    emptyPageMsg = "You haven't rejected any request yet.";
  }

  const requests = requets.map((request) => <EditRequestMobileview key={request.id} request={request} />);
  return <div>{requets.length === 0 ? <Empty title={emptyPageTitle} msg={emptyPageMsg} /> : <span>{requests}</span>}</div>;
}
