import React from "react";
import { useState } from "react";
import LongMenu from "../LongMenu";
import Person from "components/Person";
import expandLess from "images/expandLess.svg";
import expandMore from "images/expandMore.svg";
import Design from "./MobileView.module.css";
import style from "./AllUsersMobileView.module.css";
import { dateFormatter } from "utils/functions/dateFormatter";
import moment from "moment";

import approvedUser from "../../images/approvedUser.svg";
import activeUser from "../../images/activeUser.svg";
import subscribedUser from "../../images/subscribedUser.svg";

function AllUsersMobileView(props) {
  const { user } = props;

  var endDate = user.subscription ? moment(user.subscription.expiringOn) : null;
  var today = moment();
  const remainingDays = endDate && endDate.isValid() ? endDate.diff(today, "days") + 1 : 0;  

  const subscriptionIsActive = user.subscription.isActive === undefined ? null : user.subscription.isActive;
  const subscriptionDate = user.subscription.lastRenewedOn === undefined ? null : dateFormatter(user.subscription.lastRenewedOn);

  let options = [];
  if (user.isVerified && user.isActive && remainingDays >= 0) {
    options = ["Share", "Deactivate", "Delete"];
  } else if (user.isVerified && !user.isActive && remainingDays >= 0) {
    options = ["Reactivate", "Delete"];
  } else if (user.isVerified && user.isActive && remainingDays < 0) {
    options = ["Deactivate", "Delete"];
  } else if (user.isVerified && !user.isActive && remainingDays < 0) {
    options = ["Reactivate", "Delete"];
  }else if (!user.isVerified) {
    options = ["Reject", "Approve"];
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className={style.mainContainer}>
        <div className={style.profileContainer}>
          <Person id={user.id} name={user.name} referenceNumber={user.referenceNumber} profileImageUrl={user.profileImageUrl} />
          <LongMenu options={options} user={user} />
          <div className={Design.expandIcons}>
            {isOpen ? <img src={expandLess} alt="expandLess" onClick={toggleAccordion} /> :  <img src={expandMore} alt="expandMore" onClick={toggleAccordion} /> }
          </div>
        </div>
        <div className={style.iconbarContainer}>
          <div className={style.notifyStateIconsBar}>
            {user.isVerified ? <img src={approvedUser} alt="approvedUser" width={16}/> : null}
            { user.isActive ? <img src={activeUser} alt="activeUser" width={16} /> : null}
            {remainingDays >= 0 ? <img src={subscribedUser} alt="subscribedUser" width={16} /> : null }
          </div>
        </div>
      </div>
      {/* accordion */}
      {isOpen ? (
        <div className={Design.accordion}>
          {/* <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Details</p>
            <p className={Design.accordionValue}>
              {user.gender} {user.age} yrs, {user.hometown}
            </p>
          </div> */}
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Registered on</p>
            <p className={Design.accordionValue}>{dateFormatter(user.registeredOn)}</p>
          </div>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Last payment on</p>
            <p className={Design.accordionValue}>{subscriptionDate}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default AllUsersMobileView;
