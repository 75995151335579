import React from "react";
import { useState } from "react";
import LongMenu from "../LongMenu";
import Person from "components/Person";
import expandLess from "images/expandLess.svg";
import expandMore from "images/expandMore.svg";
import Design from "./MobileView.module.css";

export default function ReportedUsersMobileView(props) {
  const { user } = props;
  let options = ["Remove"]; 
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  let reportedUserName = user.reportedUser ? user.reportedUser.name : null;
  let reportedUserReferenceNum = user.reportedUser ? user.reportedUser.referenceNumber : null;
  let reportedByUserName = user.reportedByUser ? user.reportedByUser.name : null;
  let reportedByProfilePic =  user.reportedByUser ? user.reportedByUser.profileImageUrl : null;
  let reportedUserProfilePic = user.reportedUser ? user.reportedUser.profileImageUrl : null;
  return (
    <div>
      <div className={Design.container}>
        <Person id={user.reportedUserId} name={reportedUserName} referenceNumber={reportedUserReferenceNum} profileImageUrl={reportedUserProfilePic} />
        <LongMenu options={options} user={user} />
        <div className={Design.expandIcons}>
          {isOpen ? <img src={expandLess} alt="expandLess" onClick={toggleAccordion} /> :  <img src={expandMore} alt="expandMore" onClick={toggleAccordion} /> }
        </div>
      </div>
      {/* accordion */}
      {isOpen ? (
        <div className={Design.accordion}>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Reporter</p>
            <Person id={user.reportedByUserId} name={reportedByUserName} profileImageUrl={reportedByProfilePic} size={"25px"} />
          </div>
          <div className={Design.accordianContent}>
            <p className={Design.accordionKey}>Reason</p>
            <p className={Design.accordionValue}>{user.reason}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}
