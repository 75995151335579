import * as actionTypes from "../actions/sortOrderActions";

const initialState = {
  sortOrder: "desc",
  sortOrderBy: null,
};

const sortOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SORT_ORDERS:
      return {
        sortOrder: action.sortOrder,
        sortOrderBy: action.sortOrderBy,
      };
    default:
      return state;
  }
};

export default sortOrderReducer;
