import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Person from "components/Person";
import LongMenu from "./LongMenu";
import { dateFormatter } from "../utils/functions/dateFormatter";
import Design from "./Users.module.css";

export default function ReportedUsers(props) {
  const { user } = props;
  let options = ["Remove"]; 
  let date = `Reported on ${dateFormatter(user.updatedAt)}`;
  let reportedUserName = user.reportedUser ? user.reportedUser.name : null;
  let reportedUserReferenceNum = user.reportedUser ? user.reportedUser.referenceNumber : null;
  let reportedByUserName = user.reportedByUser ? user.reportedByUser.name : null;
  let reportedByUserReferenceNum = user.reportedByUser ? user.reportedByUser.referenceNumber : null;
  let reportedByProfilePic =  user.reportedByUser ? user.reportedByUser.profileImageUrl : null;
  let reportedUserProfilePic = user.reportedUser ? user.reportedUser.profileImageUrl : null;

  return (
    <TableRow sx={{ maxHeight: 440 }}>
      <TableCell sx={{ border: "none" }}>
        <Person id={user.reportedUserId} name={reportedUserName} referenceNumber={reportedUserReferenceNum} profileImageUrl={reportedByProfilePic} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <Person id={user.reportedByUserId} name={reportedByUserName} referenceNumber={reportedByUserReferenceNum} profileImageUrl={reportedUserProfilePic} />
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <div>
          <p className={Design.profileDetails}>{user.reason}</p>
          <p className={Design.approvedDate}>{date}</p>
        </div>
      </TableCell>
      <TableCell sx={{ border: "none" }}>
        <LongMenu options={options} user={user} />
      </TableCell>
    </TableRow>
  );
}
