import React from "react";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Image from "../../components/Image";

export default function AutoSelectItem(props) {
  const { user } = props; 
  return (
    <ListItemButton>
      <ListItemAvatar>
        <Image url={user.profileImageUrl} />
      </ListItemAvatar>
      <ListItemText primary={user.name} />
    </ListItemButton>
  );
}
