import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { PRIMARY_COLOR } from "utils/custom/colorPalette";
import { useNavigate, useLocation } from "react-router-dom";

export default function ScrollableTabs(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const pathNames = path.split("/");
  const [value, setValue] = React.useState(pathNames[3]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <Box sx={{ width: "100%", bgcolor: "#ffffff" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          allowScrollButtonsMobile
          TabIndicatorProps={{ sx: { backgroundColor: PRIMARY_COLOR } }}
          sx={{
            "& button": { color: "#637381" },
            "& button.Mui-selected": { color: "#212B36" },
          }}
        >
          <Tab sx={{ textTransform: "capitalize" }} label="General"  value="profile" />
          <Tab sx={{ textTransform: "capitalize" }} label="Contacts" value="contacts" />
          <Tab sx={{ textTransform: "capitalize" }} label="Subscription" value="subscription" />
          <Tab sx={{ textTransform: "capitalize" }} label="Requests" value="requests" />
          <Tab sx={{ textTransform: "capitalize" }} label="Manage" value="settings" />
          <Tab sx={{ textTransform: "capitalize" }} label="Profile Update" value="update-profile" />
        </Tabs>
      </Box>
    </div>
  );
}
