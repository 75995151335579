import * as React from 'react';
import { connect } from "react-redux";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import allFilterIcon from "images/allFilterIcon.svg";
import { SET_SORT_ORDERS } from 'store/actions/sortOrderActions';
import upArrow from "images/upArrow.svg";
import downArrow from "images/downArrow.svg";

function AllFilterIcon(props) {
  const { options, sortOrder, sortOrderBy } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };  

  const handleSortOperation=(value)=>{
    if(sortOrder === "asc"){
      props.handleSorting("desc", value)
    }else{
      props.handleSorting("asc", value)
    }
    handleClose();
  }

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src={allFilterIcon} width={30} style={{marginBottom: "12px", cursor: "pointer"}}/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
       {options.map((option) => (
          <MenuItem sx={{ fontSize: "14px" }} onClick={() => handleSortOperation(option.value)}>
            {option.label} {<img style={{marginLeft: "5px"}} width={18} src={sortOrder === "asc" ? downArrow : upArrow}></img>}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    sortOrder: state.sortOrderReducer.sortOrder,
    sortOrderBy: state.sortOrderReducer.sortOrderBy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSorting: (sortOrder, sortOrderBy) => dispatch({ type: SET_SORT_ORDERS, sortOrder, sortOrderBy }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllFilterIcon);