import { connect } from "react-redux";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

// firebase notification
import Notifications from "Notification/Notifications";

// authentication
import LoginPage from "authentication/LoginPage";

// container
import Container from "container/Container";

// error
import Error from "empty/Error";

import ActionLogs from "activitylog/ActionLogs";
import Dashboard from "dashboard/Dashboard";
import RequestsSection from "requests/RequestsSection";

// Success stories
import AddSuccessStories from "./successStories/addStories/AddSuccessStories";
import SuccessStories from "./successStories/successStories/SuccessStories";
import SuccessStoryDetails from "./successStories/successStory/SuccessStoryDetails";

// admins
import Admins from "admins/Admins";
import EditAdminDetails from "admins/EditAdminDetails";

// user profiles
import UserProfile from "user/UserProfile";
import General from "user/general/General";
import Contacts from "user/general/Contacts";
import Subscription from "user/subscription/Subscription";
import UserRequests from "user/userRequests/UserRequests";
import Manage from "user/manage/Manage";
import ProfileUpdate from "user/profileUpdate/ProfileUpdate";

// deactivation
import Deactivation from "deactivation/Deactivation";

import CustomizedSnackbars from "./components/CustomizedSnackbars";

// exports sections
import ExportPdfComponent from "./print/ExportPdfComponent";
import ReportsSection from "./reports/ReportsSection";

// edit requests
import UpdateProfileDetailsRequest from "./updateProfileDetailsRequest/UpdateProfileDetailsRequest";

// tables
import ActiveUsersTable from "dashboard/tables/ActiveUsersTable";
import AllUsersTable from "dashboard/tables/AllUsersTable";
import DeactivatedUsersTable from "dashboard/tables/DeactivatedUsersTable";
import PendingUsersTable from "dashboard/tables/PendingUsersTable";
import ReportedUsersTable from "dashboard/tables/ReportedUsersTable";
import SubscribedUsersTable from "dashboard/tables/SubscribedUsersTable";
import UnSubscribedUsersTable from "dashboard/tables/UnSubscribedUsersTable";


// Feed back forms

import FeedbackForms from "feedbackForms/FeedbackForms";
import AddFeedbackForm from "feedbackForms/AddFeedbackForm";

// Finances
import FinancesDashboard from  "finances/FinancesDashboard";

// Analytics
import AnalyticalDashboard from "./analytics/AnalyticalDashboard";

function App(props) {
  return (
    <div>
      <Notifications />
      <Router>
        <div>
          <Routes>
            <Route path="/auth" element={!props.isLoggedIn ? <LoginPage /> : <Navigate to="/users/all" />} />
            <Route path="/" element={props.isLoggedIn ? <Container /> : <Navigate to="/auth" />}>
              <Route path="users" element={<Dashboard />}>
                <Route path="all" element={<AllUsersTable />} />
                <Route path="active" element={<ActiveUsersTable />} />
                <Route path="deactive" element={<DeactivatedUsersTable />} />
                <Route path="pending" element={<PendingUsersTable />} />
                <Route path="reported" element={<ReportedUsersTable />} />
                <Route path="subscribe" element={<SubscribedUsersTable />} />
                <Route path="unsubscribe" element={<UnSubscribedUsersTable />} />
              </Route>
              <Route path="requests" element={<RequestsSection />} />
              <Route path="notifications" element={<ActionLogs />} />
              <Route path="admins" element={<Admins />} />
              <Route path="reports" element={<ReportsSection />} />
              <Route path="feedbacks" element={<FeedbackForms />} />
              <Route path="add/feedbacks" element={<AddFeedbackForm />} />
              <Route path="profile/updates" element={<UpdateProfileDetailsRequest />} />
              <Route path="admins/profile/edit/:userId" element={<EditAdminDetails />} />
              <Route path="finances" element={<FinancesDashboard />} />
              <Route path="analytics" element={<AnalyticalDashboard />} />

              <Route path="user/:userId" element={<UserProfile />}>
                <Route path="profile" element={<General />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="subscription" element={<Subscription />} />
                <Route path="requests" element={<UserRequests />} />
                <Route path="settings" element={<Manage />} />
                <Route path="update-profile" element={<ProfileUpdate />} />
              </Route>

              <Route path="success-stories" element={<SuccessStories />} />
              <Route path="success-stories/:storyId" element={<SuccessStoryDetails />} />
              <Route path="add/success-stories" element={<AddSuccessStories />} />
            </Route>
            <Route path="/user/deactivate/:userId" element={props.isLoggedIn ? <Deactivation /> : <Navigate to="/auth" />} />
            <Route path="/user/print/:userId" element={props.isLoggedIn ? <ExportPdfComponent /> : <Navigate to="/auth" />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      </Router>
      <CustomizedSnackbars />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.authReducer.authToken != null && state.authReducer.name != null,
  };
};

export default connect(mapStateToProps, null)(App);
// connect(mapStateToProps, null) => null means value of mapDispatched props //
